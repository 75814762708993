import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";

import FilterSelectField from "./FilterSelectField";
import FilterDateRangeField from "./FilterDateRangeField";
import FilterRangeField from "./FilterRangeField";
import FilterSearchField from "./FilterSearchField";

import "./filter.css";

const Filter = ({
  column,
  contractNumbers,
  dealNumbers,
  data,
  setRowSelection,
}) => {
  const [eDeals, setEDeals] = useState([]);
  const columnFilterValue = column.getFilterValue();
  const {filterVariant} = column.columnDef.meta ?? {};

  useEffect(() => {
    if (column) {
      if (column.id === "contract_number" && contractNumbers) {
        setEDeals(contractNumbers);
      } else if (column.id === "deal_number" && dealNumbers) {
        setEDeals(dealNumbers);
      }
    }
  }, [column, contractNumbers, dealNumbers]);

  const renderFilterField = () => {
    switch (filterVariant) {
      case "select":
        return (
          <FilterSelectField
            column={column}
            columnFilterValue={columnFilterValue}
            data={data}
            setRowSelection={setRowSelection}
          />
        );
      case "daterange":
        return (
          <FilterDateRangeField
            column={column}
            columnFilterValue={columnFilterValue}
            setRowSelection={setRowSelection}
          />
        );
      case "range":
        return (
          <FilterRangeField
            column={column}
            columnFilterValue={columnFilterValue}
            setRowSelection={setRowSelection}
          />
        );
      default:
        return (
          <FilterSearchField
            column={column}
            columnFilterValue={columnFilterValue}
            setRowSelection={setRowSelection}
          />
        );
    }
  };

  return renderFilterField();
};

Filter.propTypes = {
  column: PropTypes.object,
  contractNumbers: PropTypes.array,
  dealNumbers: PropTypes.array,
  setRowSelection: PropTypes.func,
};

export default Filter;
