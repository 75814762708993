import PropTypes from "prop-types";

import {useState} from "react";
import {FormGroup, Label, Input} from "reactstrap";
import {useFormContext} from "react-hook-form";

import siteRefId from "../../common";
import CustomRadioButton from "../../../common/CustomRadioButton";

const AdditionalInfo = ({
  isDisabled,
  mbLocations,
  siteReferenceId,
  mbBalance,
  versioningDocs,
}) => {
  const {
    register,
    formState: {errors},

    control,
  } = useFormContext();

  const [showPrevVersion, setShowPrevVersion] = useState(false);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  return (
    <div className="flex flex-col gap-5 w-full">
      {siteRefId.uk === siteReferenceId && (
        <>
          <FormGroup>
            <Label for="mbLocationGroupId" className="fw-normal mb-4">
              Location
            </Label>
            <Input
              type="select"
              id="mbLocationGroupId"
              {...computeProps("mbLocationGroupId", {
                // required: "Please select location",
              })}
              invalid={!!errors.mbLocationGroupId && !isDisabled}
              disabled={isDisabled}
            >
              {mbLocations.map((location) => (
                <option
                  key={location.locationGroupId}
                  value={location.locationGroupId}
                >
                  {location.locationGroupName}
                </option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="mbBalanceGroupId" className="fw-normal mb-4">
              Balance
            </Label>
            <Input
              type="select"
              id="mbBalanceGroupId"
              {...computeProps("mbBalanceGroupId", {
                // required: "Please select balance",
              })}
              invalid={!!errors.mbBalanceGroupId && !isDisabled}
              disabled={isDisabled}
            >
              {mbBalance.map((balance) => (
                <option
                  key={balance.balanceGroupId}
                  value={balance.balanceGroupId}
                >
                  {balance.balanceGroupName}
                </option>
              ))}
            </Input>
          </FormGroup>
        </>
      )}
      <FormGroup>
        <Label for="replacePreviousVersion" className="fw-normal mb-4">
          Does this document replace any previous version?
        </Label>
        <CustomRadioButton
          type="radio"
          control={control}
          name="replacePreviousVersion"
          error={errors.replacePreviousVersion}
          selectedValue={setShowPrevVersion}
          disabled={isDisabled}
        />
      </FormGroup>
      {showPrevVersion && (
        <FormGroup>
          <Label for="previousVersionId" className="fw-normal mb-4">
            Select a previous version
          </Label>
          <Input
            type="select"
            id="previousVersionId"
            data-test="previousVersionId"
            {...computeProps("previousVersionId", {
              // required: "Please select a previous version",
            })}
            invalid={!!errors.previousVersionId && !isDisabled}
            disabled={isDisabled}
          >
            <option value="">Please select a previous version</option>
            {versioningDocs.map((doc) => (
              <option
                key={doc.certificateInboundEuId}
                value={doc.certificateInboundEuId}
              >
                {doc.sdNumber}
              </option>
            ))}
          </Input>
        </FormGroup>
      )}
      <FormGroup>
        <Label for="additionalNotes" className="fw-normal mb-4">
          Additional notes (Optional)
        </Label>

        <Input
          type="textarea"
          rows="3"
          id="additionalNotes"
          data-test="additionalNotes"
          {...computeProps("additionalNotes")}
          invalid={!!errors.additionalNotes && !isDisabled}
          maxLength={200}
          disabled={isDisabled}
        />
      </FormGroup>
    </div>
  );
};

AdditionalInfo.propTypes = {
  isDisabled: PropTypes.bool,
  mbLocations: PropTypes.arrayOf(PropTypes.object),
  versioningDocs: PropTypes.arrayOf(PropTypes.object),
  mbBalance: PropTypes.arrayOf(PropTypes.object),
  siteReferenceId: PropTypes.string,
  status: PropTypes.string,
};
export default AdditionalInfo;
