import {gql} from "@apollo/client";

export const GET_MODULE_MAPPING = gql`
  query getModuleMappingData {
    bioLcGlobNaviGetModuleCatalogue {
      documentManager
      forecasting
      countryName
      ceemas
      coProcessing
      compliance
      configCenter
      countryId
      reporting
      saf
      drawdown
    }
    bioLcGetSiteReferenceDetails {
      siteReferenceData {
        countryCode
        countryId
        siteName
        siteReferenceId
      }
      statusCode
      error
    }
    bioLcGetDivisionDetails {
      error
      statusCode
      divisionData {
        divisionCode
        divisionId
        divisionName
      }
    }
  }
`;

export default {GET_MODULE_MAPPING};
