import {FilePdf24} from "@bphxd/ds-core-react/lib/icons";
import {Link} from "react-router-dom";

import {
  getFilterValue,
  getStatusColor,
  getStatusValue,
} from "modules/GlobalMassBalance/utils";
import ColumnHighlight from "../ColumnHighlight";
import ActionDropdown from "../ActionDropdown";

export const getColumnsTR = (dateFormat, decimalFormatNew, selectedCountry) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return row?.inboundRecord?.physicalReceiptDate;
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        size: 500,
        accessorFn: (row) => {
          return row?.inboundRecord?.incomingDocument;
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to="/saf/sweden/document-manager/ded3cf8e7ea8dec350e6cdbe6adb55fe"
              className="link-dark"
            >
              <u className="flex">
                {cell.getValue() && <FilePdf24 />}
                <ColumnHighlight
                  value={cell.getValue()}
                  columnFilter={getFilterValue(cell, "incomingDocument")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.rawMaterial;
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.countryOfOrigin;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "GhgTotalMj",
        key: "GhgTotalMj",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.GhgTotalMj?.toString();
        },
      },
      {
        header: "Actualized quantity (in) m³",
        accessorKey: "actualizedQuantityM3",
        key: "actualizedQuantityM3",
        visible: true,
        disabled: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.actualizedQuantityM3?.toString();
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDate",
        key: "issuanceDate",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.issuanceDate;
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "productQtyM3",
        key: "productQtyM3",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.productQtyM3?.toString();
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.productQtyMt?.toString();
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.supplier;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportSystem;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.vesselName;
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoiceNumber",
        key: "invoiceNumber",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.invoiceNumber;
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLg",
        key: "transportLg",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportLg;
        },
      },
      {
        header: "Volume variation %",
        accessorKey: "volumeVariation",
        key: "volumeVariation",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.volumeVariation?.toString();
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.certified;
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMt",
        key: "receivedQuantityMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.receivedQuantityMt?.toString();
        },
      },
      {
        header: "Actualized quantity (in) MT",
        accessorKey: "actualizedQuantityMt",
        key: "actualizedQuantityMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.actualizedQuantityMt?.toString();
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "GhgSavings",
        key: "GhgSavings",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.GhgSavings?.toString();
        },
      },
    ],
  },
  {
    header: "Outgoing",
    columns: [
      {
        header: "Quantity (out) m3",
        accessorKey: "quantityOutM3",
        key: "quantityOutM3",
        visible: true,
        disabled: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.quantityOutM3?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.quantityOutM3;
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.outboundType;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.outboundType;
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.recipient;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.recipient;
        },
      },
      {
        header: "Document",
        accessorKey: "document",
        key: "document",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.document;
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to="/saf/sweden/document-manager/ded3cf8e7ea8dec350e6cdbe6adb55fe"
              className="link-dark"
            >
              <u className="flex">
                {row?.original.outboundRecords?.document && <FilePdf24 />}
                <ColumnHighlight
                  value={row?.original.outboundRecords?.document}
                  columnFilter={getFilterValue(cell, "document")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Quantity (out) MT",
        accessorKey: "quantityOutMt",
        key: "quantityOutMt",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.quantityOutMt?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.quantityOutMt;
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDateOutgoing",
        key: "issuanceDateOutgoing",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFnOutgoing",
        accessorFn: (row) => {
          return row?.outboundRecords?.issuanceDateOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.issuanceDateOutgoing;
        },
      },
      {
        header: "Product",
        accessorKey: "product",
        key: "product",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.product;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.product;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.transportSystemOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.transportSystemOutgoing;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.vesselNameOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.vesselNameOutgoing;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "GhgTotalMjOutgoing",
        key: "GhgTotalMjOutgoing",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.GhgTotalMjOutgoing?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.GhgTotalMjOutgoing;
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "GhgSavingsOutgoing",
        key: "GhgSavingsOutgoing",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.GhgSavingsOutgoing?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.GhgSavingsOutgoing;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        key: "status",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.status;
        },
        cell: ({row}) => {
          const status = row?.original.outboundRecords?.status;
          const bgColor = getStatusColor(status);
          return (
            <span className={`uppercase px-[10px] py-[4px] ${bgColor}`}>
              {getStatusValue(status)}
            </span>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        key: "actions",
        size: 25,
        visible: true,
        enableSorting: false,
        cell: ({row, table}) => {
          return <ActionDropdown row={row} table={table} />;
        },
      },
    ],
  },
];

export const getColumnsTRSWithUK = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Issuance date",
        accessorKey: "issuanceDate",
        key: "issuanceDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.issuanceDate;
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        size: 500,
        accessorFn: (row) => {
          return row?.inboundRecord?.incomingDocument;
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to="/saf/sweden/document-manager/ded3cf8e7ea8dec350e6cdbe6adb55fe"
              className="link-dark"
            >
              <u className="flex">
                {cell.getValue() && <FilePdf24 />}
                <ColumnHighlight
                  value={cell.getValue()}
                  columnFilter={getFilterValue(cell, "incomingDocument")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Raw material",
        accessorKey: "rawMaterial",
        key: "rawMaterial",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.rawMaterial;
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.countryOfOrigin;
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "GhgSavings",
        key: "GhgSavings",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.GhgSavings?.toString();
        },
      },
      {
        header: "Quantity m³",
        accessorKey: "productQtyM3",
        key: "productQtyM3",
        visible: true,
        disabled: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.productQtyM3?.toString();
        },
      },
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return row?.inboundRecord?.physicalReceiptDate;
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.productQtyMt?.toString();
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.supplier;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportSystem;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.vesselName;
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoiceNumber",
        key: "invoiceNumber",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.invoiceNumber;
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLg",
        key: "transportLg",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportLg;
        },
      },
      {
        header: "Volume variation %",
        accessorKey: "volumeVariation",
        key: "volumeVariation",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.volumeVariation?.toString();
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.certified;
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMt",
        key: "receivedQuantityMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.receivedQuantityMt?.toString();
        },
      },
      {
        header: "Actualized quantity (in) m³",
        accessorKey: "actualizedQuantityM3",
        key: "actualizedQuantityM3",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.actualizedQuantityM3?.toString();
        },
      },
      {
        header: "Actualized quantity (in) MT",
        accessorKey: "actualizedQuantityMt",
        key: "actualizedQuantityMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.actualizedQuantityMt?.toString();
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "GhgTotalMj",
        key: "GhgTotalMj",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.GhgTotalMj?.toString();
        },
      },
    ],
  },
  {
    header: "Outgoing",
    columns: [
      {
        header: "Quantity (out) m3",
        accessorKey: "quantityOutM3",
        key: "quantityOutM3",
        visible: true,
        disabled: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.quantityOutM3?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.quantityOutM3;
        },
      },
      {
        header: "Outbound type",
        accessorKey: "outboundType",
        key: "outboundType",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.outboundType;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.outboundType;
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.recipient;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.recipient;
        },
      },
      {
        header: "Quantity (out) MT",
        accessorKey: "quantityOutMt",
        key: "quantityOutMt",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.quantityOutMt?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.quantityOutMt;
        },
      },
      {
        header: "Document",
        accessorKey: "document",
        key: "document",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.document;
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to="/saf/sweden/document-manager/ded3cf8e7ea8dec350e6cdbe6adb55fe"
              className="link-dark"
            >
              <u className="flex">
                {row?.original.outboundRecords?.document && <FilePdf24 />}
                <ColumnHighlight
                  value={row?.original.outboundRecords?.document}
                  columnFilter={getFilterValue(cell, "document")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDateOutgoing",
        key: "issuanceDateOutgoing",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFnOutgoing",
        accessorFn: (row) => {
          return row?.outboundRecords?.issuanceDateOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.issuanceDateOutgoing;
        },
      },
      {
        header: "Product",
        accessorKey: "product",
        key: "product",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.product;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.product;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.transportSystemOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.transportSystemOutgoing;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.vesselNameOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.vesselNameOutgoing;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "GhgTotalMjOutgoing",
        key: "GhgTotalMjOutgoing",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.GhgTotalMjOutgoing?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.GhgTotalMjOutgoing;
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "GhgSavingsOutgoing",
        key: "GhgSavingsOutgoing",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.GhgSavingsOutgoing?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.GhgSavingsOutgoing;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        key: "status",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.status;
        },
        cell: ({row}) => {
          const status = row?.original.outboundRecords?.status;
          const bgColor = getStatusColor(status);
          return (
            <span className={`uppercase px-[10px] py-[4px] ${bgColor}`}>
              {getStatusValue(status)}
            </span>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        key: "actions",
        size: 25,
        visible: true,
        enableSorting: false,
        cell: ({row, table}) => {
          return <ActionDropdown row={row} table={table} />;
        },
      },
    ],
  },
];

export const getColumnsCPP = (
  dateFormat,
  decimalFormatNew,
  selectedCountry,
) => [
  {
    header: "Incoming",
    columns: [
      {
        header: "Physical receipt date",
        accessorKey: "physicalReceiptDate",
        key: "physicalReceiptDate",
        visible: true,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFn",
        accessorFn: (row) => {
          return row?.inboundRecord?.physicalReceiptDate;
        },
      },
      {
        header: "Document",
        accessorKey: "incomingDocument",
        key: "incomingDocument",
        visible: true,
        size: 500,
        accessorFn: (row) => {
          return row?.inboundRecord?.incomingDocument;
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to="/saf/sweden/document-manager/ded3cf8e7ea8dec350e6cdbe6adb55fe"
              className="link-dark"
            >
              <u className="flex">
                {cell.getValue() && <FilePdf24 />}
                <ColumnHighlight
                  value={cell.getValue()}
                  columnFilter={getFilterValue(cell, "incomingDocument")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Quantity MT",
        accessorKey: "productQtyMt",
        key: "productQtyMt",
        visible: false,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.productQtyMt?.toString();
        },
      },
      {
        header: "Country of origin",
        accessorKey: "countryOfOrigin",
        key: "countryOfOrigin",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.countryOfOrigin;
        },
      },
      {
        header: "Supplier",
        accessorKey: "supplier",
        key: "supplier",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.supplier;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystem",
        key: "transportSystem",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportSystem;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselName",
        key: "vesselName",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.vesselName;
        },
      },
      {
        header: "Invoice number",
        accessorKey: "invoiceNumber",
        key: "invoiceNumber",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.invoiceNumber;
        },
      },
      {
        header: "Transport loss/gain",
        accessorKey: "transportLg",
        key: "transportLg",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        accessorFn: (row) => {
          return row?.inboundRecord?.transportLg;
        },
      },
      {
        header: "Certified",
        accessorKey: "certified",
        key: "certified",
        visible: false,
        accessorFn: (row) => {
          return row?.inboundRecord?.certified;
        },
      },
      {
        header: "Received quantity MT",
        accessorKey: "receivedQuantityMt",
        key: "receivedQuantityMt",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.receivedQuantityMt?.toString();
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "GhgTotalMj",
        key: "GhgTotalMj",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.GhgTotalMj?.toString();
        },
      },
    ],
  },
  {
    header: "Co-processed",
    columns: [
      {
        header: "Co-processed quantity (out) MT",
        accessorKey: "coProcessedQuantityMt",
        key: "coProcessedQuantityMt",
        visible: true,
        dataType: "number",
        accessorFn: (row) => {
          return row?.inboundRecord?.coProcessedQuantityMt?.toString();
        },
      },
    ],
  },
  {
    header: "Outgoing",
    columns: [
      {
        header: "Quantity (out) MT",
        accessorKey: "quantityOutMt",
        key: "quantityOutMt",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.quantityOutMt?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.quantityOutMt;
        },
      },
      {
        header: "Document",
        accessorKey: "document",
        key: "document",
        visible: true,
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.document;
        },
        cell: ({cell, row}) => {
          return (
            <Link
              to="/saf/sweden/document-manager/ded3cf8e7ea8dec350e6cdbe6adb55fe"
              className="link-dark"
            >
              <u className="flex">
                {row?.original.outboundRecords?.document && <FilePdf24 />}
                <ColumnHighlight
                  value={row?.original.outboundRecords?.document}
                  columnFilter={getFilterValue(cell, "document")}
                  hasEllipsis
                  isCertificate
                />
              </u>
            </Link>
          );
        },
      },
      {
        header: "Issuance date",
        accessorKey: "issuanceDateOutgoing",
        key: "issuanceDateOutgoing",
        visible: false,
        meta: {
          filterVariant: "daterange",
        },
        filterFn: "dateRangeFilterFnOutgoing",
        accessorFn: (row) => {
          return row?.outboundRecords?.issuanceDateOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.issuanceDateOutgoing;
        },
      },
      {
        header: "Product",
        accessorKey: "product",
        key: "product",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.product;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.product;
        },
      },
      {
        header: "Recipient",
        accessorKey: "recipient",
        key: "recipient",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.recipient;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.recipient;
        },
      },
      {
        header: "Transport system",
        accessorKey: "transportSystemOutgoing",
        key: "transportSystemOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.transportSystemOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.transportSystemOutgoing;
        },
      },
      {
        header: "Vessel name",
        accessorKey: "vesselNameOutgoing",
        key: "vesselNameOutgoing",
        visible: false,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.vesselNameOutgoing;
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.vesselNameOutgoing;
        },
      },
      {
        header: "GHG total gCO2eq/MJ",
        accessorKey: "GhgTotalMjOutgoing",
        key: "GhgTotalMjOutgoing",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.GhgTotalMjOutgoing?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.GhgTotalMjOutgoing;
        },
      },
      {
        header: "GHG savings %",
        accessorKey: "GhgSavingsOutgoing",
        key: "GhgSavingsOutgoing",
        visible: false,
        filterFn: "massBalanceCustomFilterFunction",
        dataType: "number",
        accessorFn: (row) => {
          return row?.outboundRecords?.GhgSavingsOutgoing?.toString();
        },
        cell: ({row}) => {
          return row?.original.outboundRecords?.GhgSavingsOutgoing;
        },
      },
      {
        header: "Status",
        accessorKey: "status",
        key: "status",
        visible: true,
        disabled: true,
        meta: {
          filterVariant: "select",
        },
        filterFn: "massBalanceCustomFilterFunction",
        accessorFn: (row) => {
          return row?.outboundRecords?.status;
        },
        cell: ({row}) => {
          const status = row?.original.outboundRecords?.status;
          const bgColor = getStatusColor(status);
          return (
            <span className={`uppercase px-[10px] py-[4px] ${bgColor}`}>
              {getStatusValue(status)}
            </span>
          );
        },
      },
      {
        header: "",
        accessorKey: "actions",
        key: "actions",
        size: 25,
        visible: true,
        enableSorting: false,
        cell: ({row, table}) => {
          return <ActionDropdown row={row} table={table} />;
        },
      },
    ],
  },
];
