export const MODAL_QUERY_PARAM = "modal-add-batch";
export const BATCHES_PATH = "/batches";
export const BATCHES_DOC_VIEWER_PATH = `${BATCHES_PATH}/document-viewer`;
export const NO_RECORDS = "There are no records to show right now";
export const NO_BATCH_AVAILABLE = "No batch number available";
const ADD_A_BATCH = "Add a batch";
const DHDS = "#3 DHDS";
const SUMMARY = "Summary";
const SUCCESSFULLY_CREATED = "Batch successfully created";
const MISSING_INFO = "Expected information missing";
const END_DATE_OVERLAP_INFO =
  "Start date is earlier than previous batch end date";
export const COLD_RECIRC_OPEN_NOTIFICATION =
  "Cold Recirc value tag is open, operating condition changed to Normal-Unit Recirc.";

export const TITLES = {
  BATCHES: "BATCHES",
  BATCH_DETAILS: "Batch details",
  PI_DATA: "Pi data",
  TODO: "To-do",
  SUMMARY,
};

export const OPERATIONS = {
  GET: "get_c14",
  UPDATE: "update_c14",
  GET_BATCHES: "get_batches",
  DELETE: "delete_batch",
};

export const BUTTONS = {
  ACTIVE_BATCHES: "Active Batches",
  ALLOCATED_BATCHES: "Allocated Batches",
  ADD_BATCH: "Add a batch",
  BACK: "Back",
  CLOSE: "Close",
  CONTINUE: "Continue",
  CREATE_ANOTHER: "Create another batch",
  SAVE_BATCH: "Save batch",
  EDIT_DETAILS: "Edit details",
  ACTIONS: "Actions",
  SAVE: "Save",
  EXIT_EDIT: "Exit edit mode",
  EDIT: "Edit",
  ALLOCATE_READY_BATCHES: "Allocate ready batches",
  SUBMIT_BATCHES_FOR_CREDITS: "Submit batches for credits",
};

export const BATCH_STATUS = {
  ACTIVE: "ACTIVE",
  DRAFT: "DRAFT",
  TRACKED_OUT: "TRACKED OUT",
  READY_FOR_ALLOCATION: "READY FOR ALLOCATION",
  READY_FOR_SUBMISSION: "READY FOR SUBMISSION",
  SUBMITTED_FOR_CREDITS: "SUBMITTED FOR CREDITS",
  NOT_QUALIFIED: "NOT QUALIFIED",
};

export const ACTIVE_STATUSES = [
  BATCH_STATUS.ACTIVE,
  BATCH_STATUS.READY_FOR_ALLOCATION,
];

export const dateRangeOptions = {
  allowInput: false,
  altInput: true,
  allowInvalidPreload: false,
  altFormat: "M j, Y",
  dateFormat: "Y-n-j",
  enableTime: false,
  mode: "range",
};

export const ERRORS = {
  FAILED_LOAD: "Error: Failed to load batches data",
  FAILED_DELETE: "Error: Failed to delete batch",
};

export const BATCH_LABELS = {
  STATUS: "Batch status",
  DETAILS: "Batch details",
  SHIPPING_DETAILS: "Shipping details",
  C14_DETAILS: "c14 details",
  FEEDSTOCK_ALLOCATION_DETAILS: "Feedstock allocation details",
  SUMMARY: "Batch summary",
  BATCH_ALLOCATION: "Batch allocation",
  RINS_STATUS: "RINS submission status",
  DHDS_SUMMARY: "#3DHDS summary",
};

export const INFO = {
  MISSING_BATCH: "Can not be populated, add a batch",
};

export const TABS = {
  ACTIVE: "Active",
  SUBMITTED: "Submitted",
  WATCHLIST: "Watchlist",
};

export const BATCHES_LABELS = {
  TESTING_METHOD: "Testing method",
  CERT_NUMBER: "Certificate number",
  SUBMITTER: "Submitter",
  COMPANY: "Company",
  DATE_RECEIVED: "Date received",
  DATE_REPORTED: "Date reported",
  LAB_NUMBER: "Laboratory number",
  BATCH_ID: "Batch ID",
  PERCENT_CARBON: "Percent modern carbon (pMC)",
  PERCENT_CARBON_VARIANCE: "Percent modern carbon (pMC) variance",
  SUBMITTER_LABEL: "Submitter label",
};

export const keyToLabel = {
  date_received: BATCHES_LABELS.DATE_RECEIVED,
  date_reported: BATCHES_LABELS.DATE_REPORTED,
  batch_id: BATCHES_LABELS.BATCH_ID,
  percentage_modern_carbon: BATCHES_LABELS.PERCENT_CARBON,
  percentage_modern_carbon_var: BATCHES_LABELS.PERCENT_CARBON_VARIANCE,
  testing_method: BATCHES_LABELS.TESTING_METHOD,
  company: BATCHES_LABELS.COMPANY,
  submitter: BATCHES_LABELS.SUBMITTER,
  submitter_label: BATCHES_LABELS.SUBMITTER_LABEL,
  c14_certificate_number: BATCHES_LABELS.CERT_NUMBER,
  c14_detail_id: BATCHES_LABELS.BATCH_ID,
  lab_number: BATCHES_LABELS.LAB_NUMBER,
};

export const batchesFormMap = new Map(Object.entries(keyToLabel));

export const BATCH_INPUTS = [
  {
    label: BATCHES_LABELS.TESTING_METHOD,
    key: "testing_method",
    data_type: "text",
    placeholder: "Enter testing method",
  },
  {
    label: BATCHES_LABELS.CERT_NUMBER,
    key: "c14_certificate_number",
    data_type: "text",
    placeholder: "Enter certificate number",
  },
  {
    label: BATCHES_LABELS.SUBMITTER,
    key: "submitter",
    data_type: "text",
    placeholder: "Enter submitter",
  },
  {
    label: BATCHES_LABELS.SUBMITTER_LABEL,
    key: "submitter_label",
    data_type: "text",
    placeholder: "Enter submitter label",
  },
  {
    label: BATCHES_LABELS.COMPANY,
    key: "company_name",
    data_type: "text",
    placeholder: "Enter company",
  },
  {
    label: BATCHES_LABELS.DATE_RECEIVED,
    key: "date_received",
    data_type: "date",
  },
  {
    label: BATCHES_LABELS.DATE_REPORTED,
    key: "date_submitted",
    data_type: "date",
  },
  {
    label: BATCHES_LABELS.LAB_NUMBER,
    key: "lab_number",
    data_type: "text",
    placeholder: "Enter laboratory number",
  },
  {
    label: BATCHES_LABELS.BATCH_ID,
    key: "batch_id",
    data_type: "text",
    placeholder: "Enter batch id",
  },
  {
    label: BATCHES_LABELS.PERCENT_CARBON,
    key: "percentage_modern_carbon",
    data_type: "number",
    placeholder: "Enter modern carbon percentage",
  },
  {
    label: BATCHES_LABELS.PERCENT_CARBON_VARIANCE,
    key: "percentage_modern_carbon_var",
    data_type: "text",
    placeholder: "Enter modern carbon percentage variance",
  },
];

/* Steps 1 to 5 of add a batch multi step form */
export const STEPS = {
  /** Step 1: Select a Tank number. */
  INITIAL: 1,
  /** Step 2: Select operating condition. */
  SECOND: 2,
  /** Step 3: Select start and end datetime. */
  THIRD: 3,
  /** Step 4: User gets to select Batch ID and save batch form data. */
  SAVE: 4,
  /** Step 5: Form completed, user gets to create another batch or close the session. */
  SUCCESS: 5,
};

export const ADD_BATCH_FORM = {
  keys: {
    batchId: "batch_id",
    batchCode: "batch_code",
    clearHeel: "heel_volume",
    batch_notes: "batch_notes",
    opCondition: "operating_condition",
    splitBatch: "split_batch",
    batchStartTime: "batch_start_time",
    batchEndTime: "batch_end_time",
    renewableFeedQuantity: "renewable_feed_quantity",
    sourceSampleCode: "source_sample_code",
    tankNo: "tank_number",
    tankDetailId: "tank_detail_id",
    tankVolume: "tank_volume",
    tankCertifiedDate: "tank_certified_datez",
    latestEndDate: "latest_end_date",
  },
  labels: {
    batchId: BATCHES_LABELS.BATCH_ID,
    ogBatchId: "Original batch ID",
    tankNo: "Tank number",
    clearHeel: "Clear out heel",
    opCondition: "Operating condition",
    splitBatch: "Split batch",
    batchStartTime: `${DHDS} start`,
    batchEndTime: `${DHDS} end`,
    batch_notes: "Notes",
    latestCreateDate: "Date of last batch created:",
    latestRenewableServiceDate: "Date of last renewable service:",
    renewableFeedQuantity: "Renewable feed",
    tankCertifiedDate: "Tank certified time",
  },
  title: ADD_A_BATCH,
  messages: {SUCCESSFULLY_CREATED, MISSING_INFO, END_DATE_OVERLAP_INFO},
};

export const ADD_BATCH_DATE_FIELDS = [
  {
    label: ADD_BATCH_FORM.labels.batchStartTime,
    name: ADD_BATCH_FORM.keys.batchStartTime,
  },
  {
    label: ADD_BATCH_FORM.labels.batchEndTime,
    name: ADD_BATCH_FORM.keys.batchEndTime,
  },
];

/* Add batch form field with toggle values Yes | No */
export const TOGGLE = {
  YES: "Yes",
  NO: "No",
};

/* Three different operating condition states:`BY_PASSED`, `NORMAL`, and `NORMAL_UNIT_REC` */
export const OP_CONDITIONS = {
  BY_PASSED: "By-passed",
  NORMAL: "Normal",
  NORMAL_UNIT_REC: "Normal-UnitRecirc",
};

export const INITIAL_BATCH = {
  batch_id: "",
  batch_code: "",
  batch_status: "active",
  batch_start_time: "",
  batch_end_time: "",
  operating_condition: "",
  renewable_feed_quantity: 0, // Assuming a default value of 0
  source_sample_code: [],
  heel_volume: false,
  split_batch: false,
  tank_detail_id: "",
  tank_number: "",
  batch_notes: "",
};

export const batchDetailHeaders = {
  completion_percentage: "Status",
  tank_number: "Tank #",
  batch_id: "Batch ID",
  batch_start_time: "#3 DHDS start time",
  batch_end_time: "#3 DHDS end time",
  operating_condition: "Operating condition",
  tank_volume: "Tank volume at certification lbs",
  tank_certified_datez: "Tank certified time/date",
  // Will be added after the join
  c14_test_date: "C14 test date",
  dropdown_icon: "",
};

export const BATCH_SUMMARY_LABELS = {
  DAYS_SUBMISSION: "Days until submission period end",
  TANK_CERTIFICATION: "Tank volume at certification",
  TANK_CERTIFIED: "Tank certified time/date",
};

export const DELETE_POPOVER = {
  HEADER: "Delete batch",
  MESSAGE: "Are you sure you want to delete the batch?",
  YES: "Yes, delete",
  CANCEL: "Cancel",
};

export const METRICS = {
  POUNDS: "pounds",
  DAYS: "days",
  BARRELS: "barrels",
};

export const DROPDOWN_OPTIONS = {
  VIEW_DETAILS: "View details",
  ADD_TO_WATCHLIST: "Add to watchlist",
  REMOVE_FROM_WATCHLIST: "Remove from watchlist",
  EDIT: "Edit",
  DELETE: "Delete",
};

export const BATCH_DETAILS_STATUS = {
  INCOMPLETE: "Incomplete",
  COMPLETE: "Complete",
};

export const BATCH_RECORD_KEYS = [
  {title: BATCH_LABELS.SHIPPING_DETAILS, testId: "shipping-details-card"},
  {title: BATCH_LABELS.C14_DETAILS, testId: "c14-details-card"},
  {title: BATCH_LABELS.BATCH_ALLOCATION, testId: "batch-allocation-card"},
  {title: BATCH_LABELS.RINS_STATUS, testId: "rins-status-card"},
];

export const BATCH_STATUS_CARD_KEYS = [
  {
    title: BATCH_LABELS.DETAILS,
    status: BATCH_DETAILS_STATUS.COMPLETE,
  },
  {
    title: BATCH_LABELS.SHIPPING_DETAILS,
    status: BATCH_DETAILS_STATUS.INCOMPLETE,
  },
  {
    title: BATCH_LABELS.C14_DETAILS,
    status: BATCH_DETAILS_STATUS.INCOMPLETE,
  },
  {
    title: BATCH_LABELS.FEEDSTOCK_ALLOCATION_DETAILS,
    status: BATCH_DETAILS_STATUS.INCOMPLETE,
  },
];

export const BATCH_DETAILS_CARD_COLUMNS = [
  {
    label: "#3 DHDS start",
    key: "batch_start_time",
    format: "date",
  },
  {
    label: "#3 DHDS end",
    key: "batch_end_time",
    format: "date",
  },
  {
    label: "Operating Condition",
    key: "operating_condition",
  },
  {
    label: "Tank certified time/date",
    key: "tank_certified_datez",
    format: "date",
  },
  {
    label: "Tank volume at certification lbs",
    key: "tank_volume",
    format: "lbs",
  },
  {
    label: "C14 testing date",
    key: "c14_test_date",
    format: "date",
  },
];

export const BATCH_SUMMARY_COLUMNS = [
  {
    label: "Batch ID",
    key: "batch_id",
    type: "select",
    options: [],
  },
  {
    label: "Tank Number",
    key: "tank_number",
    type: "select",
    options: [],
  },
  {
    label: "#3 DHDS start time",
    key: "batch_start_time",
    type: "date",
  },
  {
    label: "Operating Condition",
    key: "operating_condition",
    type: "select",
    options: [
      OP_CONDITIONS.BY_PASSED,
      OP_CONDITIONS.NORMAL,
      OP_CONDITIONS.NORMAL_UNIT_REC,
    ],
  },
  {
    label: "#3 DHDS end time",
    key: "batch_end_time",
    type: "date",
  },

  {
    label: "Notes",
    key: "batch_notes",
    type: "textarea",
    options: [],
    editable: true,
  },
];

export const DHDS_SUMMARY_LABELS = {
  DHDS_FEED: "#3DHDS Feed",
  DHDS_PRODUCTION: "#3DHDS Production",
};

export const DHDS_FEED_COLUMN = [
  {
    label: "Total feed",
    key: "total_feed_bbl",
    metric: "bbl",
    type: "number",
  },
  {
    label: "Renewable feed",
    key: "renewable_feed_bbl",
    metric: "bbl",
    type: "number",
  },
  {
    label: "Cold recirc in renewable feed",
    key: "cold_recirc_in_renewable_feed_bbl",
    metric: "bbl",
    type: "number",
  },
  {
    label: "Renewable feed tank vol  SU/SD",
    key: "renewable_feed_tank_col_su_sd_bbl",
    metric: "bbl",
    type: "number",
  },
];

export const DHDS_PRODUCTION_COLUMN = [
  {
    label: "Unit rundown",
    key: "unit_rundown_bbl",
    metric: "bbl",
    type: "number",
  },
  {
    label: "Unit rundown",
    key: "unit_rundown_lb",
    metric: "lb",
    type: "number",
  },
  {
    label: "Hot diesel recirc",
    key: "hot_diesel_recirc_bbl",
    metric: "bbl",
    type: "number",
  },
  {
    label: "Hot diesel recirc",
    key: "hot_diesel_recirc_lb",
    metric: "lb",
    type: "number",
  },
  {
    label: "Cold recirc",
    key: "cold_recirc_bbl",
    metric: "bbl",
    type: "number",
  },
  {
    label: "Cold recirc",
    key: "cold_recirc_lb",
    metric: "lb",
    type: "number",
  },
  {
    label: "Renewable feed",
    key: "renewable_feed_lb",
    metric: "lb",
    type: "number",
  },
  {
    label: "Cold recirc in renewable feed",
    key: "cold_recirc_in_renewable_feed_lb",
    metric: "lb",
    type: "number",
  },
  {
    label: "Renewable feed tank vol  SU/SD",
    key: "renewable_feed_tank_col_su_sd_lb",
    metric: "lb",
    type: "number",
  },
  {
    label: "Total feed",
    key: "total_feed_lb",
    metric: "lb",
    type: "number",
  },
  {
    label: "Rundown density",
    key: "rundown_density_lb_kgal",
    metric: "lb/kg",
    type: "number",
  },
];

export const DHDS_SUMMARY = {
  FEED: {
    COLUMN_KEYS: DHDS_FEED_COLUMN,
    HEADER: DHDS_SUMMARY_LABELS.DHDS_FEED,
  },
  PRODUCTION: {
    COLUMN_KEYS: DHDS_PRODUCTION_COLUMN,
    HEADER: DHDS_SUMMARY_LABELS.DHDS_PRODUCTION,
  },
};

export const C14_CARD_COLUMNS = [
  {
    label: "Sample date",
    key: "c14_sample_date",
    type: "date",
  },
  {
    label: "Sample name",
    key: "c14_sample_name",
    type: "text",
  },
  {
    label: "pmc",
    key: "c14_pmc",
    type: "number",
    metric: "%",
  },
];

export const SHIPPING_CARD_COLUMNS = [
  {
    label: "Shipment ID",
    key: "shipment_id",
    type: "text",
  },
  {
    label: "Shipment type",
    key: "shipment_type",
    type: "text",
  },
  {
    label: "Shipment date and time",
    key: "shipment_date",
    type: "date",
    metric: false,
  },
  {
    label: "Shipment volume",
    key: "shipment_volume",
    type: "number",
    metric: "bbl",
  },
];
export const SHIPMENTS_LABEL_COUNT = "SHIPMENT";
export const C14_NOT_APPLICABLE = "C14 not applicable";

export const BATCH_UPDATE_MODAL = {
  UPDATE_SUCCESS: "Batch updated successfully.",
  UPDATE_FAILURE: "Failed to update batch details",
  CANCEL: "Cancel",
  SAVE: "Save",
  EDIT: "Edit",
};

export const CHECKLIST_LABELS = {
  batch_created_status: "Batch created",
  shipment_linked_status: "Shipments linked",
  c14_linked_status: "C14 linked",
  feedstock_allocated_status: "Feedstock allocated",
  emts_submitted_status: "EMTS submitted",
  state_submitted_status: "State submitted",
  state_final_uploaded_status: "State final uploaded",
};
