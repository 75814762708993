import {Alert24} from "@bphxd/ds-core-react/lib/icons";

import {INCOMING_DOC_STATUS} from "modules/DocManager/constants";
import PropTypes from "prop-types";
import "./index.scss";

const Subtitle = ({status, onClick, className, errors, formData}) => {
  const {READY_TO_ACCEPT, READY_TO_ASSIGN, REJECTED, REQUIRES_ATTENTION} =
    INCOMING_DOC_STATUS;

  return (
    <div
      className={`flex flex-col justify-between items-start gap-2 small pb-[60px] px-7 ${className}`}
    >
      {status === READY_TO_ACCEPT && (
        <>
          <h1 className="text-[23px] fw-normal m-0">
            Ready to accept the certificate
          </h1>
          <span className="mb-2">
            We have successfully captured all information and this document
            meets criteria for acceptance. Please review and correct any
            inaccurate or missing information.
          </span>
        </>
      )}
      {status === REQUIRES_ATTENTION && (
        <>
          <h1 className="text-[23px] fw-normal m-0">Requires your attention</h1>
          <span className="mb-2">
            Based on this declaration we have been unable to gather some
            expected information. If you find the information is available from
            the PDF please confrim and add a note to explain your decision. This
            will help us learn and improve this feature in the future.
          </span>
        </>
      )}
      {status === READY_TO_ASSIGN && (
        <>
          <h1 className="text-[23px] fw-normal m-0">Ready to assign</h1>
          <span className="mb-2">
            You have accepted this document and are now ready to assign it to
            the purchase/mass balance.
          </span>
        </>
      )}
      {status === REJECTED && (
        <>
          <h1 className="text-[23px] fw-normal m-0">Rejected</h1>
          <span className="mb-2">
            You have rejected this document on {formData?.bvAuditChangedDatez}
          </span>
        </>
      )}

      {status === REQUIRES_ATTENTION && (
        <>
          <span className="fw-bold mt-4 mb-[22px]">Key points to review:</span>
          <div className="flex flex-col gap-0 w-full">
            {errors &&
              Object.keys(errors).map((key, index) => (
                <div key={index} className="flex items-center ml-3">
                  <Alert24 color="#e64949" className="w-8 h-8 mr-[5px]" />
                  <span
                    className="text-base underline cursor-pointer"
                    onClick={() => onClick(key)}
                    onKeyDown={() => {
                      onClick(key);
                    }}
                  >
                    {errors[key].message}
                  </span>
                </div>
              ))}
          </div>
        </>
      )}
    </div>
  );
};
Subtitle.propTypes = {
  status: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  errors: PropTypes.object,
  formData: PropTypes.object,
};
export default Subtitle;
