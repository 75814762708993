import {gql} from "@apollo/client";

export const SAF_GET_INCOMING_DOCUMENTS = gql`
  query MyQuery($site_reference_id: String) {
    bioLcGetIncomingSupplierApi(
      event: {divisionId: "7c5fc8889b6e3c8756abbd76353d68e7"}
    ) {
      error
      statusCode
      supplierNames {
        supplierName
      }
    }

    bioLcGetIncomingDocsApi(
      event: {
        divisionId: "7c5fc8889b6e3c8756abbd76353d68e7"
        siteReferenceId: $site_reference_id
      }
    ) {
      statusCode
      error
      documents {
        bvAuditChangedDatez
        certificateInboundEuId
        certificationSystem
        contractNumber
        dateOfIssuance
        ghgTotal
        inboundDocStatusEuId
        productQty
        productQtyUom
        productType
        rawMaterialType
        recipientReceiptAddress
        sdNumber
        state
        supplierAddress
        supplierDispatchAddress
        supplierName
        versionCount
        versionHistoryData {
          linkedCert
          bvAuditChangedDatez
          sdNumber
        }
      }
    }
  }
`;

// We have to use this in future for document file

// division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
// site_reference_id: "c8f4261f9f46e6465709e17ebea7a92b"

export const SAF_GET_INCOMING_DOCUMENTS_SINGLE = gql`
  query viewDocumentQuery(
    $certificate_inbound_eu_id: String
    $siteReferenceId: String
    $divisionId: String
  ) {
    bioLcSafGetMaterials(event: {}) {
      error
      statusCode
      product_names {
        product_name
      }
      raw_material_names {
        raw_material_name
      }
    }

    bioLcGetVersioningDocsApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      error
      statusCode
      documents {
        sdNumber
      }
    }

    bioLcGetIncomingDocFileApi(
      event: {
        divisionId: $divisionId
        certificateInboundEuId: $certificate_inbound_eu_id
        siteReferenceId: $siteReferenceId
      }
    ) {
      downloadUrl
      error
      statusCode
    }

    bioLcGetBalanceGroupApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      error
      statusCode
      balanceGroups {
        balanceGroupId
        balanceGroupName
      }
    }
    bioLcGetLocationGroupApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      error
      statusCode
      locationGroups {
        locationGroupId
        locationGroupName
      }
    }

    bioLcSafGetIncomingDocs(
      event: {
        division_id: $divisionId
        site_reference_id: $siteReferenceId
        certificate_inbound_eu_id: $certificate_inbound_eu_id
      }
    ) {
      documents {
        additional_info
        agricultural_biomass_intermediate_crop_flag
        agricultural_biomass_low_risk_flag
        allocated_electricity
        allocated_heat
        bio_product_producer
        bio_product_user
        bv_audit_changed_datez
        certificate_inbound_eu_id
        certificate_number
        certification_system
        chain_of_custody_option
        contract_number
        date_of_issuance
        default_value_applied_flag
        eu_red_compliant_flag
        ghg_eccr
        ghg_eccs
        ghg_eec
        ghg_el
        ghg_emission_percentage_in
        ghg_ep
        ghg_esca
        ghg_etd
        ghg_eu
        ghg_total
        iscc_compliant_flag
        material_dispatch_datez
        material_sustainability_criteria_flag
        nuts2_region
        producer_installation_date
        product_energy_content
        product_qty
        product_qty_uom
        product_type
        raw_material_origin_country_name
        raw_material_type
        raw_material_waste_or_residue_flag
        recipient_address
        recipient_name
        recipient_receipt_address
        sd_number
        state
        supplier_address
        supplier_dispatch_address
        supplier_name
        user_installation_date
        previous_version_sd_number
      }
      error
      statusCode
    }
  }
`;

export const UPDATE_SAF_DOCUMENT = gql`
  mutation bioLcUpdateIncomingDocDataApi($event: UpdateIncomingDocRequest!) {
    bioLcUpdateIncomingDocDataApi(event: $event) {
      error
      errors {
        field
        message
      }
      statusCode
    }
  }
`;

export default SAF_GET_INCOMING_DOCUMENTS;
