import PropTypes from "prop-types";

import {useFieldArray, useFormContext, useWatch} from "react-hook-form";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {useEffect, useState} from "react";
import CheckboxFlag from "modules/common/BinaryFlagNullable";

import {DDV, TDV, parseGhgValues} from "./utils";

const GHGEmissionInfo = ({status, isDisabled, clearErrors}) => {
  const [isCheckedYes, setIsCheckedYes] = useState(null);
  const [ghgTotal, setGhgTotal] = useState(0.0);

  const {
    register,
    formState: {errors},
    setError,
    control,
    setValue,
    getValues,
  } = useFormContext();
  const flagValue = getValues("defaultValueAppliedFlag");
  useFieldArray({
    control,
    name: "splitDetails",
    mode: "onBlur",
    rules: {
      validate: (data) => {
        if (!data) return;
        data.forEach((item, index) => {
          if (ghgTotal === TDV || ghgTotal === DDV || flagValue) {
            clearErrors("ghg_no_value");
            clearErrors(`splitDetails.${index}.ghgEec`);
            clearErrors("ghgTotal");
          } else if (
            !isDisabled &&
            !flagValue &&
            parseFloat(item.ghgEccr ?? "0") === 0 &&
            parseFloat(item.ghgEccs ?? "0") === 0 &&
            parseFloat(item.ghgEec ?? "0") === 0 &&
            parseFloat(item.ghgEee ?? "0") === 0 &&
            parseFloat(item.ghgEl ?? "0") === 0 &&
            parseFloat(item.ghgEp ?? "0") === 0 &&
            parseFloat(item.ghgEsca ?? "0") === 0 &&
            parseFloat(item.ghgEtd ?? "0") === 0 &&
            parseFloat(item.ghgEu ?? "0") === 0 &&
            item.ghgTotal !== TDV &&
            item.ghgTotal !== DDV &&
            item.ghgEtd !== DDV
          ) {
            setError(`splitDetails.${index}.ghgEec`, {
              type: "value",
              message: "At least one GHG emission must be greater than 0",
            });
          }
        });
      },
    },
  });
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const changes = useWatch({
    name: [
      "ghgEec",
      "ghgEl",
      "ghgEp",
      "ghgEtd",
      "ghgEu",
      "ghgEsca",
      "ghgEccs",
      "ghgEccr",
      "defaultValueAppliedFlag",
    ],
  });

  const numberInputPattern = {
    pattern: {
      value: /^(\d{1,10}(\.\d{1,5})?)$/,
      message:
        "Value must be number with (max 10 digits before and 5 digits after decimal point)",
    },
  };

  const [total, setTotal] = useState(0.0);

  useEffect(() => {
    setGhgTotal(getValues("ghgTotal"));
    const flagValue = getValues("defaultValueAppliedFlag");
    const parsedChanges = parseGhgValues(changes);
    const sumChanges =
      parsedChanges[0] +
      parsedChanges[1] +
      parsedChanges[2] +
      parsedChanges[3] +
      parsedChanges[4] -
      (parsedChanges[5] + parsedChanges[6] + parsedChanges[7]);

    setTotal(Math.round(sumChanges * 1000.0) / 1000.0);

    if (flagValue) {
      setValue("ghgTotal", TDV);
      setGhgTotal(TDV);
      clearErrors("ghgTotal");
      clearErrors("ghg_no_value");
      setIsCheckedYes(true);
    } else {
      if (ghgTotal === TDV || ghgTotal === DDV) {
        setGhgTotal(0.0);
        setValue("ghgTotal", 0.0);
      } else if (ghgTotal) setValue("ghgTotal", ghgTotal);
      setIsCheckedYes(false);
    }
  }, [
    changes,
    setValue,
    getValues,
    isCheckedYes,
    total,
    setTotal,
    ghgTotal,
    setGhgTotal,
    clearErrors,
  ]);

  return (
    <div className="flex flex-col gap-5 w-full">
      <FormGroup>
        <Label for="default_value_applied_flag" className="fw-normal mb-4">
          Total default value according to RED II applied
        </Label>
        <CheckboxFlag
          id="defaultValueAppliedFlag"
          name="defaultValueAppliedFlag"
          control={control}
          error={errors.defaultValueAppliedFlag}
          disabled={isDisabled}
        />
      </FormGroup>
      <div className="flex flex-row justify-between items-start gap-3">
        <FormGroup>
          <Label for="ghgEec" className="fw-normal">
            Eec
          </Label>
          <Input
            type="number"
            id="ghgEec"
            data-test="ghgEec"
            {...computeProps("ghgEec", numberInputPattern)}
            invalid={
              !!errors.ghgEec &&
              !isDisabled &&
              ghgTotal !== TDV &&
              ghgTotal !== DDV &&
              !flagValue
            }
            disabled={isDisabled || flagValue}
          />
          {errors.ghgEec &&
            !isDisabled &&
            ghgTotal !== TDV &&
            ghgTotal !== DDV && (
              <FormFeedback className="mt-2">
                {errors.ghgEec?.message}
              </FormFeedback>
            )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEl" className="fw-normal">
            El
          </Label>
          <Input
            type="number"
            id="ghgEl"
            data-test="ghgEl"
            {...computeProps("ghgEl", numberInputPattern)}
            invalid={!!errors.ghgEl && !isDisabled}
            disabled={isDisabled || flagValue}
          />
          {errors.ghgEl && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.ghgEl?.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEp" className="fw-normal">
            Ep
          </Label>
          <Input
            type="number"
            id="ghgEp"
            data-test="ghgEp"
            {...computeProps("ghgEp", numberInputPattern)}
            invalid={!!errors.ghgEp && !isDisabled}
            disabled={isDisabled || flagValue}
          />
          {errors.ghgEp && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.ghgEp?.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEtd" className="fw-normal">
            Etd
          </Label>
          <Input
            type="text"
            id="ghgEtd"
            data-test="ghgEtd"
            {...computeProps("ghgEtd", {
              pattern: {
                value: /^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/,
                message:
                  "Value must be number with (max 10 digits before and 5 digits after decimal point) or DDV",
              },
            })}
            invalid={!!errors.ghgEtd && !isDisabled}
            disabled={isDisabled || flagValue}
          />
          {errors.ghgEtd && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.ghgEtd?.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEu" className="fw-normal">
            Eu
          </Label>
          <Input
            type="number"
            id="ghgEu"
            data-test="ghgEu"
            {...computeProps("ghgEu", numberInputPattern)}
            invalid={!!errors.ghgEu && !isDisabled}
            disabled={isDisabled || flagValue}
          />
          {errors.ghg_eu && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.ghgEu?.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEsca" className="fw-normal">
            Esca
          </Label>
          <Input
            type="number"
            id="ghgEsca"
            data-test="ghgEsca"
            {...computeProps("ghgEsca", numberInputPattern)}
            invalid={!!errors.ghgEsca && !isDisabled}
            disabled={isDisabled || flagValue}
          />
          {errors.ghgEsca && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.ghgEsca?.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccs" className="fw-normal">
            Eccs
          </Label>
          <Input
            type="number"
            id="ghgEccs"
            data-test="ghgEccs"
            {...computeProps("ghgEccs", numberInputPattern)}
            invalid={!!errors.ghgEccs && !isDisabled}
            disabled={isDisabled || flagValue}
          />
          {errors.ghgEccs && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.ghgEccs?.message}
            </FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="ghgEccr" className="fw-normal">
            Eccr
          </Label>
          <Input
            type="number"
            id="ghgEccr"
            data-test="ghgEccr"
            {...computeProps("ghgEccr", numberInputPattern)}
            invalid={!!errors.ghgEccr && !isDisabled}
            disabled={isDisabled || flagValue}
          />
          {errors.ghgEccr && !isDisabled && (
            <FormFeedback className="mt-2">
              {errors.ghgEccr?.message}
            </FormFeedback>
          )}
        </FormGroup>
      </div>
      {errors.ghg_no_value && ghgTotal !== TDV && ghgTotal !== DDV && (
        <p className="text-[#9e3232] text-[11px] opacity-100 mt-[-22px]">
          {" "}
          {errors.ghg_no_value?.message}
        </p>
      )}
      <FormGroup>
        <Label for="ghgTotal" className="fw-normal mb-4">
          Total GHG emissions (gCO2eq/MJ)
        </Label>
        <Input
          type="text"
          id="ghgTotal"
          data-test="ghgTotal"
          {...computeProps("ghgTotal", {
            required: "Please enter total GHG emissions",
            validate: {
              someValue: (value) => {
                return (
                  [/^(TDV$)|^(DDV$)|^(\d{1,10}(\.\d{1,5})?)$/].every(
                    (pattern) => pattern.test(value),
                  ) ||
                  isDisabled ||
                  ghgTotal === TDV ||
                  ghgTotal === DDV ||
                  flagValue ||
                  "Value must be number with (max 10 digits before and 5 digits after decimal point) or TDV or DDV"
                );
              },
              notZero: (value) => {
                return (
                  [/^(?!0*(\.0+)?$)(\d+|\d*\.\d+)$/].every((pattern) =>
                    pattern.test(value),
                  ) ||
                  isDisabled ||
                  ghgTotal === TDV ||
                  ghgTotal === DDV ||
                  flagValue ||
                  "GHG total must be greater than zero."
                );
              },
              sumAddsUp: (value) => {
                setGhgTotal(value);
                return (
                  total === parseFloat(value) ||
                  isDisabled ||
                  ghgTotal === TDV ||
                  ghgTotal === DDV ||
                  flagValue ||
                  "GHG emissions do not match GHG total."
                );
              },
            },
          })}
          invalid={
            total !== parseFloat(ghgTotal) &&
            !isDisabled &&
            ghgTotal !== TDV &&
            ghgTotal !== DDV &&
            !flagValue
          }
          disabled={isDisabled || flagValue}
        />
        {total !== parseFloat(ghgTotal) && !isDisabled && (
          <FormFeedback className="mt-2">
            {total !== parseFloat(ghgTotal) &&
            ghgTotal !== TDV &&
            ghgTotal !== DDV
              ? "GHG emissions do not match GHG total."
              : errors.ghgTotal?.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="producerInstallationDate" className="fw-normal mb-4">
          The installation where the final biofuel or bioliquid was produced
          started physical production of biofuels or bioliquids after 5 October
          2015
        </Label>
        <CheckboxFlag
          id="producerInstallationDate"
          name="producerInstallationDate"
          control={control}
          error={errors.producerInstallationDate}
          disabled={isDisabled}
        />
      </FormGroup>

      {/* -----Mapping Unavailable at the moment------ */}

      {/* <FormGroup>
        <Label for="" className="fw-normal mb-4">
          Were incentives/subsidies received for the production of the biogas?
        </Label>
        <CheckboxFlag
          id=""
          name=""
          control={control}
          error={errors}
          disabled={isDisabled}
        />
      </FormGroup> */}
    </div>
  );
};
GHGEmissionInfo.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  status: PropTypes.string,
  isDisabled: PropTypes.bool,
  clearErrors: PropTypes.func,
};
export default GHGEmissionInfo;
