import {
  dtnShipmentKeys,
  INPUT_METRIC,
  SHIPMENTS_LABELS,
  SHIPMENT_TYPE,
} from "modules/co-processing/constants/shipments";
import {formatTimePicker, formatDatePicker} from "./formUtils";

const FIRST_STEP = 0;

const InitialShipmentState = {[dtnShipmentKeys.SHIPMENT_TYPE]: ""};

export const shipmentTypeToUpperCase = (value) => {
  if (!value) {
    return "-";
  }
  const displayVal = value.replace(/_/g, " ").toLowerCase();
  return displayVal.charAt(0).toUpperCase() + displayVal.slice(1);
};

const TruckRackFields = {
  SHIPMENT_ID: {
    key: dtnShipmentKeys.SHIPMENT_ID,
    label: "Shipment ID",
  },
  SHIPMENT_TYPE: {
    key: dtnShipmentKeys.SHIPMENT_TYPE,
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    options: [
      {value: SHIPMENT_TYPE.BACK_TO_UNIT, label: "Back to unit misc"},
      {value: SHIPMENT_TYPE.TRUCK_RACK, label: "Truck rack"},
    ],
    format: shipmentTypeToUpperCase,
    type: "select",
  },
  SHIPMENT_DATE: {
    key: dtnShipmentKeys.SHIPMENT_DATE,
    label: SHIPMENTS_LABELS.SHIPMENT_DATE,
    format: formatDatePicker,
    type: "date",
  },
  SHIPMENT_START_DATE: {
    key: dtnShipmentKeys.SHIPMENT_START_DATE,
    label: "Start time",
    format: formatTimePicker,
    type: "time",
  },
  SHIPMENT_END_DATE: {
    key: dtnShipmentKeys.SHIPMENT_END_DATE,
    label: "End time",
    format: formatTimePicker,
    type: "time",
  },
  ESTIMATED_VOLUME: {
    key: dtnShipmentKeys.ESTIMATED_VOLUME,
    label: "Estimated shipment max volume",
    format: (value) => `${value?.toLocaleString()} ${INPUT_METRIC}`,
  },
  SOURCE_TANK: {
    key: dtnShipmentKeys.SOURCE_TANK,
    label: "Source tank",
    placeholder: "Enter a tank number",
    type: "input",
  },

  // --- OPTIONAL FIELDS ---
  SHIPMENT_SUB_TYPE: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Shipment sub-type",
    options: ["sub-type A", "sub-type B"],
    isOptional: true,
    type: "select",
  },
  NOTES: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Notes",
    placeholder: "Enter your notes here...",
    isOptional: true,
    width: "col-12",
    type: "textarea",
  },
};

const BackToUnitFields = {
  SHIPMENT_ID: {
    key: dtnShipmentKeys.SHIPMENT_ID,
    label: "Shipment ID",
    type: "input",
  },
  SHIPMENT_TYPE: {
    key: dtnShipmentKeys.SHIPMENT_TYPE,
    label: SHIPMENTS_LABELS.SHIPMENT_TYPE,
    format: shipmentTypeToUpperCase,
  },
  SHIPMENT_DATE: {
    key: dtnShipmentKeys.SHIPMENT_DATE,
    label: "Date",
    format: (timeObj) =>
      timeObj?.date ? formatDatePicker(timeObj?.date) : null,
    type: "date",
    validation: {required: "Date is required"}, // Additional validations can be added here if needed
  },
  TIME: {
    key: dtnShipmentKeys.SHIPMENT_DATE, // Time shares the key with Date due to design, acting as a nested property
    label: "Time",
    format: (timeObj) =>
      timeObj?.date ? formatDatePicker(timeObj?.time) : null,
    type: "time",
    validation: {required: "Time is required"},
  },
  ESTIMATED_VOLUME: {
    key: dtnShipmentKeys.ESTIMATED_VOLUME,
    label: "Volume",
    validation: {required: "Volume is required"},
    placeholder: "Back to unit volume",
    type: "number",
    // format: (value) => `${value?.toLocaleString()} ${INPUT_METRIC}`,
  },
  SOURCE_TANK: {
    key: dtnShipmentKeys.SOURCE_TANK,
    label: "Source tank",
    format: null,
    placeholder: "Enter a tank number",
    validation: {required: "Source tank is required"},
    type: "text",
  },
  NOTES: {
    key: dtnShipmentKeys.ADDITIONAL_INFO,
    label: "Notes",
    placeholder: "Add any notes here...",
    isOptional: true,
    width: "col-12",
    type: "textarea",
  },
};

/**
 * Common field values shared by dtn_shipment types.
 * @const {!Object<string, *>}
 */
const commonDefaults = {
  [dtnShipmentKeys.SHIPMENT_ID]: "",
  [dtnShipmentKeys.SHIPMENT_TYPE]: "",
  [dtnShipmentKeys.ESTIMATED_VOLUME]: 0,
  [dtnShipmentKeys.ADDITIONAL_INFO]: "",
  [dtnShipmentKeys.SOURCE_TANK]: "",
};

/**
 * Defines default values for dtn_shipment fields based on shipment type.
 * @const {!Object<SHIPMENT_TYPE, !Object<string, *>>}
 */
const DefaultsByShipmentType = {
  [SHIPMENT_TYPE.BACK_TO_UNIT]: {
    ...commonDefaults,
    [dtnShipmentKeys.SHIPMENT_DATE]: {date: "", time: ""},
  },
  [SHIPMENT_TYPE.TRUCK_RACK]: {
    ...commonDefaults,
    [dtnShipmentKeys.SHIPMENT_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_START_DATE]: "",
    [dtnShipmentKeys.SHIPMENT_END_DATE]: "",
  },
};

export {
  FIRST_STEP,
  InitialShipmentState,
  DefaultsByShipmentType,
  TruckRackFields,
  BackToUnitFields,
};
