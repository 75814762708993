import tw from "twin.macro";
import {useState, useEffect, useMemo} from "react";
import PropTypes from "prop-types";
import {
  ACTIVE_BATCH_FIELDS,
  ACTIVE_BATCH_KEYS,
  WARNINGS,
} from "modules/co-processing/constants/shipments";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import MetricInput from "modules/co-processing/components/Shipments/MetricInput";
import BatchesVolumeBar from "./BatchesVolumeBar";
import ShipmentIdForm from "./ShipmentIdForm";

const ActiveBatchLabel = tw.div`min-w-[120px] w-[120px]`;
const ActiveBatchField = tw.div`flex items-center pb-3 text-[13px] font-[400]`;
const ActiveBatchValue = tw.div`text-[#111111] pl-3 text-opacity-[0.64]`;

const ActiveBatchCard = ({
  data,
  handleChange: propHandleChange,
  handleErrors,
  handleWarnings,
  handleLoading,
  refreshData,
}) => {
  const getShipments = (batchData) => {
    const shipments = batchData?.shipments ?? [];

    if (batchData.remaining_volume > 0 && batchData.ending_inventory == null) {
      return [...shipments, {shipment_id: "", shipment_volume: 0}];
    }
    return shipments || [];
  };

  const [shipments, setShipments] = useState(getShipments(data));
  const [userInputShipments, setUserInputShipments] = useState([]);
  const [totalShipmentsVolume, setTotalShipmentsVolume] = useState(() =>
    shipments.reduce(
      (acc, shipment) => acc + (shipment.shipment_volume || 0),
      0,
    ),
  );

  useEffect(() => {
    if (data.missing_c14) {
      handleWarnings(
        ACTIVE_BATCH_KEYS.MISSING_C14,
        WARNINGS.MISSING_C14,
        data.batch_id,
      );
    }
  }, [handleWarnings, data]);

  const remainingVolume = useMemo(
    () =>
      data.total_volume -
      totalShipmentsVolume -
      (data.estimated_ending_inventory || 0),
    [data, totalShipmentsVolume],
  );

  useEffect(() => {
    if (remainingVolume > 0) {
      setShipments((prevShipments) => {
        const isLastShipmentEmpty =
          prevShipments.length > 0 &&
          !prevShipments[prevShipments.length - 1].shipment_id &&
          !prevShipments[prevShipments.length - 1].shipment_volume;
        return isLastShipmentEmpty
          ? prevShipments
          : [...prevShipments, {shipment_id: "", shipment_volume: 0}];
      });
    }
  }, [data, handleErrors, remainingVolume]);

  const handleInputChange = (event) => {
    propHandleChange(event, {batch_id: data.batch_id});
  };

  const handleShipmentChange = (shipmentId, index) => {
    const newShipmentList = [...userInputShipments];
    newShipmentList[index] = shipmentId;
    setUserInputShipments(newShipmentList);
    propHandleChange(
      {target: {name: ACTIVE_BATCH_KEYS.SHIPMENT_ID, value: newShipmentList}},
      {batch_id: data.batch_id},
    );
  };

  const updateTotalVolume = (newVolume) => {
    const updatedVolume = totalShipmentsVolume + newVolume;

    if (newVolume <= remainingVolume) {
      setTotalShipmentsVolume(updatedVolume);
      const newShipments = [
        ...shipments,
        {shipment_id: "", shipment_volume: 0},
      ];
      setShipments(newShipments);
    }
  };

  const getBatchItemValue = (batchInfo, key) => {
    if (key === ACTIVE_BATCH_KEYS.TANK_CERTIFIED_DATE) {
      return batchInfo?.[key] ? displayDate(batchInfo[key]) : "N/A";
    }

    if (key === ACTIVE_BATCH_KEYS.REMAINING_VOLUME) {
      return remainingVolume;
    }

    if (key === ACTIVE_BATCH_KEYS.ACTUAL_ENDING_INV) {
      return batchInfo[ACTIVE_BATCH_KEYS.USER_INPUT__ENDING_INV];
    }
    return batchInfo[key];
  };

  const isEndingInventoryPresent = (batchInfo, key) => {
    return (
      key === ACTIVE_BATCH_KEYS.ACTUAL_ENDING_INV &&
      batchInfo[ACTIVE_BATCH_KEYS.USER_INPUT__ENDING_INV] != null
    );
  };

  return (
    <div data-test="copro-active-batch-card">
      <BatchesVolumeBar
        totalAmount={data?.total_volume}
        remainingAmount={data?.remaining_volume}
        endingAmount={data?.ending_inventory ?? null}
      />
      <div className="flex flex-col md:flex-row">
        <div className="pb-10 w-full md:w-1/2 px-2">
          {ACTIVE_BATCH_FIELDS.map((batch, index) => (
            <ActiveBatchField data-test="copro-active-batch-fields" key={index}>
              <ActiveBatchLabel>{batch.label}</ActiveBatchLabel>
              {batch.type === "text" ||
              isEndingInventoryPresent(data, batch.key) ? (
                <ActiveBatchValue>{`${getBatchItemValue(
                  data,
                  batch.key,
                )?.toLocaleString()} ${batch.metric ?? ""}`}</ActiveBatchValue>
              ) : (
                <ActiveBatchValue>
                  <MetricInput
                    value={getBatchItemValue(data, batch.key)}
                    details={batch}
                    showMetric={!!batch.metric}
                    maxValue={data[batch.max]}
                    onChange={handleInputChange}
                    reportErrors={handleErrors}
                    setChecking={handleLoading}
                  />
                </ActiveBatchValue>
              )}
            </ActiveBatchField>
          ))}
        </div>
        <ShipmentIdForm
          batchId={data?.batch_id}
          shipments={shipments}
          handleShipmentChange={handleShipmentChange}
          handleErrors={handleErrors}
          handleLoading={handleLoading}
          refreshData={refreshData}
          remainingVolume={remainingVolume}
          updateTotalVolume={updateTotalVolume}
        />
      </div>
    </div>
  );
};

ActiveBatchCard.propTypes = {
  data: PropTypes.any,
  handleChange: PropTypes.func,
  handleErrors: PropTypes.func,
  handleWarnings: PropTypes.func,
  handleLoading: PropTypes.func,
  refreshData: PropTypes.func,
};

export default ActiveBatchCard;
