export const siteRefId = {
  sweden: "c8f4261f9f46e6465709e17ebea7a92b",
  norway: "d5b9290a0b67727d4ba1ca6059dc31a6",
};

export const massBalanceType = {
  TR: "tr",
  TRS: "trs",
  CPP: "cpp",
};

export const countryNames = {
  SWEDEN: "sweden",
  NORWAY: "norway",
  UK: "uk",
};

export const status = {
  PENDING: "pending",
  AVAILABLE: "available",
  AVAILABLE_CO: "availableCo",
  ALLOCATED: "allocated",
  ALLOCATED_AND_SENT: "allocatedAndSent",
  RETIRED: "retired",
  WRITE_OFF: "writeOff",
  CARRIED_OVER: "carriedOver",
  INVALID_PURCHASE: "invalidPurchase",
  INVALID_OUTGOING: "invalidOutgoing",
  GENERATED: "generated",
  RESERVED: "reserved",
  TRANSFERRED: "transferred",
};

export const INCOMING = "Incoming";
export const OUTGOING = "Outgoing";
export const COPROCCESSED = "Co-processed";
export const INBOUND_RECORD = "inboundRecord";
export const OUTBOUND_RECORDS = "outboundRecords";
export const COPROCESSED_RECORD = "coProcessedRecord";

export const parentMapping = {
  [INCOMING]: INBOUND_RECORD,
  [OUTGOING]: OUTBOUND_RECORDS,
};

export const PINNED_COLUMNS_LIST = ["status", "actions"];

export const DATE_RANGE_SIZE = 2;

export const actionItems = {
  LINK_DOCUMENT: "Link document",
  UNLINK_DOCUMENT: "Unlink document",
  VIEW_DETAILS: "View details",
  ALLOCATE: "Allocate",
  REMOVE_ALLOCATION: "Remove allocation",
  UNRETIRE_FOR_MANDATE: "Unretire for mandate",
  RETIRE_FOR_MANDATE: "Retire for mandate",
  RESERVE: "Reserve",
  EDIT_RESERVATION: "Edit reservation",
  REMOVE_RESERVATION: "Remove reservation",
  TRANSFER: "Transfer",
  WRITE_OFF: "Write off",
  REMOVE_FROM_BALANCE: "Remove from balance",
  GENERATE_DOCUMENT: "Generate document",
  REMOVE_DOCUMENT: "Remove document",
  REMOVE_WRITE_OFF: "Remove write off",
  REMOVE_PURCHASE: "Remove purchase",
  REMOVE_OUTGOING_TRANSACTION: "Remove outgoing transaction",
};

export const MASS_BALANCE_CLOSE_SUCCESS_HEADER = "Balance closed";
export const MASS_BALANCE_CLOSE_SUCCESS_BODY =
  "Sucessfully closed this mass balance. Re-open if you need to edit the balance.";
export const MASS_BALANCE_CLOSE_ERROR_HEADER = "Balance cannot be closed";

export const MASS_BALANCE_CLOSING_API_MODAL = "mass-balance-closing-api-modal";
export const MASS_BALANCE_CLOSING_CONFIRMATION_MODAL =
  "mass-balance-closing-confirmation-modal";
