import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Form,
  FormGroup,
  Label,
  Spinner,
  Accordion,
  Button,
  Input,
  FormFeedback,
} from "reactstrap";
import {useForm, FormProvider} from "react-hook-form";
import {useMutation, useQuery} from "@apollo/client";
import {
  Alert32,
  Check24,
  Remove24,
  Save24,
} from "@bphxd/ds-core-react/lib/icons";
import {useMemo, useState, useCallback, useEffect} from "react";
import UPDATE_DOCUMENT from "graphql/es-co-processing/UpdateDocument";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import {useAccount, useMsal} from "@azure/msal-react";
import {toast} from "react-toastify";
import COPRO_EU_VIEW_DOCUMENT_QUERY from "../../../graphql/es-co-processing/ViewDocument";
import Layout from "../components/Layout";
import "./view-document.css";
import SupplierAndRecipientInfo from "../components/view-document/SupplierAndRecipientInfo";
import ScopeOfCertification from "../components/view-document/ScopeOfCertification";
import GeneralInformation from "../components/view-document/GeneralInformation";
import GHGEmissionInfo from "../components/view-document/GHGEmissionInfo";
import AdditionalInfo from "../components/view-document/AdditionalInfo";
import Subtitle from "../components/view-document/Subtitle";
import {DDV, TDV, parseToFloatNumber} from "../components/view-document/utils";
import "./index.scss";

const defaultValues = {
  additionalInfo: "",
  agriculturalBiomassIntermediateCropFlag: null,
  agriculturalBiomassLowRiskFlag: null,
  availableBioProductTons: "",
  productEnergyContent: "",
  certificateNumber: "",
  certificationSystem: "",
  chainOfCustodyOption: "",
  contractNumber: "",
  customerRef: "",
  dealNotes: "",
  deliveryNotes: "",
  density: "",
  densityUom: "",
  differenceVol: "",
  euRedCompliantFlag: null,
  expiryDate: "",
  feedstockCountryCode: "",
  feedstockIssueDate: "",
  feedstockMonth: "",
  feedstockQty: "",
  feedstockQtyUom: "",
  feedstockQuarter: "",
  fossilNotes: "",
  ghgEccr: 0,
  ghgEccs: 0,
  ghgEec: 0,
  ghgEee: 0,
  ghgEl: 0,
  ghgEmissionIn: "",
  ghgEmissionOut: "",
  ghgEmissionPercentageIn: "",
  ghgEmissionPercentageOut: "",
  ghgEp: 0,
  ghgEsca: 0,
  ghgEtd: 0,
  ghgEu: 0,
  ghgTotal: 0,
  importedInvoiceVol: "",
  invoiceProviderName: "",
  isccCompliantFlag: null,
  materialDispatchDate: "",
  materialSustainabilityCriteriaFlag: null,
  mbLocation: "",
  motDistanceKm: "",
  motFromRefinery: "",
  motToRefinery: "",
  observationNotes: "",
  operationDesc: "",
  orderNumber: "",
  originDestinationDesc: "",
  physicalReceiptDate: "",
  processedFeedstockTons: "",
  processType: "",
  productConcludedQty: "",
  productInvoiceVol: "",
  productionLossTons: "",
  productNetVol: "",
  productType: "",
  rawExpiryDate: "",
  rawIssueDate: "",
  rawMaterialCultivationCountryName: "",
  rawMaterialDeliveryDate: "",
  rawMaterialOriginCountryName: "",
  rawMaterialType: "",
  rawMaterialWasteOrResidueFlag: null,
  wasteOrAnimalByProductPermitNumber: "",
  supportForFuelProductionOrFuelPrecursorReceivedFlag: null,
  ifYesSpecifySupportNatureAndScheme: "",
  recipientAddress: "",
  recipientName: "",
  recipientReceiptAddress: "",
  recipientReceiptAddressSameFlag: null,
  refineryDischargeVol: "",
  refineryLoadedVol: "",
  sdNumber: "",
  supplierAddress: "",
  supplierDispatchAddress: "",
  supplierDispatchAddressSameFlag: null,
  supplierInvoiceVol: "",
  supplierName: "",
  thirdPartyName: "",
  transportationEmissionId: "",
  transportationEmissionRoute: "",
  transportLossTons: "",
  vesselAtHuelva: "",
  vesselAtTespa: "",
  emissionForTransport: false,
  defaultValueAppliedFlag: null,
  diseggregatedDefaultValueOilFlag: null,
  diseggregatedDefaultValueSoiln2oFlag: null,
  bonusCo245gManureUsedFlag: null,
  bonusCo229gManureUsedFlag: null,
  supplychainIncentiveReceivedFlag: null,
  supplychainIncentiveReceivedName: "",
  nuts2Region: "",
  splitDetails: [],
};

const tabFields = {
  supplier: [
    "feedstockIssueDate",
    "supplierName",
    "supplierAddress",
    "supplierDispatchAddress",
    "certificationSystem",
    "certificateNumber",
    "materialDispatchDate",
    "recipientName",
    "recipientAddress",
    "recipientReceiptAddress",
    "contractNumber",
  ],
  general: [
    "productType",
    "rawMaterialType",
    "additionalInfo",
    "rawMaterialOriginCountryName",
    "feedstockQty",
    "feedstockQtyUom",
    "isccCompliantFlag",
    "euRedCompliantFlag",
    "chainOfCustodyOption",
  ],
  emission: [
    "ghgEec",
    "ghgEl",
    "ghgEp",
    "ghgEtd",
    "ghgEu",
    "ghgEsca",
    "ghgEccs",
    "ghgEccr",
    "ghgTotal",
    "motFromRefinery",
    "motToRefinery",
    "motDistanceKm",
    "physicalReceiptDate",
    "splitDetails",
  ],
  certification: [
    "materialSustainabilityCriteriaFlag",
    "agriculturalBiomassIntermediateCropFlag",
    "agriculturalBiomassLowRiskFlag",
    "rawMaterialWasteOrResidueFlag",
    "wasteOrAnimalByProductPermitNumber",
    "supportForFuelProductionOrFuelPrecursorReceivedFlag",
    "ifYesSpecifySupportNatureAndScheme",
  ],
  additionalInfo: [
    "emissionForTransport",
    "mbLocation",
    "invoiceNumber",
    "vesselName",
    "observationNotes",
  ],
};

const None = "none";
const Accepting = "accepting";
const Rejecting = "rejecting";
const ViewDocumentPage = () => {
  const {appSetting, setAppSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const divisionCode = "COPRO";
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(divisionCode);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {docId} = useParams();
  const [open, setOpen] = useState([]);
  const [operation, setOperation] = useState(None);

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };
  const openAccordion = (id) => {
    if (!open.includes(id)) {
      setOpen([...open, id]);
    }
  };
  const {data: documentData, loading} = useQuery(COPRO_EU_VIEW_DOCUMENT_QUERY, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {docStatusId: docId},
  });

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: ({bioLcCoproEuUpdateInboundRecord}) => {
      if (bioLcCoproEuUpdateInboundRecord?.statusCode === 200) {
        toast.success(
          bioLcCoproEuUpdateInboundRecord?.message ??
            "Document updated successfully",
        );
        setTimeout(
          () =>
            navigate({
              pathname:
                "/copro-spain/spain/document-manager/incoming-documents",
              search: `?pageNo=${
                searchParams.get("pageNo") ?? 0
              }&docId=${docId}`,
            }),
          1000,
        );
      } else {
        toast.error(
          bioLcCoproEuUpdateInboundRecord?.message ??
            "Failed to update document",
        );
      }
      setOperation(None);
    },
  });
  const [invalidTabs, setInvalidTabs] = useState({
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  });

  const docValues = useMemo(() => {
    if (documentData && documentData.bioLcCoproEuGetInboundDoc != null) {
      const {
        additionalInfo = "",
        agriculturalBiomassIntermediateCropFlag = null,
        agriculturalBiomassLowRiskFlag = null,
        availableBioProductTons = "",
        productEnergyContent = "",
        certificateNumber = "",
        certificationSystem = "",
        chainOfCustodyOption = "",
        contractNumber = "",
        customerRef = "",
        dealNotes = "",
        deliveryNotes = "",
        density = "",
        densityUom = "",
        differenceVol = "",
        euRedCompliantFlag = null,
        expiryDate = "",
        feedstockCountryCode = "",
        feedstockIssueDate = "",
        feedstockMonth = "",
        feedstockQty = "",
        feedstockQtyUom = "",
        feedstockQuarter = "",
        fossilNotes = "",
        ghgEccr = 0,
        ghgEccs = 0,
        ghgEec = 0,
        ghgEee = 0,
        ghgEl = 0,
        ghgEmissionIn = "",
        ghgEmissionOut = "",
        ghgEmissionPercentageIn = "",
        ghgEmissionPercentageOut = "",
        ghgEp = 0,
        ghgEsca = 0,
        ghgEtd = 0,
        ghgEu = 0,
        ghgTotal = 0,
        importedInvoiceVol = "",
        invoiceProviderName = "",
        isccCompliantFlag = null,
        materialDispatchDate = "",
        materialSustainabilityCriteriaFlag = null,
        mbLocation = "",
        motDistanceKm = "",
        motFromRefinery = "",
        motToRefinery = "",
        observationNotes = "",
        operationDesc = "",
        orderNumber = "",
        originDestinationDesc = "",
        physicalReceiptDate = "",
        processedFeedstockTons = "",
        processType = "",
        productConcludedQty = "",
        productInvoiceVol = "",
        productionLossTons = "",
        productNetVol = "",
        productType = "",
        rawExpiryDate = "",
        rawIssueDate = "",
        rawMaterialCultivationCountryName = "",
        rawMaterialDeliveryDate = "",
        rawMaterialOriginCountryName = "",
        rawMaterialType = "",
        rawMaterialWasteOrResidueFlag = null,
        wasteOrAnimalByProductPermitNumber = "",
        supportForFuelProductionOrFuelPrecursorReceivedFlag = null,
        ifYesSpecifySupportNatureAndScheme = "",
        recipientAddress = "",
        recipientName = "",
        recipientReceiptAddress = "",
        recipientReceiptAddressSameFlag = null,
        refineryDischargeVol = "",
        refineryLoadedVol = "",
        sdNumber = "",
        supplierAddress = "",
        supplierDispatchAddress = "",
        supplierDispatchAddressSameFlag = null,
        supplierInvoiceVol = "",
        supplierName = "",
        thirdPartyName = "",
        transportationEmissionId = "",
        transportationEmissionRoute = "",
        transportLossTons = "",
        vesselAtHuelva = "",
        vesselAtTespa = "",
        defaultValueAppliedFlag = null,
        diseggregatedDefaultValueOilFlag = null,
        diseggregatedDefaultValueSoiln2oFlag = null,
        bonusCo245gManureUsedFlag = null,
        bonusCo229gManureUsedFlag = null,
        supplychainIncentiveReceivedFlag = null,
        supplychainIncentiveReceivedName = "",
        nuts2Region = "",
        splitDetails = [],
      } = documentData.bioLcCoproEuGetInboundDoc;
      const props = {
        sdNumber,
        feedstockCountryCode,
        rawIssueDate,
        feedstockIssueDate:
          feedstockIssueDate === "" ? "" : new Date(feedstockIssueDate),
        feedstockMonth,
        feedstockQuarter,
        feedstockQty,
        feedstockQtyUom,
        density,
        densityUom,
        supplierName,
        supplierAddress,
        supplierDispatchAddress,
        supplierDispatchAddressSameFlag,
        recipientName,
        recipientAddress,
        recipientReceiptAddress,
        recipientReceiptAddressSameFlag,
        certificationSystem,
        certificateNumber,
        materialDispatchDate:
          materialDispatchDate == null || materialDispatchDate === ""
            ? ""
            : new Date(materialDispatchDate),
        rawExpiryDate,
        expiryDate,
        contractNumber,
        orderNumber,
        customerRef,
        transportLossTons,
        productNetVol,
        productInvoiceVol,
        refineryLoadedVol,
        refineryDischargeVol,
        differenceVol,
        invoiceProviderName,
        supplierInvoiceVol,
        importedInvoiceVol,
        observationNotes,
        processedFeedstockTons,
        productConcludedQty,
        availableBioProductTons,
        productEnergyContent,
        productionLossTons,
        motFromRefinery,
        motDistanceKm,
        motToRefinery,
        originDestinationDesc,
        thirdPartyName,
        operationDesc,
        rawMaterialType,
        rawMaterialCultivationCountryName,
        physicalReceiptDate:
          physicalReceiptDate == null || physicalReceiptDate === ""
            ? ""
            : new Date(physicalReceiptDate),
        mbLocation,
        ghgEec: parseFloat(ghgEec ?? "0"),
        ghgEl: parseFloat(ghgEl ?? "0"),
        ghgEp: parseFloat(ghgEp ?? "0"),
        ghgEtd: ghgEtd === DDV ? ghgEtd : parseFloat(ghgEtd ?? "0"),
        ghgEu: parseFloat(ghgEu ?? "0"),
        ghgEsca: parseFloat(ghgEsca ?? "0"),
        ghgEccs: parseFloat(ghgEccs ?? "0"),
        ghgEccr: parseFloat(ghgEccr ?? "0"),
        ghgEee: parseFloat(ghgEee ?? "0"),
        ghgTotal:
          ghgTotal === TDV || ghgTotal === DDV
            ? ghgTotal
            : parseFloat(ghgTotal ?? "0"),
        ghgEmissionIn,
        ghgEmissionPercentageIn,
        ghgEmissionOut,
        ghgEmissionPercentageOut,
        productType,
        processType,
        rawMaterialOriginCountryName,
        rawMaterialDeliveryDate,
        chainOfCustodyOption,
        additionalInfo,
        deliveryNotes,
        dealNotes,
        fossilNotes,
        vesselAtHuelva,
        vesselAtTespa,
        transportationEmissionId,
        transportationEmissionRoute,
        isccCompliantFlag: isccCompliantFlag == null ? "" : isccCompliantFlag,
        euRedCompliantFlag:
          euRedCompliantFlag == null ? "" : euRedCompliantFlag,
        materialSustainabilityCriteriaFlag,
        agriculturalBiomassIntermediateCropFlag,
        agriculturalBiomassLowRiskFlag,
        rawMaterialWasteOrResidueFlag,
        wasteOrAnimalByProductPermitNumber,
        supportForFuelProductionOrFuelPrecursorReceivedFlag,
        ifYesSpecifySupportNatureAndScheme:
          ifYesSpecifySupportNatureAndScheme === null
            ? ""
            : ifYesSpecifySupportNatureAndScheme,
        emissionForTransport:
          transportationEmissionRoute !== null &&
          transportationEmissionRoute !== "",
        defaultValueAppliedFlag,
        diseggregatedDefaultValueOilFlag,
        diseggregatedDefaultValueSoiln2oFlag,
        bonusCo245gManureUsedFlag,
        bonusCo229gManureUsedFlag,
        supplychainIncentiveReceivedFlag,
        supplychainIncentiveReceivedName,
        nuts2Region,
        splitDetails: splitDetails?.map((split) => ({
          physicalReceiptDate:
            split.physicalReceiptDate == null ||
            split.physicalReceiptDate === ""
              ? ""
              : new Date(split.physicalReceiptDate),
          ghgEec: parseFloat(split.ghgEccr ?? "0"),
          ghgEl: parseFloat(split.ghgEl ?? "0"),
          ghgEp: parseFloat(split.ghgEp ?? "0"),
          ghgEtd:
            split.ghgEtd === DDV
              ? split.ghgEtd
              : parseFloat(split.ghgEtd ?? "0"),
          ghgEu: parseFloat(split.ghgEu ?? "0"),
          ghgEsca: parseFloat(split.ghgEsca ?? "0"),
          ghgEccs: parseFloat(split.ghgEccs ?? "0"),
          ghgEccr: parseFloat(split.ghgEccr ?? "0"),
          ghgEee: parseFloat(split.ghgEee ?? "0"),
          ghgTotal:
            split.ghgTotal === TDV || split.ghgTotal === DDV
              ? split.ghgTotal
              : parseFloat(split.ghgTotal ?? "0"),
          feedstockQty: parseFloat(split.feedstockQty ?? "0"),
          originCountryName: split.originCountryName,
          transportationEmissionId: split.transportationEmissionId,
          transportationEmissionRoute: split.transportationEmissionRoute,
          defaultValueAppliedFlag: split.defaultValueAppliedFlag,
        })),
      };
      return props;
    }
    return defaultValues;
  }, [documentData]);
  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    values: docValues,
    shouldFocusError: true,
  });
  const {
    register,
    formState: {errors},
    getValues,
    setError,
    trigger,
    setFocus,
  } = methods;

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Spain", link: "/"},
    {text: "Co-processing", link: "/copro-spain/spain"},
    {text: "Document manager", link: "/copro-spain/spain"},
    {
      text: "Incoming",
      link: {
        pathname: "/copro-spain/spain/document-manager/incoming-documents",
        search: `?pageNo=${searchParams.get("pageNo") ?? 0}&docId=${docId}`,
      },
    },
    {
      text: documentData?.bioLcCoproEuGetInboundDoc.sdNumber ?? docId,
      link: `/copro-spain/spain/document-manager/incoming-documents/${docId}`,
    },
  ];

  const validate = useCallback(async () => {
    const valid = await trigger();
    const values = getValues();
    let isOthersValid = true;
    if (
      values.splitDetails?.length === 0 &&
      values.physicalReceiptDate === ""
    ) {
      setError("physicalReceiptDate", {
        type: "value",
        message: "Please enter a physical receipt date",
      });
      isOthersValid = false;
    }
    if (values.materialDispatchDate === "") {
      setError("materialDispatchDate", {
        type: "value",
        message: "Please enter date of dispatch",
      });
    }
    if (
      values.splitDetails?.length === 0 &&
      values.defaultValueAppliedFlag !== true &&
      parseFloat(values.ghgEccr ?? "0") === 0 &&
      parseFloat(values.ghgEccs ?? "0") === 0 &&
      parseFloat(values.ghgEec ?? "0") === 0 &&
      parseFloat(values.ghgEee ?? "0") === 0 &&
      parseFloat(values.ghgEl ?? "0") === 0 &&
      parseFloat(values.ghgEp ?? "0") === 0 &&
      parseFloat(values.ghgEsca ?? "0") === 0 &&
      parseFloat(values.ghgEtd ?? "0") === 0 &&
      parseFloat(values.ghgEu ?? "0") === 0 &&
      (values.ghgTotal !== TDV ||
        values.ghgTotal !== DDV ||
        values.ghgEtd !== DDV)
    ) {
      setError("ghgEec", {
        type: "value",
        message: "At least one GHG emission must be greater than 0",
      });
      isOthersValid = false;
    }
    if (values.splitDetails?.length > 0) {
      values.splitDetails?.forEach((item, index) => {
        if (
          item.defaultValueAppliedFlag !== true &&
          parseFloat(item.ghgEccr ?? "0") === 0 &&
          parseFloat(item.ghgEccs ?? "0") === 0 &&
          parseFloat(item.ghgEec ?? "0") === 0 &&
          parseFloat(item.ghgEee ?? "0") === 0 &&
          parseFloat(item.ghgEl ?? "0") === 0 &&
          parseFloat(item.ghgEp ?? "0") === 0 &&
          parseFloat(item.ghgEsca ?? "0") === 0 &&
          parseFloat(item.ghgEtd ?? "0") === 0 &&
          parseFloat(item.ghgEu ?? "0") === 0 &&
          (item.ghgTotal !== TDV ||
            item.ghgTotal !== DDV ||
            item.ghgEtd !== DDV)
        ) {
          setError(`splitDetails.${index}.ghgEec`, {
            type: "value",
            message: "At least one GHG emission must be greater than 0",
          });
          isOthersValid = false;
        }
      });
    }
    if (values.euRedCompliantFlag == null) {
      setError("euRedCompliantFlagYes", {
        type: "value",
        message: "Please select EU RED Compliant Flag",
      });
      isOthersValid = false;
    }

    if (values.isccCompliantFlag == null) {
      setError("isccCompliantFlagYes", {
        type: "value",
        message: "Please select ISCC Compliant Flag",
      });
      isOthersValid = false;
    }

    const flags = {
      supplier: false,
      general: false,
      emission: false,
      certification: false,
      additionalInfo: false,
    };
    const tabsRequireAttention = [];
    const tabsOpenByDefault = ["additionalInfo"];
    Object.keys(tabFields).forEach((tab) => {
      const tabErrors = tabFields[tab].filter((field) => errors[field] != null);
      if (tabErrors.length > 0) {
        console.log("tabErrors", tabErrors);
        flags[tab] = true;
        if (flags[tab] && tab !== "additionalInfo") {
          tabsRequireAttention.push(tab);
        }
      }
    });
    setInvalidTabs(flags);
    setOpen([...tabsRequireAttention, ...tabsOpenByDefault]);
    return valid && isOthersValid;
  }, [getValues, setError, trigger, errors]);

  useEffect(() => {
    // Make sure we trigger form validation after values change (e.g. after loading) to show errors
    // aka simulating next tick here
    setTimeout(() => validate(), 10);
  }, [docValues, validate]);

  const validateAndSave = async (accept = false) => {
    if (accept) {
      const isValid = await validate();
      if (isValid) {
        setOperation(Accepting);
        const {emissionForTransport, splitDetails, ...data} = getValues();
        await updateDocument({
          variables: {
            event: {
              ...data,
              ghgEec:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEec),
              ghgEl:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEl ?? 0),
              ghgEp:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEp ?? 0),
              ghgEtd:
                data.ghgTotal === TDV
                  ? 0
                  : data.ghgEtd === DDV
                  ? data.ghgEtd
                  : parseToFloatNumber(data.ghgEtd ?? "0"),
              ghgEu:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEu ?? 0),
              ghgEsca:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEsca ?? 0),
              ghgEccs:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEccs ?? 0),
              ghgEccr:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEccr ?? 0),
              ghgEee:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEee ?? 0),
              ghgTotal:
                data.ghgTotal === TDV || data.ghgTotal === DDV
                  ? data.ghgTotal
                  : parseToFloatNumber(data.ghgTotal ?? "0"),
              userAction: "ACCEPT",
              changedBy: account.username,
              siteReferenceId: siteReferenceData?.siteReferenceId,
              divisionId: divisionData?.divisionId,
              splitDetails: splitDetails?.map((split) => ({
                ...split,
                transportationEmissionId: emissionForTransport
                  ? split.transportationEmissionId
                  : null,
                transportationEmissionRoute: emissionForTransport
                  ? split.transportationEmissionRoute
                  : null,

                ghgEec:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEec ?? "0"),
                ghgEl:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEl ?? "0"),
                ghgEp:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEp ?? "0"),
                ghgEtd:
                  split.ghgTotal === TDV
                    ? 0
                    : split.ghgEtd === DDV
                    ? split.ghgEtd
                    : parseToFloatNumber(split.ghgEtd ?? "0"),
                ghgEu:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEu ?? "0"),
                ghgEsca:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEsca ?? "0"),
                ghgEccs:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEccs ?? "0"),
                ghgEccr:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEccr ?? "0"),
                ghgEee:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEee ?? "0"),
                ghgTotal:
                  split.ghgTotal === TDV || split.ghgTotal === DDV
                    ? split.ghgTotal
                    : parseToFloatNumber(split.ghgTotal ?? "0"),
              })),
            },
          },
        });
      }
    } else {
      const {emissionForTransport, splitDetails, ...data} = getValues();
      if ((data.observationNotes ?? "").trim() === "") {
        setError("observationNotes", {
          type: "value",
          message: "Please enter a reason for rejection",
        });
        openAccordion("additionalInfo");
        setTimeout(
          () => setFocus("observationNotes", {shouldSelect: true}),
          250,
        );
      } else {
        setOperation(Rejecting);
        await updateDocument({
          variables: {
            event: {
              ...data,
              ghgEec:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEec),
              ghgEl:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEl ?? 0),
              ghgEp:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEp ?? 0),
              ghgEtd:
                data.ghgTotal === TDV
                  ? 0
                  : data.ghgEtd === DDV
                  ? data.ghgEtd
                  : parseToFloatNumber(data.ghgEtd ?? "0"),
              ghgEu:
                data.ghgTotal === TDV ? 0 : parseToFloatNumber(data.ghgEu ?? 0),
              ghgEsca:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEsca ?? 0),
              ghgEccs:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEccs ?? 0),
              ghgEccr:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEccr ?? 0),
              ghgEee:
                data.ghgTotal === TDV
                  ? 0
                  : parseToFloatNumber(data.ghgEee ?? 0),
              ghgTotal:
                data.ghgTotal === TDV || data.ghgTotal === DDV
                  ? data.ghgTotal
                  : parseToFloatNumber(data.ghgTotal ?? "0"),
              splitDetails: splitDetails?.map((split) => ({
                ...split,
                ghgEec:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEec ?? "0"),
                ghgEl:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEl ?? "0"),
                ghgEp:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEp ?? "0"),
                ghgEtd:
                  split.ghgTotal === TDV
                    ? 0
                    : split.ghgEtd === DDV
                    ? split.ghgEtd
                    : parseToFloatNumber(split.ghgEtd ?? "0"),
                ghgEu:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEu ?? "0"),
                ghgEsca:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEsca ?? "0"),
                ghgEccs:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEccs ?? "0"),
                ghgEccr:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEccr ?? "0"),
                ghgEee:
                  split.ghgTotal === TDV
                    ? 0
                    : parseToFloatNumber(split.ghgEee ?? "0"),
                ghgTotal:
                  split.ghgTotal === TDV || split.ghgTotal === DDV
                    ? split.ghgTotal
                    : parseToFloatNumber(split.ghgTotal ?? "0"),
              })),
              userAction: "REJECT",
              changedBy: account.username,
              siteReferenceId: siteReferenceData?.siteReferenceId,
              divisionId: divisionData?.divisionId,
            },
          },
        });
      }
    }
  };
  const onAccept = async () => validateAndSave(true);
  const onReject = async () => validateAndSave(false);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const focusOnFirstError = (tab) => {
    if (tabFields[tab] != null) {
      // set focus on first error field
      for (let i = 0; i < tabFields[tab].length; i += 1) {
        const field = tabFields[tab][i];
        if (errors[field]) {
          setFocus(field);
          break;
        }
      }
    }
  };

  const renderTitle = (docStatus) => {
    switch (docStatus) {
      case "attention":
        return "Missing expected information";
      case "attention-split":
        return "Multiple entries found";
      case "ready_for_mb":
        return "Ready to assign to mass balance";
      case "additional_info":
        return "Add additional information";
      default:
        return "";
    }
  };
  if (loading) {
    return (
      <div className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100">
        <Spinner />
      </div>
    );
  }
  const status = documentData?.bioLcCoproEuGetInboundDoc?.documentCurrentStatus;

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 ">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={renderTitle(status)}
            breadcrumbs={breadcrumbItems}
            subtitle={
              status !== "completed" ? (
                <Subtitle
                  className="px-7"
                  status={status}
                  invalidTabs={invalidTabs}
                  onClick={(tab) => {
                    openAccordion(tab);
                    // allow expand animation to finish before focusing on first error
                    setTimeout(() => focusOnFirstError(tab), 150);
                  }}
                />
              ) : (
                <span />
              )
            }
          >
            <div className="flex flex-col p-2 min-h-[800px] px-7 pt-6">
              <FormProvider {...methods}>
                <Form className="flex flex-col gap-5">
                  <FormGroup>
                    <Label for="sdNumber" className="fw-light">
                      Unique number of sustainability declaration
                    </Label>
                    <Input
                      type="text"
                      id="sdNumber"
                      {...computeProps("sdNumber", {
                        required: "Please enter a sd number",
                      })}
                      invalid={!!errors.sdNumber}
                      maxLength={100}
                      disabled={
                        status === "additional_info" || status === "completed"
                      }
                    />
                    {errors.sdNumber && (
                      <FormFeedback>{errors.sdNumber.message}</FormFeedback>
                    )}
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader targetId="supplier">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.supplier && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          Supplier and recipient information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="supplier"
                        className="mb-2 mt-6"
                      >
                        <SupplierAndRecipientInfo status={status} />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="general">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.general && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          General information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="general"
                        className="mb-2 mt-6"
                      >
                        <GeneralInformation
                          status={status}
                          countries={
                            documentData?.bioLcCoproEuGetCountries ?? []
                          }
                          rawMaterialTypes={
                            documentData?.bioLcCoproRawMaterialAPI?.Records ??
                            []
                          }
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="certification">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center justify-items-center h-8">
                          {invalidTabs.certification && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          Scope of certification of raw material
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="mb-2 mt-6"
                      >
                        <ScopeOfCertification status={status} />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="emission">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.emission && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          Greenhouse gas (GHG) emission information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="emission"
                        className="mb-2 mt-6"
                      >
                        <GHGEmissionInfo
                          status={status}
                          countries={
                            documentData?.bioLcCoproEuGetCountries ?? []
                          }
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="additionalInfo">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.additionalInfo && (
                            <Alert32 className="icon-requires--attention accordion-btn-icon-prefix" />
                          )}
                          Additional information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="additionalInfo"
                        className="mb-2 mt-6"
                      >
                        <AdditionalInfo
                          status={status}
                          mbLocations={
                            documentData.bioLcCoproConfigCenterLocationDetails
                              ?.Records ?? []
                          }
                          transportEmissions={
                            documentData
                              .bioLcCoproConfigCenterTransportationEmission
                              ?.Records ?? []
                          }
                        />
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4 mb-24">
                    {(status === "completed" ||
                      status === "additional_info") && (
                      <Button
                        color={
                          status === "additional_info"
                            ? "tertiary"
                            : "standard-primary"
                        }
                        className={
                          status === "additional_info"
                            ? "show rounded-0"
                            : "primary-btn rounded-0"
                        }
                        type="button"
                        onClick={() =>
                          navigate({
                            pathname:
                              "/copro-spain/spain/document-manager/incoming-documents",
                            search: `?pageNo=${
                              searchParams.get("pageNo") ?? 0
                            }&docId=${docId}`,
                          })
                        }
                      >
                        <Remove24 className="btn-icon-prefix" />
                        Cancel
                      </Button>
                    )}
                    {status !== "completed" && (
                      <>
                        {status !== "additional_info" && (
                          <Button
                            color="tertiary"
                            outline
                            type="button"
                            onClick={onReject}
                            disabled={operation !== None}
                            className="show rounded-0"
                          >
                            {operation === Rejecting ? (
                              <Spinner size="sm" className="btn-icon-prefix" />
                            ) : (
                              <Remove24 className="btn-icon-prefix" />
                            )}
                            Reject
                          </Button>
                        )}
                        <Button
                          type="button"
                          color="standard-primary"
                          className="primary-btn rounded-0"
                          onClick={onAccept}
                          disabled={operation !== None || status === "accepted"}
                        >
                          {operation === Accepting ? (
                            <Spinner size="sm" className="btn-icon-prefix" />
                          ) : status === "additional_info" ? (
                            <Save24 className="btn-icon-prefix" />
                          ) : (
                            <Check24 className="btn-icon-prefix" />
                          )}
                          {status === "additional_info" ? "Save" : "Accept"}
                        </Button>
                      </>
                    )}
                  </div>
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {documentData?.bioLcCoproEuDownloadFile?.downloadUrl != null &&
          documentData?.bioLcCoproEuDownloadFile?.downloadUrl.includes(
            "file-not-found",
          ) ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={documentData?.bioLcCoproEuDownloadFile.downloadUrl}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewDocumentPage;
