import React from "react";
import moment from "moment-timezone";
import {Datepicker, Timepicker} from "@bphxd/ds-core-react";
import {Input, InputGroup, InputGroupText} from "reactstrap";
import parseAndRoundToTwoDecimal from "utils/numberUtil";
import {
  dtnShipmentKeys,
  VOLUME_PER_HOUR,
} from "modules/co-processing/constants/shipments";
import InputNumeric from "modules/common/lnputNumeric";
import dateFns from "utils/helpers/dateFunctions";
import {convertPSTtoUTC} from "modules/co-processing/helpers/formBuilder";

export const formatDatePicker = (date) =>
  date ? dateFns.convertDateFormat_MM_DD_YYYY(date) : "-";

export const formatTimePicker = (time) => {
  if (!time) return "-";

  const formatted = moment(time, "HH:mm").format("hh:mm A");

  return formatted;
};

export function calculateMaxVolume(startTime, endTime) {
  if (!startTime || !endTime) {
    return null;
  }

  // Times are received in format "YYYY-MM-DDTHH:mm:ss", interested only in "HH:mm" part for calculation.
  const format = "HH:mm";
  const startMoment = moment(startTime, format);
  const endMoment = moment(endTime, format);

  // Adjust for times that cross midnight
  if (endMoment.isBefore(startMoment)) {
    endMoment.add(1, "days"); // End time is never more than a day after start time.
  }

  const diffHours = endMoment.diff(startMoment, "hours", true);

  const estimatedVolume = diffHours * VOLUME_PER_HOUR;

  return parseAndRoundToTwoDecimal(estimatedVolume);
}

export function displayFieldInfo(fieldName, fieldValue, fieldConfig) {
  if (fieldName === dtnShipmentKeys.RENEWABLE_SHIPMENT_FLAG) {
    return fieldValue === false;
  }
  return !!fieldConfig.info;
}

// Combines date and time into a single string
const formatShipmentDateTime = (date, time) => {
  const isValidDate = moment(date, "YYYY-MM-DD", true).isValid();
  const isValidTime = moment(time, "HH:mm", true).isValid();

  if (isValidDate && isValidTime) {
    return `${date} ${time}`;
  }

  return date;
};

const parseVolume = (volume = "") => {
  const parsedVolume = parseFloat(volume.replace(/,/g, ""));

  return Number.isNaN(parsedVolume) ? 0 : parsedVolume;
};

export function formatTruckRack(formData) {
  if (!formData) {
    return null;
  }

  const {shipment_datez, shipment_start_datez, shipment_end_datez, ...rest} =
    formData;

  const shipmentStart = formatShipmentDateTime(
    shipment_datez,
    shipment_start_datez,
  );
  const shipmentEnd = formatShipmentDateTime(
    shipment_datez,
    shipment_end_datez,
  );

  const pstShipmentDate = new Date(
    new Date(shipment_datez).getTime() +
      new Date().getTimezoneOffset() * 1000 * 60,
  );

  return {
    ...rest,
    shipment_datez: convertPSTtoUTC(pstShipmentDate),
    shipment_start_datez: convertPSTtoUTC(shipmentStart),
    shipment_end_datez: convertPSTtoUTC(shipmentEnd),
  };
}

export const renderInputs = {
  input: ({addOn, error, field, placeholder, type}) => (
    <InputGroup className="bg-white">
      <Input
        {...field}
        invalid={!!error}
        placeholder={placeholder}
        type={type}
      />
      {addOn && (
        <InputGroupText className="bg-transparent">{addOn}</InputGroupText>
      )}
    </InputGroup>
  ),

  date: ({error, field}) => {
    return (
      <Datepicker
        name={field.name}
        required
        className="createShipment__datePicker"
        invalid={!!error}
        options={{
          altFormat: "m/j/Y",
          dateFormat: "Y-m-d",
          defaultDate: field?.value,
          onChange: (_, dateStr) => field?.onChange(dateStr),
        }}
      />
    );
  },

  time: ({field, error, startTime}) => {
    const options = {
      altInput: true,
      enableTime: true,
      noCalendar: true,
      altFormat: "h:i K", // Display format for user
      defaultDate: field?.value,
      dateFormat: "H:i",
      minTime:
        field.name === dtnShipmentKeys.SHIPMENT_END_DATE
          ? startTime
          : undefined,
      onChange: (selectedDates, dateStr) => {
        field.onChange(dateStr);
      },
    };

    return (
      <Timepicker
        required
        id={field?.name}
        name={field?.name}
        invalid={!!error}
        options={options}
        className="createShipment__timePicker form-control-prepended"
      />
    );
  },

  select: ({field, error, options, placeholder = "Select an option"}) => {
    return (
      <Input type="select" {...field} invalid={!!error}>
        <option key="none" disabled hidden value="">
          {`-- ${placeholder} --`}
        </option>

        {options?.map((option) => (
          <option key={option?.value || option} value={option?.value || option}>
            {option?.label || option}
          </option>
        ))}
      </Input>
    );
  },

  number: ({field, error, addOn, ...config}) => {
    return (
      <InputGroup className="bg-white">
        <InputNumeric
          {...field}
          className="form-control"
          decimals={2}
          placeholder={config?.placeholder}
          thousandSeparator
        />
        {addOn && (
          <InputGroupText className="bg-transparent">{addOn}</InputGroupText>
        )}
      </InputGroup>
    );
  },
};

export const formatBackToUnit = (formData) => {
  const {shipment_datez = {}} = formData;
  const shipmentDate =
    formatShipmentDateTime(shipment_datez?.date, shipment_datez?.time) || null;

  return {
    ...formData,
    shipment_datez: convertPSTtoUTC(shipmentDate),
    estimated_volume: parseVolume(formData?.estimated_volume),
  };
};
