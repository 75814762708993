import moment from "moment";
import {useEffect} from "react";
import PropTypes from "prop-types";
import {FormGroup, Label, Input} from "reactstrap";
import {
  OP_CONDITIONS,
  ADD_BATCH_FORM,
  ADD_BATCH_DATE_FIELDS,
} from "modules/co-processing/constants/batches";
import {Controller, useFormContext} from "react-hook-form";
import {useSplitBatch} from "../Context/SplitBatchContext";
import Form from "./Form";

const batchFormLabels = ADD_BATCH_FORM.labels;
const batchFieldKeys = ADD_BATCH_FORM.keys;

export const OperatingCondition = () => {
  return (
    <Form.Select
      label={batchFormLabels.opCondition}
      name={batchFieldKeys.opCondition}
      options={Object.values(OP_CONDITIONS)}
    />
  );
};

export const DHDSDateTime = ({prefix = ""}) => {
  const {watch, setError, clearErrors} = useFormContext();
  const [startTime, endTime, latestEndDate] = watch([
    batchFieldKeys.batchStartTime,
    batchFieldKeys.batchEndTime,
    batchFieldKeys.latestEndDate,
  ]);

  useEffect(() => {
    const start = moment(startTime);
    const end = moment(endTime);
    const latestEnd = moment(latestEndDate);

    if (start.isValid() && end.isValid() && start.isSameOrAfter(end)) {
      setError(batchFieldKeys.batchStartTime, {
        type: "custom",
        message: "Start time must be earlier than end time.",
      });
      setError(batchFieldKeys.batchEndTime, {
        type: "custom",
        message: "End time must be after start time.",
      });
    } else if (start.isValid() && start.isBefore(latestEnd)) {
      setError(batchFieldKeys.batchStartTime, {
        type: "custom",
        message: ADD_BATCH_FORM.messages.END_DATE_OVERLAP_INFO,
      });
    } else {
      clearErrors([
        batchFieldKeys.batchStartTime,
        batchFieldKeys.batchEndTime,
        batchFieldKeys.latestEndDate,
      ]);
    }
  }, [startTime, endTime, latestEndDate, setError, clearErrors]);

  return (
    <>
      {ADD_BATCH_DATE_FIELDS.map(({name, label}) => (
        <Form.DateTimePicker key={name} name={name} label={prefix + label} />
      ))}
    </>
  );
};
DHDSDateTime.propTypes = {prefix: PropTypes.string};

export const SplitBatch = ({isBypassed = false}) => {
  const {watch} = useFormContext();
  const {toggleHandler} = useSplitBatch();
  const isSplitBatch = watch(batchFieldKeys.splitBatch);

  const {setValue} = useFormContext();

  useEffect(() => {
    if (isBypassed) {
      setValue(batchFieldKeys.splitBatch, false);
    }
  }, [isBypassed, setValue]);

  useEffect(() => {
    toggleHandler(isSplitBatch);
  }, [toggleHandler, isSplitBatch]);

  return isBypassed ? null : (
    <Form.Toggle
      label={batchFormLabels.splitBatch}
      name={batchFieldKeys.splitBatch}
    />
  );
};
SplitBatch.propTypes = {isBypassed: PropTypes.bool};

export const BatchIDTextField = ({batchOptions = []}) => {
  const {control} = useFormContext();

  return (
    <Form.Field label={batchFormLabels.batchId} name={batchFieldKeys.batchId}>
      <Controller
        name={batchFieldKeys.batchId}
        control={control}
        render={({field}) => {
          return (
            <Input
              name={batchFieldKeys.batchId}
              value={field?.value ?? ""}
              {...field}
            />
          );
        }}
      />
    </Form.Field>
  );
};

export const Notes = () => {
  const {control} = useFormContext();

  return (
    <FormGroup className="col-sm-12 mb-3 mb-lg-5">
      <Label for={batchFieldKeys.batch_notes}>
        {batchFormLabels.batch_notes}
      </Label>

      <Controller
        name={batchFieldKeys.batch_notes}
        control={control}
        render={({field}) => {
          return (
            <Input
              {...field}
              className="bg-white"
              type="textarea"
              maxLength={300}
              placeholder="Add notes"
              rows="2"
              value={field?.value ?? ""}
            />
          );
        }}
      />
      <span className="label-optional" />
    </FormGroup>
  );
};

BatchIDTextField.propTypes = {batchOptions: PropTypes.array};
