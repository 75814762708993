import moment from "moment";
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from "react-hook-form";
import PropTypes from "prop-types";
import {FormGroup, FormFeedback, Label, Input} from "reactstrap";
import {Datepicker} from "@bphxd/ds-core-react";
import {ADD_BATCH_FORM, TOGGLE} from "modules/co-processing/constants/batches";

const batchKeys = ADD_BATCH_FORM.keys;

const Form = ({onSubmit, children, defaultValues}) => {
  const {handleSubmit, ...methods} = useForm({
    defaultValues,
    mode: "onSubmit",
  });

  return (
    <FormProvider {...methods}>
      <form
        className="addBatch__form"
        id="add_batch_form"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        {children}
      </form>
    </FormProvider>
  );
};

const FormField = ({children, className, error, label, name}) => {
  return (
    <FormGroup className={["addBatch__field", "col-sm-6", className].join(" ")}>
      <Label for={name}>{label}</Label>

      {children}

      {error && (
        <FormFeedback style={{display: "block"}} invalid="true">
          {error.message || ADD_BATCH_FORM.messages.MISSING_INFO}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

const DHDS_KEYS = [batchKeys.batchStartTime, batchKeys.batchEndTime];

const DateTimePicker = ({label, name}) => {
  const {
    control,
    formState: {errors},
    getValues,
    watch,
  } = useFormContext();

  const latestEndDate = watch(batchKeys.latestEndDate);

  const [startTime, endTime] = getValues(DHDS_KEYS);

  return (
    <FormField label={label} name={name} error={errors?.[name]}>
      <Controller
        name={name}
        control={control}
        rules={{
          required: true,
          validate: (v) => {
            if (
              moment(startTime).isSameOrAfter(moment(endTime)) &&
              DHDS_KEYS.includes(name)
            ) {
              return name === batchKeys.batchStartTime
                ? "Start time must be earlier than end time."
                : "End time must be after start time.";
            }

            if (
              moment(v).isBefore(moment(latestEndDate)) &&
              name === batchKeys.batchStartTime
            ) {
              return ADD_BATCH_FORM.messages.END_DATE_OVERLAP_INFO;
            }
            return undefined;
          },
        }}
        render={({field}) => {
          return (
            <Datepicker
              name={name}
              required
              className="datePicker"
              invalid={!!errors[name]}
              options={{
                allowInput: false,
                altFormat: "m/j/Y h:i K",
                dateFormat: "Z",
                minDate:
                  name === batchKeys.batchEndTime ? startTime : undefined,
                defaultDate: field?.value,
                enableTime: true,
                minuteIncrement: 1,
                onChange: (selectedDates, dateStr) => field?.onChange(dateStr),
              }}
            />
          );
        }}
      />
    </FormField>
  );
};

const STATUS_TEXT = {
  DEFAULT: "-- Select an option --",
  LOADING: "Loading...",
  NO_OPTIONS: "No options available",
};

export const getStatusText = (loading, noOptions) => {
  return loading
    ? STATUS_TEXT.LOADING
    : noOptions
    ? STATUS_TEXT.NO_OPTIONS
    : STATUS_TEXT.DEFAULT;
};

const Select = ({label, name, options = [], isLoading, ...props}) => {
  const {
    control,
    formState: {errors},
  } = useFormContext();
  const noOptions = options.length === 0;
  const placeholderText = getStatusText(isLoading, noOptions);

  return (
    <FormField
      label={label}
      name={name}
      className={props.className}
      error={errors?.[name]}
      isLoading={isLoading}
    >
      <Controller
        {...props}
        control={control}
        name={name}
        rules={{required: true}}
        render={({field}) => {
          return (
            <Input
              type="select"
              className="addBatch__input"
              {...field}
              invalid={!!errors[name]}
              id={name}
            >
              <option disabled={noOptions} hidden={!!options.length} value="">
                {placeholderText}
              </option>

              {options.map((item) => (
                <option key={item.value || item} value={item.value || item}>
                  {item.label || item}
                </option>
              ))}
            </Input>
          );
        }}
      />
    </FormField>
  );
};

const Switch = ({label, name}) => {
  const {control} = useFormContext();

  return (
    <FormField label={label} name={name}>
      <Controller
        control={control}
        name={name}
        render={({field}) => {
          return (
            <FormGroup switch>
              <Input
                id={`input-${name}`}
                type="switch"
                {...field}
                checked={field?.value}
              />
              <Label for={`input-${name}`} className="text-sm/4" color="muted">
                {field?.value ? TOGGLE.YES : TOGGLE.NO}
              </Label>
            </FormGroup>
          );
        }}
      />
    </FormField>
  );
};

const fieldPropTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};

Form.propTypes = {
  children: PropTypes.node.isRequired,
  defaultValues: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

FormField.propTypes = {
  ...fieldPropTypes,
  children: PropTypes.node,
  className: PropTypes.string,
  error: PropTypes.any,
};

DateTimePicker.propTypes = fieldPropTypes;
Switch.propTypes = {...fieldPropTypes, isDisabled: PropTypes.bool};
Select.propTypes = {
  ...fieldPropTypes,
  isLoading: PropTypes.bool,
  option: PropTypes.array,
};

Form.Field = FormField;
Form.DateTimePicker = DateTimePicker;
Form.Select = Select;
Form.Toggle = Switch;

export default Form;
