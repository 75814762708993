import React, {useMemo, useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import {GET_MODULE_MAPPING} from "graphql/global/getModuleMapping";
import PropTypes from "prop-types";
import {AppSettingDetailContext} from "./context";

export const AppSetting = ({children}) => {
  const {data: moduleMappingData} = useQuery(GET_MODULE_MAPPING);

  const [appSetting, setAppSetting] = useState({
    featureMappingData: null, // contains all the data from module catalogue table
    currentSite: "GLOBAL", // keeps the current selected site NOTE: not is use now
    currentCountry: "GLOBAL", // keeps the current selected country
    currentModule: "", // keeps the current selected module
    currentModuleTitle: "", // keeps the current selected module title
    currentCountryMappingData: null, // keeps the current selected country feature mapping data
    siteData: [], // keeps master site details
    divisionData: [], // keeps master division details
  });

  useEffect(() => {
    const moduleMappingDataItems =
      moduleMappingData?.bioLcGlobNaviGetModuleCatalogue;
    const countryFeatureData =
      moduleMappingData?.bioLcGlobNaviGetModuleCatalogue?.find(
        (item) => item.countryName === "GLOBAL",
      );
    if (moduleMappingDataItems) {
      setAppSetting((appSetting) => {
        return {
          ...appSetting,
          currentCountryMappingData: countryFeatureData,
          featureMappingData:
            moduleMappingData?.bioLcGlobNaviGetModuleCatalogue,
          siteData:
            moduleMappingData?.bioLcGetSiteReferenceDetails?.siteReferenceData,
          divisionData:
            moduleMappingData?.bioLcGetDivisionDetails?.divisionData,
        };
      });
    }
  }, [
    moduleMappingData?.bioLcGlobNaviGetModuleCatalogue,
    moduleMappingData?.bioLcGetSiteReferenceDetails?.siteReferenceData,
    moduleMappingData?.bioLcGetDivisionDetails?.divisionData,
  ]);

  const appSettingContextValue = useMemo(() => {
    return {appSetting, setAppSetting};
  }, [appSetting, setAppSetting]);

  return (
    <AppSettingDetailContext.Provider value={appSettingContextValue}>
      {children}
    </AppSettingDetailContext.Provider>
  );
};

AppSetting.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppSetting;
