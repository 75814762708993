import {Edit24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useState, useEffect} from "react";
import {
  BATCH_SUMMARY_COLUMNS,
  OP_CONDITIONS,
} from "modules/co-processing/constants/batches";
import {displayDate} from "modules/co-processing/helpers/dateHelper";
import EditBatchModal from "./EditModal";
import "./index.scss";

const BatchSummaryDisplay = ({batchDetails, refetch}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [columns, setColumns] = useState(BATCH_SUMMARY_COLUMNS);
  const isByPass = batchDetails.operating_condition === OP_CONDITIONS.BY_PASSED;

  useEffect(() => {
    if (isByPass) {
      setColumns((prevColumns) =>
        prevColumns.map((col) => {
          if (col.key === "batch_start_time") {
            return {...col, label: "Start time"};
          }
          if (col.key === "batch_end_time") {
            return {...col, label: "End time"};
          }
          return col;
        }),
      );
    }
  }, [isByPass]);

  const renderFormField = (item) => {
    const value = batchDetails?.[item.key];
    return item.type === "date" ? (
      <span data-test="copro-batch-date-text">{displayDate(value)}</span>
    ) : (
      <span data-test="copro-batch-text">{value}</span>
    );
  };

  const openModal = (itemDetails) => {
    setUpdateDetails(() => ({
      ...itemDetails,
      batchId: batchDetails?.batch_id,
    }));
    setModalOpen(true);
  };

  return (
    <div className="grid grid-cols-2 gap-4 pt-7 pb-8">
      {columns.map((item, index) => (
        <div key={index} className="pb-10">
          <div data-test="batch-detail-body-item" className="text-xs pb-4">
            <span className="batch-input-container">
              {item.label}
              {item.editable && (
                <Edit24
                  data-test="batch-detail-edit-icon"
                  className="cursor-pointer"
                  onClick={() => {
                    openModal(item);
                  }}
                />
              )}
            </span>
          </div>
          <div className="text-xl">{renderFormField(item)}</div>
        </div>
      ))}
      <EditBatchModal
        details={updateDetails}
        setModal={setModalOpen}
        modal={modalOpen}
        refetch={refetch}
        onClose={() => setModalOpen(false)}
      />
    </div>
  );
};

BatchSummaryDisplay.propTypes = {
  batchDetails: PropTypes.object,
  refetch: PropTypes.func,
};

export default BatchSummaryDisplay;
