import PropTypes from "prop-types";
import {useState, useCallback} from "react";
import {Button, ModalBody, Modal, ModalHeader, Input, Label} from "reactstrap";
import {useLazyQuery} from "@apollo/client";
import {UPDATE_BATCH} from "graphql/coprocessing/batches";
import {BATCH_UPDATE_MODAL} from "modules/co-processing/constants/batches";
import {Close} from "@bphxd/ds-core-react";
import {toast} from "react-toastify";
import {BUTTONS} from "modules/co-processing/constants/shipments";

const EditBatchModal = ({details, modal, setModal, refetch, onClose}) => {
  const [detailValue, setDetailValue] = useState(details?.value);

  const [updateBatch] = useLazyQuery(UPDATE_BATCH, {
    fetchPolicy: "no-cache",
    onCompleted: (result) => {
      if (result?.bioLcCoproUsBatchesApi?.statusCode === 200) {
        toast.success(BATCH_UPDATE_MODAL.UPDATE_SUCCESS);
        refetch();
        onClose();
      } else {
        toast.error(
          `"${BATCH_UPDATE_MODAL.UPDATE_FAILURE} - ${result?.bioLcCoproUsBatchesApi?.error}"`,
        );
      }
    },
  });

  const close = () => {
    setDetailValue(null);
    setModal(false);
  };

  const handleChange = useCallback((event) => {
    const {value} = event.target;
    setDetailValue(value);
  }, []);

  const onSaveDetail = (notes) => {
    updateBatch({
      variables: {
        batch_id: details?.batchId,
        updates: [
          {
            column: "batch_notes",
            value: notes,
          },
        ],
      },
    });

    close();
  };

  const getDefaultInput = () => {
    return (
      <Input
        style={{resize: "none"}}
        data-test="copro-batch-modal-input"
        name={details?.key}
        id={details?.key}
        type={details?.inputType}
        value={detailValue}
        onChange={handleChange}
        maxLength={200}
        required
      />
    );
  };

  const renderInput = () => {
    return getDefaultInput(details?.value);
  };

  return (
    <div>
      <Modal size="sm" unmountOnClose centered isOpen={modal}>
        <ModalHeader
          className="border border-b"
          close={<Close className="custom-close" onClick={() => close()} />}
        >{`Edit ${details?.label?.toLowerCase()}`}</ModalHeader>
        <ModalBody className="p-0">
          <div className="px-4 pt-6">
            <Label className="text-gray-700 text-sm mb-2" for={details?.label}>
              {details?.label}
            </Label>
            {renderInput()}
          </div>
          <div className="flex flex-row w-full border border-t mt-32">
            <Button
              className="w-1/2 !text-gray-400"
              color=""
              onClick={() => close()}
            >
              {BUTTONS.CANCEL}
            </Button>
            <span>
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  backgroundColor: "#ededed",
                }}
              />
            </span>
            <Button
              className="w-1/2 disabled:border-0"
              color=""
              disabled={detailValue == null || detailValue.trim().length === 0}
              onClick={() => onSaveDetail(detailValue)}
            >
              {BUTTONS.SAVE}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

EditBatchModal.propTypes = {
  details: PropTypes.object,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
  refetch: PropTypes.func,
  onClose: PropTypes.func,
};

export default EditBatchModal;
