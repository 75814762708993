import * as XLSX from "xlsx";
import {Button} from "reactstrap";
import PropTypes from "prop-types";
import {DownloadDocumentGeneric24} from "@bphxd/ds-core-react/lib/icons";
import {BUTTONS} from "modules/co-processing/constants/coProcessing";

const excelHeaderMap = {
  // Batches
  batch_id: "Batch ID",
  batch_start_time: "#3DHDS Start time",
  batch_end_time: "#3DHDS end time",
  tank_certified_datez: "Tank certified date/time",
  c14_test_date: "C14 test date",
  operating_condition: "Operating condition",
  tank_number: "Tank number",
  tank_volume: "Tank volume at certification",
  rinPerGal: "RIN/gal",
  c14_pmc: "Percent modern carbon (pMC)",
  submitted: "Submitted",
  renewable_feed_bbl: "#3DHDS Feed - Renewable feed (bbl)",
  cold_recirc_in_renewable_feed_bbl:
    "#3DHDS Feed - Cold recirc in renewable feed (bbl)",
  renewable_feed_tank_col_su_sd_bbl:
    "#3DHDS Feed - Renewable feed tank vol adj to cold recirc in renewable feed for SU/SD (bbl)",
  total_feed_bbl: "#3DHDS Feed - Total feed (bbl)",
  unit_rundown_bbl: "#3DHDS Production - Unit rundown (bbl)",
  hot_diesel_recirc_bbl: "#3DHDS Production - Hot diesel recirc (bbl)",
  cold_recirc_bbl: "#3DHDS Production - Cold recirc (bbl)",
  renewable_feed_lb: "#3DHDS Feed - Renewable feed (lb)",
  cold_recirc_in_renewable_feed_lb:
    "#3DHDS Feed - Cold recirc in renewable feed (lb)",
  renewable_feed_tank_col_su_sd_lb:
    "#3DHDS Feed - Renewable feed tank vol adj to cold recirc in renewable feed for SU/SD (lb)",
  total_feed_lb: "#3DHDS Feed - Total feed (lb)",
  unit_rundown_lb: "#3DHDS Production - Unit rundown (lb)",
  hot_diesel_recirc_lb: "#3DHDS Production - Hot diesel recirc (lb)",
  cold_recirc_lb: "#3DHDS Production - Cold recirc (lb)",
  rundown_density: "#3DHDS - Rundown density (lb/kgal)",

  // Shipments fields
  status: "Status",
  shipment_date: "Shipment date",
  shipment_type: "Shipment type",
  shipment_id: "Shipment ID",
  actual_volume: "Total actual shipment volume",
  credits_qualified: "RINS qualified",
  load_start_time: "Load start time",
  load_end_time: "Load end time",
  renewable_shipment_flag: "Renewable",
  destination: "Destination",
  sub_type: "Sub type",
  notes: "Notes",
};

function transformKeysToHeaderLabels(item, keyMap) {
  const {__typename, ...fieldsToExport} = item ?? {};

  return Object.fromEntries(
    Object.entries(fieldsToExport).map(([k, v]) => [keyMap[k] || k, v]),
  );
}

export const exportExcelData = (historicalData) => {
  // Format data using the provided headerMapping
  const formattedData = historicalData.map((data) =>
    transformKeysToHeaderLabels(data, excelHeaderMap),
  );

  const ws = XLSX.utils.json_to_sheet(formattedData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, "Historical Data");
  XLSX.writeFile(wb, "historical_data_export.xlsx");
};

const ExportHistorical = ({historicalData}) => {
  return (
    <Button
      className="rounded-0 ml-auto mr-5"
      color="primary"
      data-test="historical-export-btn"
      disabled={!historicalData || historicalData?.length === 0}
      onClick={() => exportExcelData(historicalData)}
    >
      {BUTTONS.EXPORT}
      <DownloadDocumentGeneric24 className="btn-icon-suffix" />
    </Button>
  );
};

ExportHistorical.propTypes = {historicalData: PropTypes.array};

export default ExportHistorical;
