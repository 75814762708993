import {Fragment} from "react";
import PropTypes from "prop-types";
import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import {flexRender} from "@tanstack/react-table";
import SpinnerLoading from "modules/common/SpinnerLoading";

import Filter from "./Filter";
import Warning from "./Warning";
import Checkbox from "./Checkbox";
import TableCheckbox from "./TableCheckbox";

import "./filter.css";

export const getPinningStyle = (column) => {
  const isPinned = column.getIsPinned();
  return {
    left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    right:
      isPinned === "right" ? `${column.getStart("right") + 7}px` : undefined,
    position: isPinned ? "sticky" : "relative",
    zIndex: isPinned ? 1 : null,
  };
};

const createCheckboxColumn = (type, otherRows, otherData) => ({
  header: ({table}) => (
    <TableCheckbox
      table={table}
      type={type}
      otherRows={otherRows}
      otherData={otherData}
    />
  ),
  accessorKey: "checkbox",
  enableSorting: false,
  size: 25,
  cell: ({row, table}) => (
    <Checkbox
      row={row}
      table={table}
      type={type}
      otherRows={otherRows}
      otherData={otherData}
    />
  ),
});

const createConditionalCheckboxColumn = (type, otherRows, otherData) => ({
  ...createCheckboxColumn(type, otherRows, otherData),
  cell: ({row, table}) =>
    row.original.balance_group_name && row.original.location_group_name ? (
      <Checkbox
        row={row}
        table={table}
        type={type}
        otherRows={otherRows}
        otherData={otherData}
      />
    ) : (
      <Warning row={row} />
    ),
});

export const getColumns = (
  columns,
  enableRowSelection,
  type,
  otherRows,
  otherData,
) => {
  if (!enableRowSelection) {
    return columns;
  }

  const newColumn =
    type === "Incoming"
      ? createCheckboxColumn(type, otherRows, otherData)
      : createConditionalCheckboxColumn(type, otherRows, otherData);

  return type === "Incoming"
    ? [newColumn, ...columns]
    : [...columns, newColumn];
};

export const placeholderHeader = (header) => {
  return null;
};

const getSortOpacity = (isSorted, sortDirection) => {
  if (!isSorted || (isSorted && isSorted !== sortDirection)) {
    return "opacity-20";
  }
  return "";
};

const SortableHeader = ({
  header,
  showFilter,
  contractNumbers,
  dealNumbers,
  data,
  setRowSelection,
}) => {
  const {id, colSpan, column} = header;
  const {getToggleSortingHandler, getIsSorted, getCanFilter} = column;
  const isNumber = header.getContext().column.columnDef.dataType === "number";

  return (
    <th
      key={id}
      colSpan={colSpan}
      style={{
        cursor: "pointer",
        userSelect: "none",
        whiteSpace: "nowrap",
        backgroundColor: "#fafafa",
        borderBottomColor: "#cccccc",
        ...getPinningStyle(column),
      }}
    >
      <div
        className={`d-flex align-items-center ${
          isNumber && "justify-content-end"
        }`}
        style={{
          width: "168px",
          height: "30px",
        }}
        onClick={getToggleSortingHandler()}
        onKeyDown={() => {}}
      >
        <div>{flexRender(column.columnDef.header, header.getContext())}</div>
        <div className="position-relative ms-2">
          <div className="react-table-sort position-absolute top-50 translate-middle-y">
            <TriangleDown16
              className={`position-absolute bottom-0 ${getSortOpacity(
                getIsSorted(),
                "desc",
              )}`}
            />
            <TriangleUp16
              className={`position-absolute top-0 ${getSortOpacity(
                getIsSorted(),
                "asc",
              )}`}
            />
          </div>
        </div>
      </div>
      {getCanFilter() && showFilter ? (
        <div className="mt-[10px]">
          <Filter
            column={column}
            contractNumbers={contractNumbers}
            dealNumbers={dealNumbers}
            data={data}
            setRowSelection={setRowSelection}
          />
        </div>
      ) : null}
    </th>
  );
};

SortableHeader.propTypes = {
  header: PropTypes.object,
  showFilter: PropTypes.bool,
  contractNumbers: PropTypes.array,
  dealNumbers: PropTypes.array,
  data: PropTypes.array,
  setRowSelection: PropTypes.func,
};

export const sortableHeader = (
  header,
  showFilter,
  contractNumbers,
  dealNumbers,
  data,
  setRowSelection,
) => (
  <SortableHeader
    header={header}
    showFilter={showFilter}
    contractNumbers={contractNumbers}
    dealNumbers={dealNumbers}
    data={data}
    setRowSelection={setRowSelection}
  />
);

export const nonSortableHeader = (header) => {
  return (
    <th
      // align-top for row selection and row options columns so positioning is same as <td>
      className="align-top"
      key={header.id}
      colSpan={header.id === "checkbox" ? header.colSpan : "2"}
      style={{
        position: "sticky",
        left: "0px",
        zIndex: "10",
        backgroundColor:
          header.id === "1_Purchase_po_number" ||
          header.id === "1_Incoming document_certificate_number"
            ? "white"
            : "#fafafa",
        borderBottomColor:
          header.id === "1_Purchase_po_number" ||
          header.id === "1_Incoming document_certificate_number"
            ? "#ededed"
            : "#cccccc",
      }}
    >
      {flexRender(header.column.columnDef.header, header.getContext())}
    </th>
  );
};

export const renderTableHeaders = (
  table,
  showFilter,
  contractNumbers,
  dealNumbers,
  data,
  setRowSelection,
) => {
  return table.getHeaderGroups().map((headerGroup) => (
    <tr key={headerGroup.id}>
      {headerGroup.headers.map((header) => (
        <Fragment key={header.id}>
          {header.isPlaceholder
            ? placeholderHeader(header)
            : header.column.getCanSort()
            ? sortableHeader(
                header,
                showFilter,
                contractNumbers,
                dealNumbers,
                data,
                setRowSelection,
              )
            : nonSortableHeader(header)}
        </Fragment>
      ))}
    </tr>
  ));
};

const LoadingRow = () => (
  <tr className="h-[360px]">
    <td colSpan="100%">
      <div className="flex items-center justify-center ">
        <SpinnerLoading />
      </div>
    </td>
  </tr>
);

const DataRow = ({row, selectedRow, type}) => (
  <tr key={row.id} style={{height: "52px"}}>
    {row.getVisibleCells().map((cell) => {
      const isNumber = cell.getContext().column.columnDef.dataType === "number";
      const isSelected = selectedRow(cell);
      const isMissingData =
        type === "Purchase" &&
        (!cell.row.original.balance_group_name ||
          !cell.row.original.location_group_name);

      return (
        <td
          key={cell.id}
          className={isNumber ? "text-right" : ""}
          style={{
            backgroundColor: isSelected
              ? "#eef0f5"
              : isMissingData
              ? "#ffedf0"
              : undefined,
            whiteSpace: "nowrap",
            ...getPinningStyle(cell.column),
          }}
        >
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </td>
      );
    })}
  </tr>
);

DataRow.propTypes = {
  row: PropTypes.object,
  selectedRow: PropTypes.func,
  type: PropTypes.string,
};

export const renderTableBody = (
  table,
  loading,
  totalData,
  selectedRow,
  type,
) =>
  loading ? (
    <LoadingRow />
  ) : (
    totalData > 0 &&
    table
      .getRowModel()
      .rows.map((row) => (
        <DataRow row={row} selectedRow={selectedRow} type={type} />
      ))
  );

const EmptyRow = ({totalData}) => (
  <tr style={{height: `${(7 - totalData) * 52}px`}}></tr>
);

EmptyRow.propTypes = {
  totalData: PropTypes.number,
};

const NoMatchFilterRow = ({type, setColumnFilter}) => (
  <tr className="h-96">
    <td colSpan="100%">
      <div className="pt-7 pl-5 text-center">
        {`Sorry, no ${
          type === "Incoming" ? "incoming documents" : "purchases"
        } match your filter. `}
        <br />
        <span
          className="underline cursor-pointer leading-10"
          onClick={() => setColumnFilter([])}
          onKeyDown={() => {}}
        >
          Clear the filters
        </span>
        <span>
          {" "}
          to view all the {type === "Incoming" ? "documents" : "purchases"}.
        </span>
      </div>
    </td>
  </tr>
);

NoMatchFilterRow.propTypes = {
  type: PropTypes.string,
  setColumnFilter: PropTypes.func,
};

const AllActualizedRow = ({type}) => (
  <tr className="h-96">
    <td colSpan="100%">
      <div className="flex items-center justify-center mt-10 ml-10 mr-10 text-center">
        {type === "Incoming" ? (
          "It appears that you have actualized all available incoming documents"
        ) : (
          <span>
            {"It appears that you've linked all purchases. "}
            <span className="underline cursor-pointer">
              Contact BioVerse
            </span>{" "}
            if your purchase transactions are not available in the system.
          </span>
        )}
      </div>
    </td>
  </tr>
);

AllActualizedRow.propTypes = {
  type: PropTypes.string,
};

export const renderEmptyStates = (
  loading,
  totalData,
  type,
  isIncomingFilter,
  isPurchaseFilter,
  setColumnFilter,
  otherData,
) => (
  <>
    {!loading && totalData !== 0 && totalData < 7 && (
      <EmptyRow totalData={totalData} />
    )}
    {!loading &&
      totalData === 0 &&
      ((type === "Incoming" && isIncomingFilter) ||
        (type === "Purchase" && isPurchaseFilter)) && (
        <NoMatchFilterRow type={type} setColumnFilter={setColumnFilter} />
      )}
    {!loading &&
      totalData === 0 &&
      ((type === "Incoming" && !isIncomingFilter) ||
        (type === "Purchase" && !isPurchaseFilter)) &&
      otherData.length !== 0 && <AllActualizedRow type={type} />}
  </>
);
