import {gql} from "@apollo/client";

export const ALL_INCOMING_DOCUMENTS = gql`
  query getIncomingDocuments($divisionId: String, $siteReferenceId: String) {
    bioLcGetIncomingDocsApi(
      event: {divisionId: $divisionId, siteReferenceId: $siteReferenceId}
    ) {
      documents {
        certificateInboundEuId
        sdNumber
        supplierName
        recipientReceiptAddress
        productType
        rawMaterialType
        feedstockQty
        balance
        state
        ghgTotal
        countryCode
        certificationSystem
        supplierDispatchAddress
        feedstockIssueDatez
        bvAuditCreatedDatez
        ghgEmissionPercentageIn
        versionCount
        versionHistoryData {
          linkedCert
          sdNumber
          bvAuditChangedDatez
        }
      }
      error
      statusCode
    }
    bioLcGetDivisionData(event: {}) {
      divisionData {
        divisionCode
        divisionId
        divisionName
      }
      error
      statusCode
    }
  }
`;

export default {ALL_INCOMING_DOCUMENTS};
