import moment from "moment";

const BooleanMap = {true: "Yes", false: "No"};
const DATE_FORMAT = "DD/MM/YYYY hh:mm A";

const handleNullOrUndefined = (value, formatterFn = (v) => v) =>
  value == null || value === undefined ? "N/A" : formatterFn(value);

const formatDate = (value) =>
  moment(value, DATE_FORMAT, true).isValid()
    ? value
    : moment(value).format(DATE_FORMAT);

const booleanToYesOrNo = (value) => BooleanMap[value.toLowerCase()] || value;

const formatNumber = (value) =>
  typeof value === "number"
    ? new Intl.NumberFormat("en-US").format(Math.round(value))
    : value;

const format = {
  bool: (value) => handleNullOrUndefined(value, booleanToYesOrNo),
  date: (value) => handleNullOrUndefined(value, formatDate),
  number: (value) => handleNullOrUndefined(value, formatNumber),
  default: handleNullOrUndefined,
};

export {format, formatDate, handleNullOrUndefined};
