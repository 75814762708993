import React, {Fragment, useCallback, useMemo, useState} from "react";
import PropTypes from "prop-types";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
  flexRender,
} from "@tanstack/react-table";
import {Table} from "reactstrap";
import {TriangleDown16, TriangleUp16} from "@bphxd/ds-core-react/lib/icons";
import SpinnerLoading from "modules/common/SpinnerLoading";

import "./index.scss";
import {
  checkFilter,
  customDateFilterFn,
  customDateFilterFnOutgoing,
} from "modules/GlobalMassBalance/utils";
import {PINNED_COLUMNS_LIST} from "modules/GlobalMassBalance/constants";
import Filter from "../Filter/Filter";

const getSortOpacity = (isSorted, sortDirection) => {
  if (!isSorted || (isSorted && isSorted !== sortDirection)) {
    return "opacity-20";
  }
  return "";
};

const DocumentTable = ({
  data,
  columns,
  loading,
  type,
  showFilter,
  columnFilter,
  setColumnFilter,
}) => {
  const [selectedRow, setSelectedRow] = useState("");

  const table = useReactTable({
    data,
    columns,
    filterFns: {
      dateRangeFilterFn: customDateFilterFn,
    },
    state: {
      columnFilters: columnFilter,
      columnPinning: {
        right: PINNED_COLUMNS_LIST,
      },
    },
    onColumnFiltersChange: setColumnFilter,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  const outgoingIndex = type === "cpp" ? 2 : 1;

  const INCOMING_COLUMN_IDS = useMemo(() => {
    return columns[0].columns.map((col) => col.accessorKey);
  }, [columns]);

  const OUTGOING_COLUMN_IDS = useMemo(() => {
    return columns[outgoingIndex].columns.map((col) => col.accessorKey);
  }, [columns, outgoingIndex]);

  const OUTGOING_COLUMN_FILTER_IDS = useMemo(() => {
    return columns[outgoingIndex].columns
      .filter((col) => col.filterFn === "massBalanceCustomFilterFunction")
      .map((col) => col.accessorKey);
  }, [columns, outgoingIndex]);

  const OUTGOING_COLUMN_DATE_FILTER_IDS = useMemo(() => {
    return columns[outgoingIndex].columns
      .filter((col) => col.filterFn === "dateRangeFilterFnOutgoing")
      .map((col) => col.accessorKey);
  }, [columns, outgoingIndex]);

  const CO_PROCCESSED_COLUMN_IDS = useMemo(() => {
    return columns.length > 2
      ? columns[1].columns.map((col) => col.accessorKey)
      : [];
  }, [columns]);

  const isOutgoingFilter = useMemo(() => {
    return columnFilter.some((obj) => OUTGOING_COLUMN_IDS.includes(obj.id));
  }, [columnFilter, OUTGOING_COLUMN_IDS]);

  const totalData = table.getPrePaginationRowModel().rows.length;

  const isDataAvailable = checkFilter(
    data,
    columnFilter,
    isOutgoingFilter,
    OUTGOING_COLUMN_FILTER_IDS,
    OUTGOING_COLUMN_DATE_FILTER_IDS,
  );

  const statusRightPosition =
    totalData > 0 && isDataAvailable ? "55px" : "31px";

  const buildRows = useCallback(
    (row, flexRender) => {
      let outboundRecords = row.original?.outboundRecords ?? [{}];
      if (isOutgoingFilter) {
        columnFilter.forEach((filter) => {
          const key = filter?.id;
          const value = filter?.value;
          if (OUTGOING_COLUMN_FILTER_IDS.includes(key)) {
            outboundRecords = outboundRecords.filter((record) =>
              record[key]
                ?.toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
            );
          }
          if (OUTGOING_COLUMN_DATE_FILTER_IDS.includes(key)) {
            outboundRecords = outboundRecords.filter((record) =>
              customDateFilterFnOutgoing(record[key], key, value),
            );
          }
        });
      }

      if (outboundRecords.length === 0) {
        return null;
      }

      return (
        <tr key={row.id}>
          {row.getVisibleCells().map((cell) => {
            if (INCOMING_COLUMN_IDS.includes(cell.column.id)) {
              return (
                <td
                  key={cell.id}
                  className={`${cell.index > 0 ? "d-none" : ""} ${
                    cell.getContext().column.columnDef.dataType === "number" &&
                    "text-right"
                  }`}
                  style={{
                    verticalAlign:
                      outboundRecords.length > 1 ? "top" : "middle",
                    padding: "10px 15px",
                    minWidth: cell.column.id === "actions" ? "auto" : "150px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            }
            if (OUTGOING_COLUMN_IDS.includes(cell.column.id)) {
              const {row, ...context} = cell.getContext();
              return (
                <td
                  key={cell.id}
                  style={{
                    verticalAlign: "middle",
                    minWidth: cell.column.id === "actions" ? "auto" : "150px",
                    position: cell.column.getIsPinned() ? "sticky" : "relative",
                    top: cell.column.getIsPinned() ? "0" : "auto",
                    right: cell.column.getIsPinned()
                      ? cell.column.id === "status"
                        ? statusRightPosition
                        : "0px"
                      : "auto",
                  }}
                  className="width-td"
                >
                  {outboundRecords.map((record, index) => (
                    <tr
                      key={index}
                      className={`fixed-height-tr ${
                        selectedRow === row.id + index &&
                        outboundRecords.length > 1
                          ? "!border-b !border-b-[#bbb]"
                          : ""
                      }`}
                      style={{
                        borderBottom:
                          index < outboundRecords.length - 1
                            ? "1px solid #eee"
                            : "",
                        padding: "0px 15px",
                        display: "flex",
                        justifyContent:
                          cell.getContext().column.columnDef.dataType ===
                          "number"
                            ? "flex-end"
                            : "flex-start",
                        maxHeight: "200px",
                        borderLeft:
                          OUTGOING_COLUMN_IDS[0] === cell.column.id
                            ? "1px solid #eee"
                            : "",
                      }}
                      onMouseEnter={() => setSelectedRow(row.id + index)}
                      onMouseLeave={() => setSelectedRow("")}
                    >
                      <td
                        style={{
                          alignItems: "center",
                          verticalAlign: "middle",
                          padding: "15px 0px",
                          whiteSpace: "nowrap",
                          zIndex: "auto",
                          display:
                            cell.column.id === "actions" ? "flex" : "block",
                        }}
                        className="fixed-height-tr"
                      >
                        {flexRender(cell.column.columnDef.cell, {
                          ...context,
                          row: {
                            ...row,
                            original: {
                              ...row.original,
                              outboundRecords: record,
                            },
                            highlight: () => {
                              setSelectedRow(cell.id);
                            },
                          },
                        })}
                      </td>
                    </tr>
                  ))}
                </td>
              );
            }
            if (CO_PROCCESSED_COLUMN_IDS.includes(cell.column.id)) {
              return (
                <td
                  key={cell.id}
                  className={`${cell.index > 0 ? "d-none" : ""} ${
                    cell.getContext().column.columnDef.dataType === "number" &&
                    "text-right"
                  }`}
                  style={{
                    verticalAlign: "middle",
                    padding: "10px 15px",
                    borderLeft:
                      CO_PROCCESSED_COLUMN_IDS[0] === cell.column.id
                        ? "1px solid #eee"
                        : "",
                    whiteSpace: "nowrap",
                  }}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              );
            }
            return null;
          })}
        </tr>
      );
    },
    [
      CO_PROCCESSED_COLUMN_IDS,
      INCOMING_COLUMN_IDS,
      OUTGOING_COLUMN_DATE_FILTER_IDS,
      OUTGOING_COLUMN_FILTER_IDS,
      OUTGOING_COLUMN_IDS,
      columnFilter,
      isOutgoingFilter,
      selectedRow,
      statusRightPosition,
    ],
  );

  return (
    <Table hover className="border-b border-b-[#eee] !mb-0">
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Fragment key={header.id}>
                {header.column.getCanSort() ? (
                  <th
                    className="sort-header"
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      borderLeft:
                        OUTGOING_COLUMN_IDS[0] === header.id ||
                        CO_PROCCESSED_COLUMN_IDS[0] === header.id
                          ? "1px solid #eee"
                          : "",
                      minWidth:
                        header.column.id === "actions" ? "auto" : "150px",
                      position: header.column.getIsPinned()
                        ? "sticky"
                        : "relative",
                      top: header.column.getIsPinned() ? "0px" : "auto",
                      right: header.column.getIsPinned()
                        ? header.column.id === "status"
                          ? statusRightPosition
                          : "0px"
                        : "auto",
                    }}
                  >
                    {OUTGOING_COLUMN_IDS.includes(header.column.id) ? (
                      <div
                        className={`d-flex align-items-center ${
                          header.getContext().column.columnDef.dataType ===
                            "number" && "justify-content-end"
                        }`}
                      >
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        className={`d-flex align-items-center ${
                          header.getContext().column.columnDef.dataType ===
                            "number" && "justify-content-end"
                        }`}
                        onClick={header.column.getToggleSortingHandler()}
                        onKeyDown={() => {}}
                      >
                        <div>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext(),
                          )}
                        </div>
                        <div className="position-relative ms-2">
                          <div className="react-table-sort position-absolute top-50 translate-middle-y">
                            <TriangleDown16
                              className={`position-absolute bottom-0 ${getSortOpacity(
                                header.column.getIsSorted(),
                                "desc",
                              )}`}
                            />
                            <TriangleUp16
                              className={`position-absolute top-0 ${getSortOpacity(
                                header.column.getIsSorted(),
                                "asc",
                              )}`}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {header.column.getCanFilter() && showFilter ? (
                      <div className="mt-[10px]">
                        <Filter column={header.column} data={data} />
                      </div>
                    ) : null}
                  </th>
                ) : (
                  <th
                    className={`top-header ${
                      header.column.columnDef.key === "actions"
                        ? "global-actions"
                        : ""
                    }`}
                    key={header.id}
                    colSpan={header.colSpan}
                    style={{
                      borderLeft:
                        columns[0].header !== header.id ? "1px solid #eee" : "",
                      position:
                        header.column.getIsPinned() &&
                        PINNED_COLUMNS_LIST.includes(header.column.id)
                          ? "sticky"
                          : "relative",
                      top:
                        header.column.getIsPinned() &&
                        PINNED_COLUMNS_LIST.includes(header.column.id)
                          ? "0px"
                          : "auto",
                      right:
                        header.column.getIsPinned() &&
                        PINNED_COLUMNS_LIST.includes(header.column.id)
                          ? header.column.id === "status"
                            ? statusRightPosition
                            : "0px"
                          : "auto",
                    }}
                  >
                    <span className="top-header-text">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                    </span>
                  </th>
                )}
              </Fragment>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {loading && (
          <tr className="h-[360px]">
            <td colSpan="100%">
              <div className="flex items-center justify-center ">
                <SpinnerLoading />
              </div>
            </td>
          </tr>
        )}
        {!loading &&
          table
            .getRowModel()
            .rows.map((row) => (
              <Fragment key={row.id}>{buildRows(row, flexRender)}</Fragment>
            ))}
        {(totalData === 0 || !isDataAvailable) && (
          <tr className="h-[calc(100vh-742px)]">
            <td
              colSpan="100%"
              className="text-[16px] !text-[#111111] text-center !pt-[110px]"
            >
              {columnFilter.length > 0 ? (
                <span>
                  No results found matching keyword Please try a different
                  keyword
                </span>
              ) : (
                <span>
                  There are currently no incoming or outgoing <br />{" "}
                  transactions recorded for this mass balance period.
                </span>
              )}
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

DocumentTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  columns: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  type: PropTypes.string,
  showFilter: PropTypes.bool,
  columnFilter: PropTypes.array,
  setColumnFilter: PropTypes.func,
};

export default DocumentTable;
