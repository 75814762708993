import React, {useCallback, useEffect, useMemo, useState} from "react";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {useForm, FormProvider} from "react-hook-form";
import {startCase} from "lodash";
import {useAccount, useMsal} from "@azure/msal-react";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import {DIV_CODE_GF} from "constants/divisionDetails";
import {
  Form,
  FormGroup,
  Label,
  FormFeedback,
  Input,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Spinner,
  Button,
  Modal,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import moment from "moment";
import {
  Alert32,
  Check24,
  Remove24,
  TailLeft24,
} from "@bphxd/ds-core-react/lib/icons";
import {
  DOCUMENT_DETAIL_VIEW,
  docManagerUpdate,
} from "graphql/docManager/IncomingDocs";
import {toast} from "react-toastify";
import SingleDocumentViewer from "../components/DetailedView/SingleDocumentViewer";

import Layout from "../components/DetailedView/Layout";
import Subtitle from "../components/DetailedView/Subtitle";

import SupplierAndRecipientInfo from "../components/DetailedView/SupplierAndRecipientInfo";
import GeneralInformation from "../components/DetailedView/GeneralInformation";
import AdditionalInfo from "../components/DetailedView/AdditionalInfo";

import GHGEmissionInfo from "../components/DetailedView/GHGEmissionInfo";
import ScopeOfCertification from "../components/DetailedView/ScopeOfCertification";

import Datepicker from "../components/DetailedView/Datepicker/index";

import {DDV, TDV} from "../components/DetailedView/utils";

export const defaultValues = {
  sdNumber: "",
  // state: "",
  dateOfIssuance: "",
  supplierName: "",
  supplierAddress: "",
  certificationSystem: "",
  certificateNumber: "",
  recipientName: "",
  recipientAddress: "",
  contractNumber: "",
  supplierDispatchAddress: "",
  recipientReceiptAddress: "",
  materialDispatchDatez: "",
  producerInstallationDate: "",
  productType: "",
  rawMaterialType: "",
  additionalInfo: "",
  additionalNotes: "",
  rawMaterialOriginCountryName: "",
  feedstockQty: "",
  feedstockQtyUom: "",
  feedstockIssueDatez: "",
  productEnergyContent: "",
  euRedCompliantFlag: null,
  isccCompliantFlag: null,
  chainOfCustodyOption: "",
  materialSustainabilityCriteriaFlag: null,
  agriculturalBiomassIntermediateCropFlag: null,
  agriculturalBiomassLowRiskFlag: null,
  rawMaterialWasteOrResidueFlag: "",
  defaultValueAppliedFlag: null,
  recipientReceiptAddressSameFlag: null,
  supplierDispatchAddressSameFlag: null,

  ghgEec: "",
  ghgEl: "",
  ghgEp: "",
  ghgEtd: "",
  ghgEu: "",
  ghgEsca: "",
  ghgEccs: "",
  ghgEccr: "",
  ghgTotal: "",
};

const tabFields = {
  supplier: [
    "supplierName",
    // "state",
    "supplierAddress",
    "certificationSystem",
    "certificateNumber",
    "recipientName",
    "recipientAddress",
    "contractNumber",
    "supplierDispatchAddress",
    "materialDispatchDatez",

    "recipientReceiptAddress",
  ],
  general: [
    "productType",
    "rawMaterialType",
    "rawMaterialOriginCountryName",
    "feedstockQty",
    "feedstockQtyUom",
    "feedstockIssueDatez",
    "productEnergyContent",
    "euRedCompliantFlag",
    "isccCompliantFlag",
    "chainOfCustodyOption",
  ],
  emission: [
    "defaultValueAppliedFlag",
    "ghgEec",
    "ghgEl",
    "ghgEp",
    "ghgEtd",
    "ghgEu",
    "ghgEsca",
    "ghgEccs",
    "ghgEccr",
    "ghgTotal",
    "rawMaterialWasteOrResidueFlag",
    "producerInstallationDate",
  ],
  certification: [
    "materialSustainabilityCriteriaFlag",
    "agriculturalBiomassIntermediateCropFlag",
    "agriculturalBiomassLowRiskFlag",
    // "rawMaterialWasteOrResidueFlag",
  ],
  additionalInfo: [
    "mbBalanceGroupId",
    "mbLocationGroupId",
    "additionalNotes",
    "replacePreviousVersion",
    "previousVersionId",
  ],
};

const rejectReasons = [
  {
    key: "info_missing",
    value: "Required information missing or incorrect",
    message:
      "This certificate will be kept and deactivated. A new certificate needs to be reissued",
  },
  {
    key: "out_of_date",
    value: "Out of date certificate",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
  {key: "invalid", value: "Invalid file", message: "This file will be deleted"},
  {
    key: "other",
    value: "Other",
    message:
      "This certificate will be kept and deactivated. A new certificate has already been reissued",
  },
];

const DetailedDocumentViewPage = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;

  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_GF);
  const siteReferenceId = siteReferenceData?.siteReferenceId;
  const divisionId = divisionData?.divisionId;

  const [open, setOpen] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [submitAction, setSubmitAction] = useState("");
  const [rejecting, setRejecting] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);
  const [openTabsOnce, setOpenTabsOnce] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const navigate = useNavigate();

  const {docId, country} = useParams();
  const [searchParams] = useSearchParams();

  const pageNo = searchParams.get("pageNo") ?? 0;

  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);

  const {data, loading, refetch} = useQuery(DOCUMENT_DETAIL_VIEW, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      divisionId,
      certificateInboundEuId: docId,
      siteReferenceId,
    },
  });

  const document = data?.bioLcGetIncomingDocDetailsApi?.documentDetails;

  const isAfter5Oct2015 = (date) => {
    if (date) {
      return moment(date, "YYYY-MM-DD").isAfter("2015-10-05");
    }
    return "";
  };

  const values = useMemo(() => {
    if (data && document != null) {
      const {
        sdNumber = "",
        dateOfIssuance = "",
        supplierName = "",
        // state = "",
        supplierAddress = "",
        certificationSystem = "",
        certificateNumber = "",
        recipientName = "",
        recipientAddress = "",
        contractNumber = "",
        supplierDispatchAddress = "",
        recipientReceiptAddress = "",
        materialDispatchDatez = "",
        producerInstallationDate = "",
        productType = "",
        rawMaterialType = "",
        additionalInfo = "",
        additionalNotes = "",
        rawMaterialOriginCountryName = "",
        feedstockQty = "",
        feedstockQtyUom = "",
        // feedstockIssueDatez = "",
        productEnergyContent = "",
        euRedCompliantFlag = null,
        isccCompliantFlag = null,
        chainOfCustodyOption = "",
        materialSustainabilityCriteriaFlag = null,
        agriculturalBiomassIntermediateCropFlag = null,
        agriculturalBiomassLowRiskFlag = null,
        rawMaterialWasteOrResidueFlag = "",
        defaultValueAppliedFlag = "",
        recipientReceiptAddressSameFlag = null,
        supplierDispatchAddressSameFlag = null,
        ghgEec = 0,
        ghgEl = 0,
        ghgEp = 0,
        ghgEtd = 0,
        ghgEu = 0,
        ghgEsca = 0,
        ghgEccs = 0,
        ghgEccr = 0,
        ghgTotal = 0,
        bvAuditCreatedDatez = "",
        bvAuditChangedDatez = "",
      } = document;
      const props = {
        sdNumber,
        dateOfIssuance,
        supplierName,
        // state,
        supplierAddress,
        certificationSystem,
        certificateNumber,
        recipientName,
        recipientAddress,
        contractNumber,
        supplierDispatchAddress,
        recipientReceiptAddress,
        materialDispatchDatez,
        producerInstallationDate: isAfter5Oct2015(producerInstallationDate),
        productType,
        rawMaterialType,
        additionalInfo,
        additionalNotes,
        rawMaterialOriginCountryName,
        feedstockQty,
        feedstockQtyUom,
        // feedstockIssueDatez,
        productEnergyContent,
        euRedCompliantFlag,
        isccCompliantFlag,
        chainOfCustodyOption,
        materialSustainabilityCriteriaFlag,
        agriculturalBiomassIntermediateCropFlag,
        agriculturalBiomassLowRiskFlag,
        rawMaterialWasteOrResidueFlag,
        defaultValueAppliedFlag,
        ghgEec: parseFloat(ghgEec ?? "0"),
        ghgEl: parseFloat(ghgEl ?? "0"),
        ghgEp: parseFloat(ghgEp ?? "0"),
        ghgEtd: parseFloat(ghgEtd ?? "0"),
        ghgEu: parseFloat(ghgEu ?? "0"),
        ghgEsca: parseFloat(ghgEsca ?? "0"),
        ghgEccs: parseFloat(ghgEccs ?? "0"),
        ghgEccr: parseFloat(ghgEccr ?? "0"),
        ghgTotal:
          ghgTotal === TDV || ghgTotal === DDV
            ? ghgTotal
            : parseFloat(ghgTotal ?? "0"),
        bvAuditCreatedDatez,
        bvAuditChangedDatez,
        recipientReceiptAddressSameFlag,
        supplierDispatchAddressSameFlag,
      };
      return props;
    }
    return defaultValues;
  }, [data, document]);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    values,
    shouldFocusError: true,
  });
  const {
    register,
    control,
    formState: {errors},
    getValues,
    resetField,
    watch,
    trigger,
    setFocus,
    setError,
    clearErrors,
  } = methods;

  const hasErrors = Object.keys(errors).length > 0;

  const ghgEec_defaultValueAppliedFlag = watch("defaultValueAppliedFlag");

  const ghgEec_watch = watch("ghgEec");
  const ghgEl_watch = watch("ghgEl");
  const ghgEp_watch = watch("ghgEp");
  const ghgEtd_watch = watch("ghgEtd");
  const ghgEu_watch = watch("ghgEu");
  const ghgEsca_watch = watch("ghgEsca");
  const ghgEccs_watch = watch("ghgEccs");
  const ghgEccr_watch = watch("ghgEccr");
  const ghgTotal_watch = watch("ghgTotal");

  const status = document?.state;

  const isDisabled =
    status !== undefined &&
    (status.toLowerCase() === "completed" ||
      status.toLowerCase() === "rejected");

  useEffect(() => {
    if (
      !isDisabled &&
      ghgEec_defaultValueAppliedFlag !== true &&
      parseFloat(ghgEec_watch ?? "0") === 0 &&
      parseFloat(ghgEl_watch ?? "0") === 0 &&
      parseFloat(ghgEp_watch ?? "0") === 0 &&
      parseFloat(ghgEtd_watch ?? "0") === 0 &&
      parseFloat(ghgEu_watch ?? "0") === 0 &&
      parseFloat(ghgEsca_watch ?? "0") === 0 &&
      parseFloat(ghgEccs_watch ?? "0") === 0 &&
      parseFloat(ghgEccr_watch ?? "0") === 0 &&
      (ghgTotal_watch !== TDV || ghgTotal_watch !== DDV || ghgEtd_watch !== DDV)
    ) {
      setError("ghg_no_value", {
        type: "value",
        message: "At least one GHG emission must be greater than 0",
      });
    } else {
      clearErrors("ghg_no_value");
    }
  }, [
    clearErrors,
    ghgEccr_watch,
    ghgEccs_watch,
    ghgEec_watch,
    ghgEec_defaultValueAppliedFlag,
    ghgEl_watch,
    ghgEp_watch,
    ghgEsca_watch,
    ghgEtd_watch,
    ghgEu_watch,
    ghgTotal_watch,
    setError,
    watch,
    isDisabled,
  ]);

  useEffect(() => {
    if (rejecting) {
      setFocus("rejectionReason");
    }
    if (rejecting && !watch("rejectionReason")) {
      trigger("rejectionReason");
    }
  }, [rejecting, setFocus, trigger, watch]);

  const [invalidTabs, setInvalidTabs] = useState({
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  });

  const handleRejectMessage = (message) => {
    if (message === "") {
      trigger("rejectionReason");
      setRejectMessage("");
    } else {
      setRejectMessage(
        rejectReasons.filter((r) => r.key === message)[0].message,
      );
      clearErrors("rejectionReason");
    }
  };

  const errorCount = Object.keys(errors).length;

  const validate = useCallback(async () => {
    const valid = await trigger();
    const flags = {
      supplier: false,
      general: false,
      emission: false,
      certification: false,
      additionalInfo: false,
    };
    const tabsRequireAttention = [];
    Object.keys(tabFields).forEach((tab) => {
      const tabErrors = tabFields[tab].filter((field) => errors[field] != null);
      if (tabErrors.length > 0) {
        flags[tab] = true;
        tabsRequireAttention.push(tab);
      }
    });
    setInvalidTabs(flags);
    if (tabsRequireAttention.length > 0 && !openTabsOnce) {
      setOpen([...tabsRequireAttention]);
      setOpenTabsOnce(true);
    }
    if (rejectMessage !== "") {
      clearErrors("rejectionReason");
    }
    return valid;
  }, [trigger, openTabsOnce, rejectMessage, errors, clearErrors]);

  useEffect(() => {
    // Make sure we trigger form validation after values change (e.g. after loading) to show errors
    // aka simulating next tick here
    const timeoutId = setTimeout(() => validate(), 10);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [values, validate, errorCount]);

  const openAccordion = useCallback(
    (id) => {
      if (!open.includes(id)) {
        setOpen([...open, id]);
      }
    },
    [open],
  );
  const handleErrorMessageClick = (errorValue) => {
    const errValue = errorValue === "ghg_no_value" ? "ghgEec" : errorValue;
    let match = null;
    const tabs = Object.keys(tabFields);
    for (let index = 0; index < tabs.length; index += 1) {
      const element = tabs[index];
      if (tabFields[element].includes(errValue)) {
        match = element;
        break;
      }
    }
    openAccordion(match);
    setTimeout(() => setFocus(errValue), 150);
  };

  const documentFile = data?.bioLcGetIncomingDocFileApi;

  const renderTitle = (docStatus) => {
    switch (docStatus) {
      case "requires_attention":
        return "Missing expected information";
      case "completed":
        return "Completed";
      case "ready_to_assign":
        return "Ready to assign";
      case "ready_to_accept":
        return "Ready to accept";
      case "reject":
        return "Rejected";

      default:
        return "Missing expected information";
    }
  };

  useEffect(() => {
    setTimeout(() => validate(), 10);
  }, [values, validate]);

  if (loading) {
    return (
      <div className="flex flex-col min-h-full items-center py-13 bp-core bg-light-gray-100">
        <Spinner />
      </div>
    );
  }

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },

    {
      text: "Document manager",
      link: {
        pathname: `/doc-manager/${country}/`,
        search: `?pageNo=${searchParams.get("pageNo") ?? 0}&docId=${docId}`,
      },
    },
    {
      text: document?.sdNumber,
      link: {
        pathname: `/document-manager/incoming/${country}/detailedView/${docId}`,
      },
    },
  ];

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const onSubmit = async (userAction) => {
    setSubmitLoading(true);
    setSubmitAction(userAction);
    const {...docData} = getValues();
    console.log(docData);
    const variables =
      userAction === "accept"
        ? {
            event: {
              divisionId,
              siteReferenceId,
              userAction,
              userId: account.username,
              rejectionReason: "",
              certificateInboundEuId: docId,
              replacePreviousVersion: false,
              previousVersionId: "",
              additionalNotes: docData.additionalNotes,
              documentData: {
                sdNumber: document?.sdNumber,
                sdNumberNew: docData.sdNumber,
                sdNumberOld: document?.sdNumber,
                dateOfIssuance: moment(
                  docData.dateOfIssuance,
                  "DD/MM/YYYY",
                ).format("YYYY-MM-DD HH:mm:ss.0 ZZ"),
                supplierName: docData.supplierName,
                // state: docData.state,
                supplierAddress: docData.supplierAddress,
                certificationSystem: docData.certificationSystem,
                certificateNumber: docData.certificateNumber,
                recipientName: docData.recipientName,
                recipientAddress: docData.recipientAddress,
                contractNumber: docData.contractNumber,
                supplierDispatchAddress: docData.supplierDispatchAddress,
                recipientReceiptAddress: docData.recipientReceiptAddress,
                materialDispatchDatez: moment(
                  docData.materialDispatchDatez,
                  "DD/MM/YYYY",
                ).format("YYYY-MM-DD HH:mm:ss.0 ZZ"),
                bioProductProducer: true,
                bioProductUser: true,
                productType: docData.productType,
                rawMaterialType: docData.rawMaterialType,
                additionalInfo: docData.additionalInfo,
                rawMaterialOriginCountryName:
                  docData.rawMaterialOriginCountryName,
                feedstockQty: docData.feedstockQty,
                feedstockQtyUom: docData.feedstockQtyUom,
                productQty: docData.productQty,
                productQtyUom: docData.productQtyUom,
                euRedCompliantFlag: docData.euRedCompliantFlag,
                isccCompliantFlag: docData.isccCompliantFlag,
                chainOfCustodyOption: docData.chainOfCustodyOption,
                materialSustainabilityCriteriaFlag:
                  docData.materialSustainabilityCriteriaFlag,
                agriculturalBiomassIntermediateCropFlag:
                  docData.agriculturalBiomassIntermediateCropFlag,
                agriculturalBiomassLowRiskFlag:
                  docData.agriculturalBiomassLowRiskFlag,
                rawMaterialWasteOrResidueFlag:
                  docData.rawMaterialWasteOrResidueFlag,
                defaultValueAppliedFlag: docData.defaultValueAppliedFlag,
                ghgEccr: docData.ghgEccr,
                ghgEccs: docData.ghgEccs,
                ghgEec: docData.ghgEec,
                ghgEl: docData.ghgEl,
                ghgEmissionPercentageIn: docData.ghgEmissionPercentageIn,
                ghgEp: docData.ghgEp,
                ghgEsca: docData.ghgEsca,
                ghgEtd: docData.ghgEtd,
                ghgEu: docData.ghgEu,
                ghgTotal: docData.ghgTotal,
                // allocatedElectricity: "",
                // allocatedHeat: "",
                productEnergyContent: docData.productEnergyContent,
                producerInstallationDate: docData.producerInstallationDate,
                // userInstallationDate: "",
                nuts2Region: "",
                mbBalanceGroupId: docData.mbBalanceGroupId,
                mbLocationGroupId: docData.mbLocationGroupId,
                replacePreviousVersion: docData.replacePreviousVersion,
                previousVersionId: docData.previousVersionId,
                recipientReceiptAddressSameFlag:
                  docData.recipientReceiptAddressSameFlag,
                supplierDispatchAddressSameFlag:
                  docData.supplierDispatchAddressSameFlag,
              },
            },
          }
        : {
            event: {
              divisionId,
              siteReferenceId,
              userAction,
              userId: account.username,
              certificateInboundEuId: docId,
              // previousVersionSdNumber: null,
              rejectionReason: docData.rejectionReason,
              replacePreviousVersion: false,
              additionalNotes: docData.rejectionReasonNotes,
            },
          };

    const {data: sendupdateData} = await docManagerUpdate(variables);
    if (
      sendupdateData?.bioLcUpdateIncomingDocDataApi?.errors &&
      sendupdateData?.bioLcUpdateIncomingDocDataApi?.errors?.length > 0
    ) {
      sendupdateData?.bioLcUpdateIncomingDocDataApi.errors.forEach(
        (element) => {
          resetField(element.field);
          setError(element.field, {
            type: "manual",
            message: element.message,
          });
        },
      );
      handleErrorMessageClick(
        sendupdateData?.bioLcUpdateIncomingDocDataApi?.errors[0].field,
      );
      toast.error("Failed to update document");
    } else if (sendupdateData?.bioLcUpdateIncomingDocDataApi?.error) {
      toast.error(sendupdateData?.bioLcUpdateIncomingDocDataApi?.error);
    } else {
      if (userAction === "accept") {
        toast.success("Document updated successfully");
      } else {
        toast.success("Document has been rejected successfully");
      }
      navigate({
        pathname: `/doc-manager/${country}/`,
        search: `?pageNo=${searchParams.get("pageNo") ?? 0}&docId=${docId}`,
      });
    }
    setAcceptModal(false);
    setSubmitLoading(false);
    setRejecting(false);
  };

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 ">
      <Modal size="sm" isOpen={acceptModal} className="modal-dialog-centered">
        <ModalBody className="text-center">
          <p className="mb-[2px]">
            {`Confirm ${status === "ready_to_assign" ? "update" : "accept"}`}
          </p>
          <p className="text-[13px] text-gray-800 mb-0">
            {`Are you sure you want to ${
              status === "ready_to_assign" ? "update" : "accept"
            } this document?`}
          </p>
        </ModalBody>
        <ModalFooter className="p-0 d-block">
          <div className="row g-0 m-0 modal-footer-row">
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
                onClick={() => {
                  setAcceptModal(false);
                }}
                disabled={submitLoading}
              >
                Cancel
              </Button>
            </div>
            <div className="col-6 d-grid">
              <Button
                color="darker-tertiary"
                size="lg"
                className="border-0 rounded-0 !py-[10px] bg-transparent text-default"
                onClick={() => onSubmit("accept")}
                disabled={submitLoading}
              >
                {submitLoading && (
                  <Spinner size="sm" className="btn-icon-prefix" />
                )}
                {status === "ready_to_assign" ? "Update" : "Accept"}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={renderTitle(status)}
            breadcrumbs={breadcrumbItems}
            subtitle={
              status !== "completed" ? (
                <Subtitle
                  className="px-7"
                  status={status}
                  invalidTabs={invalidTabs}
                  errors={isDisabled ? null : errors}
                  onClick={(tab) => {
                    handleErrorMessageClick(tab);
                  }}
                  formData={values}
                />
              ) : (
                <span />
              )
            }
          >
            <div className="flex flex-col p-7 min-h-[800px]">
              <FormProvider {...methods}>
                <Form className="flex flex-col gap-8">
                  <FormGroup>
                    <Label for="sdNumber" className="fw-normal mb-[12px]">
                      Unique number sustainability declaration
                    </Label>
                    <Input
                      type="text"
                      id="sdNumber"
                      {...computeProps("sdNumber", {
                        required: "Please enter an sd number",
                      })}
                      invalid={!!errors.sdNumber && !isDisabled}
                      maxLength={100}
                      disabled={isDisabled}
                    />
                    {errors.sdNumber &&
                      errors.sdNumber.type !== "required" &&
                      !isDisabled && (
                        <FormFeedback className="mt-0">
                          {errors.sdNumber.message}
                        </FormFeedback>
                      )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="dateOfIssuance" className="fw-normal mb-4">
                      Date of issuance
                    </Label>
                    <Datepicker
                      id="dateOfIssuance"
                      name="dateOfIssuance"
                      {...computeProps("dateOfIssuance", {
                        required: "Please enter date of issuance",
                      })}
                      control={control}
                      error={isDisabled ? null : errors.dateOfIssuance}
                      rules={{required: "Please enter date of issuance"}}
                      disabled={isDisabled}
                      format="d/m/Y"
                    />
                    {errors.dateOfIssuance &&
                      !isDisabled &&
                      errors.dateOfIssuance.type !== "required" && (
                        <FormFeedback className="mt-0">
                          {errors.dateOfIssuance.message}
                        </FormFeedback>
                      )}
                  </FormGroup>
                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader
                        targetId="supplier"
                        className="flex items-center"
                      >
                        {hasErrors && invalidTabs.supplier && !isDisabled && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          Supplier and recipient
                        </h5>
                      </AccordionHeader>

                      <AccordionBody accordionId="supplier" className="mb-4">
                        <SupplierAndRecipientInfo
                          status={status}
                          siteReferenceId={siteReferenceId}
                          isDisabled={isDisabled}
                          defaultValues={values}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader
                        targetId="general"
                        className="flex items-center"
                      >
                        {hasErrors && invalidTabs.general && !isDisabled && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          General information
                        </h5>
                      </AccordionHeader>
                      <AccordionBody accordionId="general" className="mb-4">
                        <GeneralInformation
                          countries={data?.bioLcCoproEuGetCountries ?? []}
                          status={status}
                          data={data}
                          isDisabled={isDisabled}
                          divisionId={divisionId}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader targetId="certification">
                        {hasErrors &&
                          invalidTabs.certification &&
                          !isDisabled && (
                            <Alert32 color="#e64949" className="mr-[9px]" />
                          )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          Scope of certification of raw material
                        </h5>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="mb-4"
                      >
                        <ScopeOfCertification
                          status={status}
                          isDisabled={isDisabled}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader targetId="emission">
                        {hasErrors && invalidTabs.emission && !isDisabled && (
                          <Alert32 color="#e64949" className="mr-[9px]" />
                        )}
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          Greenhouse gas (GHG) emission information
                        </h5>
                      </AccordionHeader>

                      <AccordionBody accordionId="emission" className="mb-4">
                        <GHGEmissionInfo
                          status={status}
                          // countries={data?.bioLcCoproEuGetCountries ?? []}
                          isDisabled={isDisabled}
                          clearErrors={clearErrors}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem className="!mt-[33px]">
                      <AccordionHeader
                        targetId="additionalInfo"
                        className="flex items-center"
                      >
                        <h5 className="accordion-title fw-light text-black text-xl opacity-100 m-0">
                          Supplementary information
                        </h5>
                      </AccordionHeader>

                      <AccordionBody
                        accordionId="additionalInfo"
                        className="mb-4"
                      >
                        <AdditionalInfo
                          status={status}
                          siteReferenceId={siteReferenceId}
                          isDisabled={isDisabled}
                          mbLocations={
                            data?.bioLcGetLocationGroupApi?.locationGroups ?? []
                          }
                          mbBalance={
                            data?.bioLcGetBalanceGroupApi?.balanceGroups ?? []
                          }
                          versioningDocs={
                            data?.bioLcGetVersioningDocsApi?.documents ?? []
                          }
                        />
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4">
                    {(status === "rejected" || status === "completed") && (
                      <Button
                        color="standard-primary"
                        className={
                          status === "rejected"
                            ? "show rounded-none"
                            : "primary-btn rounded-none"
                        }
                        type="button"
                        onClick={() =>
                          navigate({
                            pathname: `/doc-manager/${country}/`,
                            search: `?pageNo=${
                              searchParams.get("pageNo") ?? 0
                            }&docId=${docId}`,
                          })
                        }
                      >
                        <TailLeft24 className="btn-icon-prefix" />
                        Back
                      </Button>
                    )}
                    {status !== "rejected" && !rejecting && !isDisabled && (
                      <>
                        <Button
                          color="tertiary"
                          className="show btn-bg-br-gray"
                          style={{
                            borderRadius: "0px",
                          }}
                          type="button"
                          onClick={() => {
                            setRejecting(!rejecting);
                          }}
                          disabled={rejecting}
                        >
                          <Remove24 className="btn-icon-prefix" />
                          Reject
                        </Button>

                        <Button
                          type="button"
                          color="standard-primary btn-bg-br-black"
                          style={{
                            borderRadius: "0px",
                          }}
                          onClick={() => {
                            setAcceptModal(true);
                          }}
                          disabled={
                            Object.keys(errors).length !== 0 ||
                            rejecting ||
                            isDisabled
                          }
                        >
                          <Check24 className="btn-icon-prefix" />
                          {status === "ready_to_assign" ? "Update" : "Accept"}
                        </Button>
                      </>
                    )}
                  </div>
                  {rejecting && (
                    <>
                      <FormGroup>
                        <Label for="rejectionReason" className="fw-normal mb-4">
                          Reason for rejection
                        </Label>

                        <Input
                          type="select"
                          id="rejectionReason"
                          data-test="rejectionReason"
                          {...computeProps("rejectionReason", {
                            required: "Please select reject reason",
                          })}
                          invalid={!!errors.rejectionReason}
                          maxLength={100}
                          placeholder="Please select a reason"
                          onChange={(e) => {
                            handleRejectMessage(e.target.value);
                            setSelectedOption(e.target.value);
                          }}
                        >
                          <option value="">Please select a reason</option>

                          {rejectReasons.length > 0 &&
                            rejectReasons.map((p) => (
                              <option key={p.key} value={p.key}>
                                {p.value}
                              </option>
                            ))}
                        </Input>

                        {errors.rejectionReason && (
                          <FormFeedback className="mt-2">
                            {errors.rejectionReason.message}
                          </FormFeedback>
                        )}
                      </FormGroup>

                      {selectedOption === "other" && (
                        <FormGroup>
                          <Label
                            for="rejectionReasonNotes"
                            className="fw-normal mb-4"
                          >
                            Notes
                          </Label>
                          <Input
                            type="textarea"
                            rows="3"
                            id="rejectionReasonNotes"
                            data-test="rejectionReasonNotes"
                            {...computeProps("rejectionReasonNotes")}
                            invalid={!!errors.rejectionReasonNotes}
                            maxLength={200}
                            disabled={isDisabled}
                          />
                        </FormGroup>
                      )}

                      <span className="mt-[-40px]">{rejectMessage}</span>
                      <div className="flex flex-row justify-end items-center gap-4">
                        <Button
                          color="tertiary"
                          className="show btn-bg-br-gray"
                          style={{
                            borderRadius: "0px",
                          }}
                          type="button"
                          onClick={() => {
                            setRejecting(!rejecting);
                            clearErrors("rejectionReason");
                          }}
                          disabled={!rejecting}
                        >
                          <Remove24 className="btn-icon-prefix" />
                          Cancel
                        </Button>

                        <Button
                          type="button"
                          color="standard-primary btn-bg-br-black"
                          style={{
                            borderRadius: "0px",
                          }}
                          onClick={() => onSubmit("reject")}
                          disabled={errors.rejectionReason}
                        >
                          {submitLoading ? (
                            <Spinner size="sm" className="btn-icon-prefix" />
                          ) : (
                            <Check24 className="btn-icon-prefix" />
                          )}
                          Reject
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <SingleDocumentViewer
          document={{downloadUrl: documentFile?.downloadUrl}}
        />
      </div>
    </div>
  );
};

export default DetailedDocumentViewPage;
