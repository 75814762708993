import tw from "twin.macro";

const PrimaryPage = () => {
  return (
    <div className="overflow-x-auto">
      <HeaderSection>
        <PageTitle>Primary Page</PageTitle>
        <hr className="border-b-[1.5px]" />
      </HeaderSection>
    </div>
  );
};

export default PrimaryPage;

const HeaderSection = tw.div`relative flex flex-col pb-5 mx-[40px]`;
const PageTitle = tw.h1`text-[1.8125rem] -mb-5`;
