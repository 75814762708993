import {Datepicker} from "@bphxd/ds-core-react";
import {useParams} from "react-router-dom";
import {useState, useMemo} from "react";
import {useQuery} from "@apollo/client";
import {FORTY_FIVE_DAY_RANGE} from "modules/co-processing/constants/shipments";
import ExportBtn from "modules/co-processing/components/Historical/ExportBtn";
import {getDateRange} from "modules/co-processing/helpers/dateHelper";
import HistoricalTable from "modules/co-processing/components/Historical/Table";
import {getHistoricalConfig} from "modules/co-processing/components/Historical/columnConfig";
import {ShipmentTypeFilter} from "modules/co-processing/components/Historical/Filters";

const HistoricalData = () => {
  const {historicalType} = useParams();
  const config = getHistoricalConfig(historicalType);

  const [dateRange, setDateRange] = useState(
    getDateRange(FORTY_FIVE_DAY_RANGE),
  );
  const [shipmentType, setShipmentType] = useState("");

  const handleDateRange = ([startDate, endDate]) => {
    if (endDate) {
      // End date specified by the user is included in the date range.
      const inclusiveEndDate = new Date(endDate);
      inclusiveEndDate.setDate(endDate.getDate() + 1);
      const dates = [startDate, inclusiveEndDate];
      setDateRange(dates?.map((date) => date.toISOString()));
    }
  };

  const queryVars = useMemo(() => {
    if (historicalType === "batches") {
      return {
        op: "get_batches",
        batch_tab: "active",
        dateRange,
        includeDetails: true,
      };
    }
    if (historicalType === "shipments") {
      return {
        offset: -1,
        dateRange,
        shipmentType,
      };
    }
    return null;
  }, [dateRange, shipmentType, historicalType]);

  const {data, loading} = useQuery(config.apiQuery, {
    fetchPolicy: "network-only",
    variables: queryVars,
  });

  const historicalData = useMemo(
    () => (data && config ? config.transformFn(data) : []),
    [data, config],
  );

  const columns = useMemo(() => config.getColumns(), [config]);

  return (
    <div className="co-processing-container overflow-x-auto">
      <h1 className="pb-6 mx-[40px] fs-2 border-b-[1.5px]">
        {config?.pageTitle}
      </h1>

      <div className="flex flex-col justify-stretch w-full items-start px-7 py-2 mb-8">
        <div className="flex w-full gap-x-4 py-4 pl-5 bg-white">
          <div className="historical-filters" data-test="historical-filters">
            {historicalType === "shipments" && (
              <ShipmentTypeFilter
                activeShipmentType={shipmentType}
                onShipmentTypeChange={setShipmentType}
              />
            )}
            <Datepicker
              data-test="historical-date-range-filter"
              placeholder="Custom date range"
              options={{
                allowInput: false,
                defaultDate: dateRange,
                onChange: handleDateRange,
                mode: "range",
              }}
            />
          </div>
          <ExportBtn historicalData={historicalData} />
        </div>

        <HistoricalTable
          columns={columns}
          data={historicalData}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default HistoricalData;
