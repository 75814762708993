import tw from "twin.macro";
import PropTypes from "prop-types";
import {Button} from "reactstrap";
import {useState} from "react";
import {Trash24} from "@bphxd/ds-core-react/lib/icons";
import {
  ACTIVE_BATCH_KEYS,
  ACTIVE_BATCH_LABELS,
  ACTIVE_BATCH_PLACEHOLDERS,
  BUTTONS,
} from "modules/co-processing/constants/shipments";
import MetricInput from "modules/co-processing/components/Shipments/MetricInput";
import BatchUnlinkPopover from "modules/co-processing/components/Shipments/BatchUnlinkPopover";

const ActiveBatchLabel = tw.div`min-w-[120px] w-[120px]`;
const ActiveBatchField = tw.div`flex items-center pb-3 text-[13px] font-[400]`;
const ShipmentIdForm = ({
  shipments,
  handleShipmentChange,
  handleErrors,
  handleLoading,
  refreshData,
  batchId,
  remainingVolume,
  updateTotalVolume,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [selectedShipment, setSelectedShipment] = useState(null);

  const getFieldDetails = () => {
    const shipmentKey = ACTIVE_BATCH_KEYS.SHIPMENT_ID;
    return {
      key: shipmentKey,
      dataKey: shipmentKey,
      errorKey: shipmentKey,
      placeholder: ACTIVE_BATCH_PLACEHOLDERS.ID,
    };
  };

  const onRemove = (event, shipment) => {
    event.stopPropagation();
    setSelectedShipment({
      name: shipment.shipment_id,
      id: shipment.batch_allocation_id,
    });
    setShowPopover(true);
  };

  return (
    <div className="w-full md:w-1/2 px-2">
      {shipments?.map((shipment, index) => (
        <div data-test="copro-active-batch-shipment-list" key={index}>
          <div
            data-test="copro-active-batch-shipment-title"
            className="text-[13px] fw-medium pb-2 flex flex-row justify-between items-center"
          >
            <div>{`${ACTIVE_BATCH_LABELS.SHIPMENT} ${index + 1}`}</div>
            {!!shipment?.shipment_id && (
              <div
                id={`copro-us-unlink-shipment-${shipment?.batch_allocation_id}`}
              >
                <Button
                  onClick={(event) => onRemove(event, shipment)}
                  className="ml-2 bg-white border-0 text-black"
                >
                  <span>{BUTTONS.DELETE}</span>
                  <Trash24
                    className="!text-black cursor-pointer"
                    color="black"
                  />
                </Button>
              </div>
            )}
          </div>

          {shipment?.shipment_id ? (
            <>
              <ActiveBatchField>
                <ActiveBatchLabel data-test="copro-active-batch-shipment-fields">
                  {ACTIVE_BATCH_LABELS.SHIPMENT_VOLUME}
                </ActiveBatchLabel>
                <div>{`${shipment?.shipment_volume?.toLocaleString()} bbl`}</div>
              </ActiveBatchField>

              <ActiveBatchField data-test="copro-active-batch-shipment-inputs">
                <ActiveBatchLabel>
                  {ACTIVE_BATCH_LABELS.SHIPMENT_ID}
                </ActiveBatchLabel>
                <div className="flex items-center">
                  <div className="flex-1">{shipment?.shipment_id}</div>
                </div>
              </ActiveBatchField>
            </>
          ) : (
            <ActiveBatchField data-test="copro-active-batch-shipment-inputs">
              <ActiveBatchLabel>
                {ACTIVE_BATCH_LABELS.SHIPMENT_ID}
              </ActiveBatchLabel>
              <MetricInput
                batchId={batchId}
                value={shipment?.shipment_id}
                details={getFieldDetails(index)}
                onChange={(event) =>
                  handleShipmentChange(event.target.value, index)
                }
                reportErrors={handleErrors}
                setChecking={handleLoading}
                remainingVolume={remainingVolume}
                updateTotalVolume={updateTotalVolume}
              />
            </ActiveBatchField>
          )}
        </div>
      ))}
      {!!selectedShipment?.id && (
        <BatchUnlinkPopover
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          popoverID={`copro-us-unlink-shipment-${selectedShipment?.id}`}
          refreshData={refreshData}
          shipmentName={selectedShipment?.name}
          shipmentID={selectedShipment?.id}
        />
      )}
    </div>
  );
};

ShipmentIdForm.propTypes = {
  shipments: PropTypes.array,
  handleShipmentChange: PropTypes.func,
  handleErrors: PropTypes.func,
  handleLoading: PropTypes.func,
  refreshData: PropTypes.func,
  batchId: PropTypes.string,
  remainingVolume: PropTypes.number,
  updateTotalVolume: PropTypes.func,
};

export default ShipmentIdForm;
