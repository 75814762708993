import {useQuery} from "@apollo/client";
import {uniqBy} from "lodash";
import {Alert24, Info24} from "@bphxd/ds-core-react/lib/icons";
import {Input, Spinner, FormText} from "reactstrap";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {COPRO_EU_LANDING_PAGE_CONFIG_CENTER} from "../../../graphql/es-co-processing/EuLandingPage";
import LocationDetails from "../components/landing-page/LocationDetails";
import COPRO_EU_GET_NOTIFICATION from "../../../graphql/es-co-processing/GetNotification";
import Layout from "../components/Layout";
import TaskComponent from "../components/landing-page/TaskComponent";
import "./index.scss";

const EsLandingPage = () => {
  // Calculate the quarters from the current date till last year 4 quarters in an array to display in select box
  const {appSetting, setAppSetting} = useAppSetting();
  const {country: countryName} = useParams();

  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_COPRO);

  const quarters = [];
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const currentQuarter = Math.floor((currentMonth + 3) / 3);
  const lastYear = currentYear - 1;
  const totalQuarters = 4 + currentQuarter;
  for (let i = 0; i < totalQuarters; i += 1) {
    const quarter = currentQuarter - i;
    const year = quarter > 0 ? currentYear : lastYear;
    const qtr = quarter <= 0 ? 4 + quarter : quarter;
    quarters.push({name: `${year}Q${qtr}`, label: `Q${qtr} ${year}`});
  }
  const [mbCycle, setMBCycle] = useState(quarters[0].name);
  const {data, loading} = useQuery(COPRO_EU_GET_NOTIFICATION, {
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const {data: configCenter, loading: configCenterLoading} = useQuery(
    COPRO_EU_LANDING_PAGE_CONFIG_CENTER,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "network-only",
    },
  );

  const uniqueLocations = uniqBy(
    configCenter?.bioLcCoproConfigCenterLocationDetails?.Records ?? [],
    "location_code",
  );

  useEffect(() => {
    const isSticky = () => {
      const header = document.querySelector(".header-section");
      const scrollTop = window.scrollY;
      if (scrollTop >= 300) {
        header.classList.remove("d-none");
      } else {
        header.classList.add("d-none");
      }
    };
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"}, // Takes us back to 'Global' context
    {text: "Spain", link: "/"},
    {text: "Co-processing", link: "/copro-spain/spain"},
  ];

  const taskResult = data?.bioLcCoproEuGetNotification;
  const taskNotification = [
    {
      id: 1,
      text: `${taskResult?.attention} ${
        taskResult?.attention === 1 ? "document requires" : "documents require"
      } your attention`,
      link: "/copro-spain/spain/document-manager/incoming-documents",
      icon: <Alert24 className="alert-icon--danger" />,
    },
    {
      id: 2,
      text: `${taskResult?.additionalInfo} ${
        taskResult?.additionalInfo === 1
          ? "document requires"
          : "documents require"
      } additional information`,
      link: "/copro-spain/spain/document-manager/incoming-documents",
      icon: <Info24 className="alert-icon--info" />,
    },
    {
      id: 3,
      text: `${taskResult?.readyForMb} ${
        taskResult?.readyForMb === 1 ? "document" : "documents"
      } ready to assign to mass balance`,
      link: "/copro-spain/spain/document-manager/incoming-documents",
      icon: <Info24 className="alert-icon--warning" />,
    },
  ];

  // Code to loop through the taskNotification array and display the text and link
  const taskDisplay = taskNotification.map((item) => {
    return <TaskComponent item={item} key={item.id} />;
  });

  return (
    <>
      <div className="fixed top-0 bg-white rounded d-none border-transparent border-1 px-4 py-3 shadow-md header-section z-50 w-full">
        <div className="flex flex-row justify-start items-center gap-4">
          <h3>Your mass balances</h3>
          <div className="min-w-[220px] max-w-[220px]">
            <Input
              type="select"
              name="quarter"
              value={mbCycle}
              onChange={(evt) => setMBCycle(evt.target.value)}
            >
              {quarters.map(({name, label}) => (
                <option value={name} key={name}>
                  {label}
                </option>
              ))}
            </Input>
          </div>
        </div>
      </div>
      <Layout title="Dashboard" breadcrumbs={breadcrumbItems}>
        <section className="flex flex-col justify-stretch items-start w-full pb-[74px] m-0 px-7">
          <h4 className="fw-light fs-4 pb-3">Tasks</h4>
          {loading && (
            <div className="flex flex-row items-center justify-center flex-1">
              <Spinner size="sm" />
            </div>
          )}
          {!loading && (
            <div className="flex flex-row flex-wrap flex-1 gap-4">
              {taskDisplay}
            </div>
          )}
        </section>
        <section className="flex flex-col gap-4 bg-[#f2f2f2] px-7 py-10">
          <h2 className="fw-light fs-3 m-0">Your mass balances</h2>
          <div className="bp-core flex flex-row justify-start items-center gap-4">
            <div className="min-w-[220px]">
              <Input
                type="select"
                name="quarter"
                value={mbCycle}
                onChange={(evt) => setMBCycle(evt.target.value)}
              >
                {quarters.map(({name, label}) => (
                  <option value={name} key={name}>
                    {label}
                  </option>
                ))}
              </Input>
            </div>
          </div>
          <hr className="border-1 pb-6 mb-0" />
          {configCenterLoading && <Spinner size="lg" />}
          {uniqueLocations
            ?.map((location) => (
              <LocationDetails
                key={location.location_id}
                location={location}
                mbCycle={mbCycle}
                materials={
                  configCenter?.bioLcCoproConfigCenterMaterialMaster?.Records
                }
              />
            ))
            .toReversed()}
        </section>
      </Layout>
    </>
  );
};

export default EsLandingPage;
