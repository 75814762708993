import React from "react";
import PropTypes from "prop-types";
import {Modal, ModalBody, ModalFooter, Button} from "reactstrap";

const MassBalanceCloseResponseModal = ({
  showResponse,
  setShowResponse,
  headerData,
  bodyData,
}) => {
  return (
    <Modal size="sm" isOpen={showResponse} className="modal-dialog-centered">
      <ModalBody className="text-center">
        <p className="mb-[2px]">{headerData}</p>
        <p className="text-[13px] text-gray-800 mb-0">{bodyData}</p>
      </ModalBody>
      <ModalFooter className="p-0 d-block">
        <div className="row g-0 m-0 modal-footer-row">
          <div className="col-12 d-grid">
            <Button
              color="darker-tertiary"
              className="border-0 rounded-0 py-4 bg-transparent text-default"
              onClick={() => setShowResponse(false)}
            >
              Back to mass balance
            </Button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

MassBalanceCloseResponseModal.propTypes = {
  showResponse: PropTypes.bool,
  setShowResponse: PropTypes.func,
  headerData: PropTypes.string,
  bodyData: PropTypes.string,
};

export default MassBalanceCloseResponseModal;
