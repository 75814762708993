import {useParams, Link} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";
import {Bioverse as bioverseConfig} from "content/navigations";
import {useMemo, useEffect} from "react";
import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {capitalize, uniqBy} from "lodash";
import {useQuery} from "@apollo/client";
import {LOCATION_DETAILS} from "graphql/config-center/LocationDetails";
import Section from "modules/common/SectionType/SectionCountry";
import {use} from "chai";

const FeatureCountryDashboard = () => {
  const {module} = useParams();
  const {appSetting, setAppSetting} = useAppSetting();

  const {data: locationData, loading: locationLoading} =
    useQuery(LOCATION_DETAILS);

  const uniqueLocations = uniqBy(
    locationData?.bioLcCoproConfigCenterLocationDetails?.Records ?? [],
    "location_code",
  );

  // Filter the featureMappingData from appSetting to get countries specific to the current module, excluding 'GLOBAL'
  const currentModuleCountries =
    module &&
    appSetting?.featureMappingData?.filter(
      (item) => item[module] === true && item.countryName !== "GLOBAL",
    );

  // menu items
  const bioverseMenu = useMemo(
    () =>
      bioverseConfig(
        appSetting?.currentCountry,
        uniqueLocations,
        locationLoading,
      ),
    [appSetting?.currentCountry, uniqueLocations, locationLoading],
  );

  useEffect(() => {
    const currentModuleDetails = bioverseMenu.find(
      (item) => item.module === module,
    );
    if (appSetting?.currentModuleTitle !== currentModuleDetails?.moduleTitle) {
      setAppSetting((prevAppSetting) => ({
        ...prevAppSetting,
        currentModuleTitle: currentModuleDetails?.moduleTitle,
      }));
    }
  }, [bioverseMenu, module, setAppSetting, appSetting?.currentModuleTitle]);

  // Define breadcrumb items for navigation, dynamically setting the second item based on the current module title
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"}, // Takes us back to 'Global' context
    {text: capitalize(appSetting?.currentModuleTitle ?? ""), link: "/"},
  ];

  return (
    <div className=" min-h-screen px-6 lg:px-10">
      <Breadcrumb className="px-8 pt-5">
        {breadcrumbItems.map((item, index) => (
          <BreadcrumbItem
            key={index}
            active={index === breadcrumbItems.length - 1}
          >
            {item.text === "BioVerse" ? (
              <Link
                to="/"
                data-test="home-button"
                onClick={() => {
                  const countryFeatureData =
                    appSetting?.featureMappingData?.find(
                      (item) => item.countryName === "GLOBAL",
                    );

                  setAppSetting({
                    ...appSetting,
                    currentCountry: "GLOBAL",
                    currentCountryMappingData: countryFeatureData,
                    currentModule: "",
                    currentModuleTitle: "",
                  });
                }}
              >
                {item.text}
              </Link>
            ) : index === breadcrumbItems.length - 1 ? (
              <span>{item.text}</span>
            ) : (
              <Link to={item.link}>{item.text}</Link>
            )}
          </BreadcrumbItem>
        ))}
      </Breadcrumb>

      <div data-test="section-type" className="py-6">
        {appSetting?.currentModuleTitle && (
          <h1 className="text-4x text-black fw-light mb-0 pb-[48px] px-8 ">
            {`${capitalize(appSetting?.currentModuleTitle)} `}
          </h1>
        )}

        <div className="px-7">
          <hr className="border-1 mt-0 mb-6" />
        </div>

        <div className="grid grid-cols-1 gap-3 px-8 mt-5 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4">
          {currentModuleCountries?.length > 0 &&
            currentModuleCountries?.map((item, index) => (
              <Section
                key={`country-${index}`}
                itemKey={`country-${index}`}
                country={item.countryName?.toLowerCase()}
                module={module}
                uniqueLocations={uniqueLocations}
                locationLoading={locationLoading}
              />
            ))}
          {currentModuleCountries && currentModuleCountries?.length === 0 && (
            <p className="text-xl pt-7 pb-12">No items to display here...</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default FeatureCountryDashboard;
