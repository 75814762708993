import PropTypes from "prop-types";

import {useFormContext} from "react-hook-form";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";

import CheckboxFlag from "modules/common/BinaryFlagNullable";
import UnitOfMeasure from "./UnitOfMeasure";

import {DIVISION_ID_GF} from "../../constants";

const GeneralInformation = ({
  data,
  status,
  isDisabled,
  countries,
  divisionId,
}) => {
  const {
    register,
    formState: {errors},
    control,
  } = useFormContext();
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const productTypeItems = data?.bioLcGetMaterialDataApi?.productNames || [];
  const rawMaterialTypes =
    data?.bioLcGetMaterialDataApi?.rawMaterialNames || [];

  const isGF = divisionId === DIVISION_ID_GF;
  const qtyInput = isGF ? "feedstockQty" : "productQty";
  const qtyErrors = isGF ? errors.feedstockQty : errors.productQty;
  const qtyUomInput = isGF ? "feedstockQtyUom" : "feedstockQtyUom";
  const qtyUomErrors = isGF ? errors.feedstockQtyUom : errors.feedstockQtyUom;

  return (
    <div className="flex flex-col gap-5 w-full">
      <FormGroup>
        <Label for="productType" className="fw-normal mb-4">
          Type of product
        </Label>
        <Input
          type="select"
          id="productType"
          data-test="productType"
          {...computeProps("productType", {
            required: "Please enter type of product",
          })}
          invalid={!!errors.productType && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
          placeholder="Please choose from list"
        >
          <option value="">Please choose from list</option>
          {productTypeItems.map((productTypeItem, index) => (
            <option key={index} value={productTypeItem.productName}>
              {productTypeItem.productName}
            </option>
          ))}
        </Input>
        {errors.productType && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.productType.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialType" className="fw-normal mb-4">
          Type of raw material
        </Label>

        <Input
          type="select"
          id="rawMaterialType"
          data-test="rawMaterialType"
          {...computeProps("rawMaterialType", {
            required: "Please enter type of raw material",
          })}
          invalid={!!errors.rawMaterialType && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
          placeholder="Please choose from list"
        >
          <option value="">Please choose from list</option>

          {rawMaterialTypes.length > 0 &&
            rawMaterialTypes.map((p, i) => (
              <option key={i} value={p.rawMaterialName}>
                {p.rawMaterialName}
              </option>
            ))}
        </Input>

        {errors.rawMaterialType && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.rawMaterialType.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="additionalInfo" className="fw-normal mb-4">
          Additional information (voluntary)
        </Label>
        <Input
          type="textarea"
          rows="3"
          id="additionalInfo"
          data-test="additionalInfo"
          {...computeProps("additionalInfo")}
          invalid={!!errors.additionalInfo && !isDisabled}
          maxLength={250}
          disabled={isDisabled}
        />
      </FormGroup>
      <FormGroup>
        <Label for="rawMaterialOriginCountryName" className="fw-normal  mb-4">
          Country of origin (of the origin raw material)
        </Label>
        <Input
          type="select"
          id="rawMaterialOriginCountryName"
          data-test="rawMaterialOriginCountryName"
          {...computeProps("rawMaterialOriginCountryName", {
            required: "Please enter country of origin",
          })}
          invalid={!!errors.rawMaterialOriginCountryName && !isDisabled}
          disabled={isDisabled}
        >
          {countries.map((country) => (
            <option key={country.code} value={country.name}>
              {country.name}
            </option>
          ))}
        </Input>
        {errors.rawMaterialOriginCountryName && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.rawMaterialOriginCountryName.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for={qtyInput} className="fw-normal mb-4">
          Quantity
        </Label>
        <Input
          type="number"
          id={qtyInput}
          data-test={qtyInput}
          {...computeProps(qtyInput, {
            required: "Please enter quantity",
            pattern: {
              value: /^(\d{1,10}(\.\d{1,5})?)$/,
              message:
                "Value must be number with (max 10 digits before and 5 digits after decimal point)",
            },
          })}
          rules={{
            validate: (value) =>
              isDisabled || value > 0 || "Please enter a valid quantity",
          }}
          invalid={!!qtyErrors && !isDisabled}
          disabled={isDisabled}
        />

        {qtyErrors && !isDisabled && (
          <FormFeedback className="mt-2">{qtyErrors.message}</FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="feedstockQtyUom" className="fw-normal">
          Unit of measure
        </Label>
        <UnitOfMeasure
          id={qtyUomInput}
          name={qtyUomInput}
          control={control}
          error={isDisabled ? null : qtyUomErrors}
          disabled={isDisabled}
          rules={{
            validate: (value) =>
              !isDisabled && (value === null || value === "")
                ? "Please select unit of measure"
                : true,
          }}
        />
      </FormGroup>
      <FormGroup>
        <Label for="productEnergyContent" className="fw-normal mb-4">
          Energy content (MJ)
        </Label>
        <Input
          type="number"
          id="productEnergyContent"
          data-test="productEnergyContent"
          {...computeProps("productEnergyContent", {
            pattern: {
              value: /^(\d{1,10}(\.\d{1,5})?)$/,
              message:
                "Value must be number with (max 10 digits before and 5 digits after decimal point)",
            },
          })}
          rules={{
            validate: (value) =>
              isDisabled || value > 0
                ? true
                : "Please enter a valid energy content",
          }}
          invalid={!!errors.productEnergyContent && !isDisabled}
          disabled={isDisabled}
        />

        {errors.productEnergyContent && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.productEnergyContent.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup className="flex flex-col">
        <Label for="euRedCompliantFlag" className="fw-normal">
          EU RED Compliant material
        </Label>
        <CheckboxFlag
          id="euRedCompliantFlag"
          name="euRedCompliantFlag"
          control={control}
          error={isDisabled ? null : errors.euRedCompliantFlag}
          disabled={isDisabled}
          rules={{
            validate: (value) => {
              return isDisabled || value !== null || "Please select an option";
            },
          }}
        />
      </FormGroup>
      <FormGroup className="flex flex-col">
        <Label for="isccCompliantFlag" className="fw-normal">
          ISCC Compliant material (voluntary)
        </Label>

        <CheckboxFlag
          id="isccCompliantFlag"
          name="isccCompliantFlag"
          control={control}
          error={isDisabled ? null : errors.isccCompliantFlag}
          disabled={isDisabled}
          rules={{
            validate: (value) => {
              return isDisabled || value !== null || "Please select an option";
            },
          }}
        />
      </FormGroup>
    </div>
  );
};
GeneralInformation.propTypes = {
  data: PropTypes.object,
  status: PropTypes.string,
  countries: PropTypes.arrayOf(PropTypes.object).isRequired,
  isDisabled: PropTypes.bool,
  divisionId: PropTypes.string,
};
export default GeneralInformation;
