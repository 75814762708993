import PropTypes from "prop-types";
import React, {useCallback, useState, useMemo} from "react";
import {Popover, PopoverBody, Progress} from "reactstrap";
import {CHECKLIST_LABELS} from "modules/co-processing/constants/batches";
import {Empty, PartialIcon, SuccessIcon} from "./Icons";
import {checklistStatusPropTypes} from "./checkListStatusTypes";
import "./index.scss";

const BatchStatusEnum = {
  UNCHECKED: 0,
  PARTIAL: 1,
  COMPLETE: 2,
};

const StatusIcons = {
  [BatchStatusEnum.PARTIAL]: <PartialIcon />,
  [BatchStatusEnum.COMPLETE]: <SuccessIcon />,
  [BatchStatusEnum.UNCHECKED]: <Empty />,
};

const getStatusIcon = (status) => StatusIcons[status] || <Empty />;

export const StatusItem = ({status, label}) => {
  return (
    <div className="status-item" data-test={`${label}-status-item`}>
      {getStatusIcon(status)}
      <span>{label}</span>
    </div>
  );
};

StatusItem.propTypes = {
  label: PropTypes.string.isRequired,
  status: PropTypes.number,
};

const ChecklistPopover = ({popoverId, children, statuses}) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = useCallback(
    () => setPopoverOpen((prevState) => !prevState),
    [],
  );

  return (
    <div>
      <div
        id={popoverId}
        aria-haspopup="true"
        className="px-2 cursor-pointer"
        onMouseEnter={togglePopover}
        onMouseLeave={togglePopover}
      >
        {children}
      </div>
      <Popover
        className="checklist-popover"
        isOpen={popoverOpen}
        target={popoverId}
        toggle={togglePopover}
      >
        <PopoverBody>
          {Object.keys(CHECKLIST_LABELS).map((key) => (
            <StatusItem
              key={key}
              label={CHECKLIST_LABELS[key]}
              status={statuses?.[key] || null}
            />
          ))}
        </PopoverBody>
      </Popover>
    </div>
  );
};

ChecklistPopover.propTypes = {
  popoverId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  statuses: PropTypes.shape(checklistStatusPropTypes).isRequired,
};

export default ChecklistPopover;
