import PropTypes from "prop-types";
import {useState, useCallback, useEffect} from "react";
import {Button, ModalBody, Modal, ModalHeader, Input, Label} from "reactstrap";
import {Close} from "@bphxd/ds-core-react";
import {
  BUTTONS,
  INPUT_METRIC,
  PIPELINE_SUB_TYPE_OPTIONS,
  VESSEL_SUB_TYPE_OPTIONS,
  SUB_TYPES,
  RENEWABLE_OPTIONS,
  RINS_QUALIFIED_OPTIONS,
} from "modules/co-processing/constants/shipments";

const EditShipmentModal = ({details, saveDetail, modal, setModal}) => {
  const [detailValue, setDetailValue] = useState(null);
  const [initialValue, setInitialValue] = useState(null);

  useEffect(() => {
    setDetailValue(details?.value);
    setInitialValue(details?.value);
  }, [details?.value]);

  const close = () => {
    setDetailValue(null);
    setModal(false);
  };
  const handleChange = useCallback(
    (event) => {
      const {value, name} = event.target;
      const numericInputValue = parseFloat(value, 10);
      if (
        name === "total_actual_volume" &&
        (!/^\d*\.?\d*$/.test(value) || numericInputValue > details?.maxValue)
      ) {
        return;
      }
      setDetailValue(value);
    },
    [details],
  );

  const onSaveDetail = (allocated_id, key, value) => {
    saveDetail(allocated_id, key, value === SUB_TYPES.NONE ? null : value);
    close();
  };

  const getDefaultInput = () => {
    return (
      <Input
        style={{resize: "none"}}
        data-test="copro-shipment-modal-input"
        name={details?.key}
        id={details?.key}
        type={details?.inputType}
        value={detailValue}
        onChange={handleChange}
        maxLength={200}
        required
      />
    );
  };

  const renderInput = () => {
    switch (details?.key) {
      case "total_actual_volume":
        return (
          <div className="flex">
            <Input
              data-test="copro-metric-actual-input"
              className="min-w-[169px] text-xs"
              name={details?.key}
              id={details?.key}
              value={detailValue || details?.value || ""}
              onChange={(event) => handleChange(event)}
            />
            {!!details?.metric && (
              <div
                data-test="copro-shipment-metric-appended"
                className="input-group-append bg-white"
              >
                <div className="input-group-text">{INPUT_METRIC}</div>
              </div>
            )}
          </div>
        );
      case "renewable_shipment_flag":
        return (
          <Input type="select" onChange={handleChange}>
            {RENEWABLE_OPTIONS.map(({value, label, setDisable}, index) => (
              <option
                disabled={setDisable ? detailValue != null : false}
                key={index}
                value={value}
              >
                {label}
              </option>
            ))}
          </Input>
        );
      case "credits_qualified":
        return (
          <Input type="select" onChange={handleChange}>
            {RINS_QUALIFIED_OPTIONS.map(({value, label, setDisable}, index) => (
              <option
                disabled={setDisable ? detailValue != null : false}
                key={index}
                value={value}
              >
                {label}
              </option>
            ))}
          </Input>
        );
      case "sub_type":
        return (
          <Input type="select" onChange={handleChange}>
            {(details?.shipmentType === "PIPELINE"
              ? PIPELINE_SUB_TYPE_OPTIONS
              : VESSEL_SUB_TYPE_OPTIONS
            ).map(({value, label, setDisable}, index) => (
              <option
                disabled={setDisable ? detailValue != null : false}
                key={index}
                value={value}
              >
                {label}
              </option>
            ))}
          </Input>
        );
      case "notes":
      default:
        return getDefaultInput();
    }
  };

  const isButtonDisabled =
    !detailValue ||
    detailValue === initialValue ||
    (typeof detailValue === "string" && detailValue.trim().length === 0);

  return (
    <div>
      <Modal size="sm" unmountOnClose centered isOpen={modal}>
        <ModalHeader
          className="border border-b"
          close={<Close className="custom-close" onClick={() => close()} />}
        >{`Edit ${details?.label?.toLowerCase()}`}</ModalHeader>
        <ModalBody className="p-0">
          <div className="px-4 pt-6">
            <Label className="text-gray-700 text-sm mb-2" for={details?.label}>
              {details?.label}
            </Label>
            {renderInput()}
          </div>
          <div className="flex flex-row w-full border border-t mt-32">
            <Button
              className="w-1/2 !text-gray-400"
              color=""
              onClick={() => close()}
            >
              {BUTTONS.CANCEL}
            </Button>
            <span>
              <div
                style={{
                  width: "1px",
                  height: "100%",
                  backgroundColor: "#ededed",
                }}
              />
            </span>
            <Button
              className="w-1/2 disabled:border-0"
              color=""
              disabled={isButtonDisabled}
              onClick={() =>
                onSaveDetail(
                  details?.allocated_id ?? null,
                  details?.key,
                  detailValue,
                )
              }
            >
              {BUTTONS.SAVE}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

EditShipmentModal.propTypes = {
  details: PropTypes.object,
  saveDetail: PropTypes.func,
  modal: PropTypes.bool,
  setModal: PropTypes.func,
};

export default EditShipmentModal;
