import tw from "twin.macro";
import {Button} from "reactstrap";
import {
  BUTTONS,
  PAGE_TITLE,
} from "modules/co-processing/constants/coProcessing";
import DataView from "modules/co-processing/components/Shipments/DataView";

const BulkAdjustment = () => {
  return (
    <PageLayout>
      <HeaderSection data-test="bulk-adjustment-header">
        <PageTitle>{PAGE_TITLE.SHIPMENTS}</PageTitle>
      </HeaderSection>

      <HorizontalLine />
      <div className="-ml-6">
        <DataView />
      </div>
      <HorizontalLine />

      <div
        data-test="adjustment-section"
        className="flex flex-col space-y-7 mt-10"
      >
        <div className="flex justify-between">
          <div className="text-[26px] pr-3 font-light">Bulk adjustment</div>
          <Button
            className="rounded-0 lg:mr-32"
            color="standard-outline"
            data-test="save-adjustment-btn"
          >
            <span>{BUTTONS.SAVE_AND_REFRESH}</span>
          </Button>
        </div>
        <div className="flex justify-between mt-7">
          <div className="flex space-x-5 pl-6" data-test="volume-display">
            <span className="text-secondary">Remaining volume to adjust</span>
            <span>( volume placeholder ) bbl</span>
          </div>

          <div className="ml-auto lg:space-x-24">
            <Button
              className="btn-clear rounded-0"
              color="standard-outline"
              data-test="cancel-adjustment-btn"
            >
              {BUTTONS.CANCEL}
            </Button>

            <Button
              color="primary"
              className="rounded-0 text-nowrap"
              data-test="submit-adjustment-btn"
            >
              {BUTTONS.SUBMIT_ADJUSTMENT}
            </Button>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default BulkAdjustment;

const HeaderSection = tw.div`relative flex flex-col`;
const HorizontalLine = tw.hr`h-px my-8 bg-gray-700 border-0 dark:bg-gray-800`;
const PageTitle = tw.h1`text-[1.8125rem]`;
const PageLayout = tw.div`overflow-x-auto px-10 pt-2 flex flex-col space-y-6`;
