import React, {useEffect, useMemo, useState} from "react";
import moment from "moment";
import {toast} from "react-toastify";
import {
  Button,
  InputGroup,
  InputGroupText,
  Input,
  UncontrolledDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import {DIV_CODE_GF} from "constants/divisionDetails";
import {uniqBy, orderBy} from "lodash";
import {useUserSettings} from "providers/userSettings";
import {useLazyQuery, useQuery} from "@apollo/client";
import {
  Add24,
  Filter24,
  Remove24,
  Download24,
  TriangleRightSolid24,
} from "@bphxd/ds-core-react/lib/icons";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import {
  DOC_STATUS_TRACKER,
  uploadFile,
} from "graphql/docManager/uploadDocument";
import {ALL_INCOMING_DOCUMENTS} from "graphql/docManager/incomingDocuments";
import {
  ExportAnnualAPI,
  ExportSelectedRowsAPI,
} from "graphql/docManager/ExportIncomingDetails";
import {
  POLLING_END_IN_SECONDS,
  POLLING_FIRST_TRIGGER_IN_SECONDS,
  POLLING_INTERVAL_IN_SECONDS,
  DOC_STATUS,
} from "modules/DocManager/constants";

import {useParams, useSearchParams} from "react-router-dom";
import {downloadFile} from "utils/helpers/downloadFile";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import getColumnsUK from "./Columns/ColumnsUK";
import getColumnsPoland from "./Columns/ColumnsPoland";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import DocManagerFilter from "../Filters/DocManagerFilter";
import UploadDocuments from "../UploadDocuments";
import PreviewDocumentModal from "../UploadDocuments/PreviewDocumentModal";
import ResponseModal from "../UploadDocuments/ResponseModal";
import DocumentTable from "../Table/DocumentTable";
import NotificationBar from "./NotificationBar";

import "../../pages/styles.scss";

const IncomingDocumentComponent = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const pageIndexNumber = searchParams.get("pageNo") ?? 0;
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_GF);

  const [rowSelection, setRowSelection] = useState({});
  const [pageIndex, setPageIndex] = useState(pageIndexNumber);
  const [showUploadModal, setUploadModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [selectedDivision, setSelectedDivision] = useState(null);

  const [showFilter, setShowFilter] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [receivingPointList, setReceivingPointList] = useState([]);
  const [productList, setProductList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [originList, setOriginList] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);

  const [isStillUploading, setIsStillUploading] = useState(false);

  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);

  const [supplierName, setSupplierName] = useState(undefined);
  const [receivingPointName, setReceivingPointName] = useState(undefined);
  const [productName, setProductName] = useState(undefined);
  const [statusName, setStatusName] = useState(undefined);
  const [originName, setOriginName] = useState(undefined);

  const [sortedData, setSortedData] = useState(undefined);

  const {country: selectedCountry} = useParams();

  const siteReferenceId = siteReferenceData?.siteReferenceId;
  const divisionId = divisionData?.divisionId;

  const sortByItem = searchParams.get("sortBy");

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const {
    data: incomingRecordsData,
    loading,
    refetch,
  } = useQuery(ALL_INCOMING_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {siteReferenceId, divisionId},
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const incomingResponseData =
    incomingRecordsData &&
    incomingRecordsData?.bioLcGetIncomingDocsApi?.documents;

  const divisionDataList =
    incomingRecordsData &&
    incomingRecordsData?.bioLcGetDivisionData?.divisionData;

  useEffect(() => {
    if (incomingResponseData) {
      const supplierItems = incomingResponseData
        ?.filter(
          (x) =>
            x.supplierName !== undefined &&
            x.supplierName !== null &&
            x.supplierName !== "",
        )
        ?.map((a) => a.supplierName);
      const uniqSuppliers = uniqBy(supplierItems ?? []);
      const sortedSupplierList = orderBy(
        uniqSuppliers,
        [(supplier) => supplier.toLowerCase()],
        ["asc"],
      );
      setSupplierList(sortedSupplierList);
      const receivingPointItems = incomingResponseData
        ?.filter(
          (x) =>
            x.recipientReceiptAddress !== undefined &&
            x.recipientReceiptAddress !== null &&
            x.recipientReceiptAddress !== "",
        )
        ?.map((a) => a.recipientReceiptAddress);

      const uniqreceivingPointItems = uniqBy(receivingPointItems ?? []);
      const sortedreceivingPointItemsList = orderBy(
        uniqreceivingPointItems,
        [(recname) => recname.toLowerCase()],
        ["asc"],
      );
      setReceivingPointList(sortedreceivingPointItemsList);

      const productTypeItems = incomingResponseData
        ?.filter(
          (x) =>
            x.productType !== undefined &&
            x.productType !== null &&
            x.productType !== "",
        )
        ?.map((a) => a.productType);

      const uniqproductTypeItems = uniqBy(productTypeItems ?? []);
      const sortedproductTypeItemsList = orderBy(
        uniqproductTypeItems,
        [(prodname) => prodname.toLowerCase()],
        ["asc"],
      );
      setProductList(sortedproductTypeItemsList);

      const statusItems = incomingResponseData
        ?.filter(
          (x) => x.state !== undefined && x.state !== null && x.state !== "",
        )
        ?.map((a) => ({
          value: a.state,
          label: DOC_STATUS[a.state],
        }));
      const uniqstatusItems = uniqBy(statusItems ?? [], "value");
      const sortedstatusItemsList = orderBy(
        uniqstatusItems,
        [(prodname) => prodname.value.toLowerCase()],
        ["asc"],
      );
      setStatusList(sortedstatusItemsList);
      const originItems = incomingResponseData
        ?.filter(
          (x) =>
            x.countryCode !== undefined &&
            x.countryCode !== null &&
            x.countryCode !== "",
        )
        ?.map((a) => a.countryCode);
      const uniqoriginItems = uniqBy(originItems ?? []);
      const sortedoriginItemsList = orderBy(
        uniqoriginItems,
        [(orgname) => orgname.toLowerCase()],
        ["asc"],
      );
      setOriginList(sortedoriginItemsList);
    }
  }, [
    incomingResponseData,
    setSupplierList,
    setReceivingPointList,
    setProductList,
    setStatusList,
    setOriginList,
  ]);

  useEffect(() => {
    if (incomingResponseData) {
      setSortedData(incomingResponseData);
    }
  }, [incomingResponseData]);

  const tableCols = useMemo(
    () =>
      selectedCountry === "united kingdom"
        ? getColumnsUK(dateFormat, decimalFormat, selectedCountry)
        : getColumnsPoland(dateFormat, decimalFormat, selectedCountry),
    [dateFormat, decimalFormat, selectedCountry],
  );

  const [columns, setColumns] = useState(tableCols);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols, dateFormat]);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const uniqYears = useMemo(() => {
    const years = sortedData?.map((item) => {
      return (
        item.feedstockIssueDatez &&
        new Date(moment(item.feedstockIssueDatez))?.getFullYear()
      );
    });
    const uniqueYears = [
      ...new Set(
        years?.filter(
          (year) => year !== undefined && !Number.isNaN(year) && year !== "",
        ),
      ),
    ];
    return uniqueYears?.sort((a, b) => b - a);
  }, [sortedData]);

  const handleFilterSubmit = (data) => {
    setRowSelection({});
    if (data) {
      setSupplierName(data.supplierName);
      setReceivingPointName(data.recipientReceiptAddress);
      setProductName(data.productType);
      setStatusName(data.state);
      setOriginName(data.countryCode);
      const convertedObjects = Object.entries(data)
        .filter(([, value]) => value !== undefined && value !== "")
        .map(([key, value]) => ({id: key, value}));

      setColumnFilter(convertedObjects);
    } else {
      setSupplierName(undefined);
      setReceivingPointName(undefined);
      setProductName(undefined);
      setStatusName(undefined);
      setOriginName(undefined);
    }
  };

  const [pollingTracker, {data: trackerData, stopPolling, called}] =
    useLazyQuery(DOC_STATUS_TRACKER, {
      pollInterval: POLLING_INTERVAL_IN_SECONDS,
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    const handleTracking = async () => {
      if (trackerData && trackerData.bioLcUploadIncomingDocStatusTrackerApi) {
        const {statusCode} = trackerData.bioLcUploadIncomingDocStatusTrackerApi;
        switch (statusCode) {
          case 400: // Duplicate document
            setIsUploading(false);
            setIsDuplicate(true);
            setErrorModalVisible(true);
            setIsStillUploading(false);
            stopPolling();
            break;
          case 500: // Textract failed
            setIsUploading(false);
            setErrorModalVisible(true);
            setIsStillUploading(false);
            stopPolling();
            break;
          case 201: // Successfully parsed
            setIsUploading(false);
            setIsUploadSuccessful(true);
            setIsStillUploading(false);
            stopPolling();
            refetch();

            break;
          case 202: // still uploading
            setIsUploading(false);
            setErrorModalVisible(false);
            setIsStillUploading(true);

            break;
          default:
            setIsUploading(false);
            setErrorModalVisible(true);
            setIsStillUploading(false);
            stopPolling();
            break;
        }
      }
    };

    handleTracking();
  }, [trackerData, stopPolling, refetch]);

  useEffect(() => {
    let pollingTimeout;
    if (called) {
      pollingTimeout = setTimeout(() => {
        // After polling 5 times, if the result is still in progress, then terminate the polling manually.
        if (
          trackerData?.bioLcUploadIncomingDocStatusTrackerApi?.statusCode ===
          202
        ) {
          setIsUploading(false);
          setErrorModalVisible(false);
          // setIsStillUploading(true);
          stopPolling();
        }
      }, 0);
    }
    return () => clearTimeout(pollingTimeout);
  }, [
    called,
    stopPolling,
    trackerData?.bioLcUploadIncomingDocStatusTrackerApi?.statusCode,
  ]);

  const handleUpload = async () => {
    if (uploadFileData !== null && uploadFileData !== undefined) {
      setPageIndex(0);
      setIsUploading(true);
      setShowResponse(true);
      try {
        const uploadStatusCode = await uploadFile(
          uploadFileData,
          siteReferenceId,
          selectedDivision,
        );
        if (uploadStatusCode === 200) {
          // First polling will start after 30 seconds of uploading the PDF.
          setTimeout(async () => {
            pollingTracker({
              variables: {
                docName: uploadFileData.name,
                siteReferenceId,
                divisionId: selectedDivision,
              },
            });
          }, POLLING_FIRST_TRIGGER_IN_SECONDS);
        } else {
          setIsUploading(false);
          setErrorModalVisible(true);
        }
      } catch (err) {
        console.error(err);
        // Unexpected error
      }
    }
    return false;
  };

  const handleExportSelected = async (selectedRows) => {
    const selectedRecords = Object.keys(selectedRows)
      .map((index) => Number(index))
      .map((index) => sortedData[index]);

    const selectedIds = selectedRecords.map(
      ({certificateInboundEuId}) => certificateInboundEuId,
    );

    const response = await ExportSelectedRowsAPI({
      divisionId,
      siteReferenceId,
      certificateInboundEuIds: selectedIds,
    });

    if (
      response?.data?.bioLcGetIncomingDocsExportApi &&
      response?.data?.bioLcGetIncomingDocsExportApi?.statusCode === 200
    ) {
      downloadFile(response?.data?.bioLcGetIncomingDocsExportApi?.downloadUrl);
    } else {
      toast.error("Something went wrong, please try later.");
    }
  };

  const handleAnnualExport = async (year) => {
    const response = await ExportAnnualAPI({
      divisionId,
      siteReferenceId,
      year,
    });
    if (
      response?.data?.bioLcGetIncomingDocsExportYearApi &&
      response?.data?.bioLcGetIncomingDocsExportYearApi?.statusCode === 200
    ) {
      downloadFile(
        response?.data?.bioLcGetIncomingDocsExportYearApi?.downloadUrl,
      );
    } else {
      toast.error("Something went wrong, please try later.");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-stretch w-full items-start ">
        <div className=" flex flex-row justify-end w-full my-5 gap-x-3 items-center">
          <div>
            <NotificationBar
              siteReferenceId={siteReferenceId}
              divisionId={divisionId}
              selectedCountry={selectedCountry}
            />
          </div>
          <div>
            <Button
              color="primary"
              type="secondary"
              className="float-right rounded-0 text-gray-400"
              id="addDocumentButton"
              size="md"
              onClick={() => {
                setUploadModal(!showUploadModal);
              }}
            >
              Add document
              <Add24 className="btn-icon-suffix" />
            </Button>
          </div>
        </div>
      </div>

      <div className="flex flex-col justify-stretch w-full items-start ">
        <div className=" flex flex-row justify-between w-full px-4 py-5 bg-white">
          <div className="flex flex-none flex-row items-center ">
            <div className="mr-2">
              <CustomizeTable
                columns={columns}
                onColumnChange={(cols) => setColumns(cols)}
                showApplyButton={false}
              />
            </div>
            <div>
              <UncontrolledDropdown>
                <DropdownToggle color="standard-tertiary rounded-0" caret>
                  Export
                  <Download24 className="btn-icon-suffix" />
                </DropdownToggle>
                <DropdownMenu tag="ul">
                  <li>
                    <DropdownItem
                      href="#"
                      onClick={async () => handleExportSelected(rowSelection)}
                      disabled={Object.keys(rowSelection)?.length === 0}
                    >{`Export selected (${
                      Object.keys(rowSelection)?.length
                    })`}</DropdownItem>
                  </li>

                  <UncontrolledDropdown direction="end" tag="li">
                    <DropdownToggle
                      tag="button"
                      caret
                      className="dropdown-item"
                    >
                      Export annual data
                      <div className="dropdown-suffix x5-me-n1">
                        <TriangleRightSolid24 />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu>
                      {uniqYears?.map((year) => (
                        <DropdownItem
                          onClick={async () => handleAnnualExport(year)}
                        >
                          <Download24 className="btn-icon-prefix" />
                          <span className="">{year}</span>
                        </DropdownItem>
                      ))}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>
          <div className="flex flex-none flex-row items-center ">
            <div className="mr-2">
              <Button
                data-test="doc-manager-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filter
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
            <div>
              <InputGroup className="input-group-merge" size="">
                <Input
                  className="form-control-appended"
                  placeholder="Search"
                  value={filtering}
                  onChange={(e) => {
                    setFiltering(e.target.value);
                    setPageIndex(0);
                  }}
                />
                <div className="input-group-append">
                  <InputGroupText>
                    {filtering ? (
                      <Remove24
                        className="cursor-pointer"
                        onClick={() => {
                          setFiltering("");
                          setPageIndex(0);
                        }}
                      />
                    ) : (
                      <SvgSearch24 />
                    )}
                  </InputGroupText>
                </div>
              </InputGroup>
            </div>
          </div>
        </div>

        {showFilter && (
          <div className="flex flex-col justify-stretch w-full items-start px-7 border-secondary border-top">
            <div className=" flex flex-row w-full my-6">
              <DocManagerFilter
                supplierList={supplierList}
                receivingPointList={receivingPointList}
                productList={productList}
                statusList={statusList}
                originList={originList}
                supplierName={supplierName}
                productName={productName}
                receivingPointName={receivingPointName}
                statusName={statusName}
                originName={originName}
                setColumnFilter={setColumnFilter}
                setShowFilter={setShowFilter}
                onSubmit={handleFilterSubmit}
                selectedCountry={selectedCountry}
                setPageIndex={setPageIndex}
              />
            </div>
          </div>
        )}

        <div className="border-secondary border-top w-full pb-40 min-h-[500px]">
          <DocumentTable
            columns={filteredColumns}
            data={sortedData ?? []}
            className="incoming-docs-table-docmanager"
            highlightedRows={[]}
            loading={loading}
            filtering={filtering?.trim()}
            setFiltering={setFiltering}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            type="Incoming"
            enableRowSelection
            rowSelection={rowSelection}
            setRowSelection={setRowSelection}
            pageIndex={pageIndex}
          />
          {showUploadModal && (
            <UploadDocuments
              showUploadModal={showUploadModal}
              setUploadModal={setUploadModal}
              setUploadFile={setUploadFileData}
              setShowPreview={setShowPreview}
              handleUpload={handleUpload}
              divisionData={divisionDataList}
              setSelectedDivision={setSelectedDivision}
              selectedDivision={selectedDivision}
            />
          )}

          {showPreview && (
            <PreviewDocumentModal
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              uploadFile={uploadFileData}
              setIsUploading={setIsUploading}
              handleUpload={handleUpload}
              setShowResponse={setShowResponse}
            />
          )}

          {showResponse && (
            <ResponseModal
              showResponse={showResponse}
              setShowResponse={setShowResponse}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              isErrorModalVisible={isErrorModalVisible}
              setErrorModalVisible={setErrorModalVisible}
              setUploadModal={setUploadModal}
              bodyClassNames={
                isErrorModalVisible || isUploadSuccessful || isStillUploading
                  ? "h-40 pt-0"
                  : "h-48"
              }
              isUploadSuccessful={isUploadSuccessful}
              setIsUploadSuccessful={setIsUploadSuccessful}
              isDuplicate={isDuplicate}
              setIsDuplicate={setIsDuplicate}
              isStillUploading={isStillUploading}
              setIsStillUploading={setIsStillUploading}
            />
          )}
        </div>
      </div>
    </>
  );
};

IncomingDocumentComponent.propTypes = {};

export default IncomingDocumentComponent;
