import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {FormGroup, Label, Input, FormFeedback} from "reactstrap";
import {useFormContext} from "react-hook-form";

import siteRefId from "modules/DocManager/common";

import Datepicker from "./Datepicker/index";

const SupplierAndRecipientInfo = ({
  status,
  isDisabled,
  siteReferenceId,
  defaultValues,
}) => {
  const {recipientReceiptAddress, supplierDispatchAddress} = defaultValues;

  const [supplierDisabled, setSupplierDisabled] = useState(false);
  const [receiverDisabled, setReceiverDisabled] = useState(false);

  const {
    register,
    formState: {errors},
    control,
    watch,
    setValue,
    getValues,
    trigger,
    defaultValues: defaultValuesNew,
  } = useFormContext();

  const watchRec = watch("recipientReceiptAddressSameFlag");
  const watchSup = watch("supplierDispatchAddressSameFlag");

  useEffect(() => {
    if (watchRec) {
      setValue("recipientReceiptAddress", getValues("recipientAddress"));
      setReceiverDisabled(true);
    } else {
      setValue("recipientReceiptAddress", recipientReceiptAddress);
      setReceiverDisabled(false);
    }

    if (watchSup) {
      setValue("supplierDispatchAddress", getValues("supplierAddress"));
      setSupplierDisabled(true);
    } else {
      setValue("supplierDispatchAddress", supplierDispatchAddress);
      setSupplierDisabled(false);
    }
    trigger("supplierDispatchAddress");
    trigger("recipientReceiptAddress");
  }, [
    setValue,
    watchRec,
    watchSup,
    getValues,
    trigger,
    recipientReceiptAddress,
    supplierDispatchAddress,
  ]);

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };
  return (
    <div className="flex flex-col gap-5 w-full">
      <FormGroup>
        <Label for="supplierName" className="fw-normal mb-4">
          Name of supplier
        </Label>
        <Input
          type="text"
          id="supplierName"
          {...computeProps("supplierName", {
            required: "Please enter name of supplier",
          })}
          data-test="supplierName"
          invalid={!!errors.supplierName && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.supplierName && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.supplierName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="supplierAddress" className="fw-normal mb-4">
          Address of supplier
        </Label>
        <Input
          type="text"
          id="supplierAddress"
          {...computeProps("supplierAddress")}
          data-test="supplierAddress"
          invalid={!!errors.supplierAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
        />
        {errors.supplierAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.supplierAddress?.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="certificationSystem" className="fw-normal mb-4">
          Certification system
        </Label>
        <Input
          type="select"
          id="certificationSystem"
          data-test="certificationSystem"
          {...computeProps("certificationSystem", {
            required: "Please select a certification system",
          })}
          invalid={!!errors.certificationSystem && !isDisabled}
          disabled={isDisabled}
        >
          {siteReferenceId === siteRefId.pl && (
            <>
              <option value="Red Cert">Red Cert</option>
              <option value="KZR INiG">KZR INiG </option>
              <option value="ISCC">ISCC</option>
            </>
          )}
          {siteReferenceId === siteRefId.uk && (
            <>
              <option value="">Please select option</option>
              <option value="ISCC EU">ISCC EU</option>
            </>
          )}
        </Input>
        {errors.certificationSystem && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.certificationSystem?.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup>
        <Label for="certificateNumber" className="fw-normal mb-4">
          Certificate number
        </Label>
        <Input
          type="text"
          id="certificateNumber"
          data-test="certificateNumber"
          {...computeProps("certificateNumber")}
          invalid={!!errors.certificateNumber && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {!isDisabled &&
          (errors.certificateNumber ? (
            <FormFeedback className="mt-2">
              {errors.certificateNumber.message}
            </FormFeedback>
          ) : (
            <span className="text-xs text-gray-700">Valid certificate</span>
          ))}
      </FormGroup>
      <FormGroup>
        <Label for="recipientName" className="fw-normal mb-4">
          Name of recipient
        </Label>
        <Input
          type="text"
          id="recipientName"
          data-test="recipientName"
          {...computeProps("recipientName")}
          invalid={!!errors.recipientName && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.recipientName && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.recipientName.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="recipientAddress" className="fw-normal mb-4">
          Address of recipient
        </Label>
        <Input
          type="text"
          id="recipientAddress"
          data-test="recipientAddress"
          {...computeProps("recipientAddress")}
          invalid={!!errors.recipientAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled}
        />
        {errors.recipientAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.recipientAddress.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="contractNumber" className="fw-normal mb-4">
          Contract number
        </Label>
        <Input
          type="text"
          id="contractNumber"
          data-test="contractNumber"
          {...computeProps("contractNumber")}
          invalid={!!errors.contractNumber && !isDisabled}
          maxLength={50}
          disabled={isDisabled}
        />
        {errors.contractNumber && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.contractNumber.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup>
        <Label for="supplierDispatchAddress" className="fw-normal mb-4">
          Address of dispatch
        </Label>
        <Input
          type="text"
          id="supplierDispatchAddress"
          data-test="supplierDispatchAddress"
          {...computeProps("supplierDispatchAddress", {
            required: "Please enter address of dispatch",
          })}
          invalid={!!errors.supplierDispatchAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled || supplierDisabled}
        />
        {errors.supplierDispatchAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.supplierDispatchAddress.message}
          </FormFeedback>
        )}
      </FormGroup>
      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("supplierDispatchAddressSameFlag")}
          type="checkbox"
          id="supplierDispatchAddressSameFlag"
          data-test="supplierDispatchAddressSameFlag"
          disabled={isDisabled}
        />

        <Label for="supplierDispatchAddressSameFlag">
          Same as address of supplier
        </Label>
      </FormGroup>
      <FormGroup>
        <Label for="recipientReceiptAddress" className="fw-normal mb-4">
          Address of receipt
        </Label>
        <Input
          type="text"
          id="recipientReceiptAddress"
          data-test="recipientReceiptAddress"
          {...computeProps("recipientReceiptAddress", {
            required:
              "Please enter address of receipt/receiving point of the sustainable material",
          })}
          invalid={!!errors.recipientReceiptAddress && !isDisabled}
          maxLength={100}
          disabled={isDisabled || receiverDisabled}
        />
        {errors.recipientReceiptAddress && !isDisabled && (
          <FormFeedback className="mt-2">
            {errors.recipientReceiptAddress.message}
          </FormFeedback>
        )}
      </FormGroup>

      <FormGroup check inline className="form-check-alt form-check-lg !mb-0">
        <Input
          {...computeProps("recipientReceiptAddressSameFlag")}
          type="checkbox"
          id="recipientReceiptAddressSameFlag"
          data-test="recipientReceiptAddressSameFlag"
          disabled={isDisabled}
        />

        <Label for="recipientReceiptAddressSameFlag">
          Same as address of recipient
        </Label>
      </FormGroup>
      <FormGroup>
        <Label for="materialDispatchDatez" className="fw-normal mb-4">
          Date of dispatch
        </Label>
        <Datepicker
          id="materialDispatchDatez"
          name="materialDispatchDatez"
          control={control}
          error={errors.materialDispatchDatez}
          rules={{
            required: "Please enter date of dispatch",
          }}
          format="d/m/Y"
          disabled={isDisabled}
        />
      </FormGroup>
    </div>
  );
};
SupplierAndRecipientInfo.propTypes = {
  status: PropTypes.string,
  siteReferenceId: PropTypes.string,
  isDisabled: PropTypes.bool,
  defaultValues: PropTypes.object,
};
export default SupplierAndRecipientInfo;
