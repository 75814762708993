const shipmentDataFormatter = (inputData) => {
  return inputData?.parts?.map((part) => {
    return {
      total_estimated_volume: inputData.total_estimated_volume,
      shipment_date: inputData.shipment_date,
      batch_id: part.batch_id,
      total_actual_volume: part.total_actual_volume,
      shipment_type: part.batch_shipment_type || inputData.shipment_type,
      shipment_id: part.allocated_shipment_id || inputData.shipment_id,
      allocated_shipment_id: part.allocated_shipment_id,
      sub_type: part.sub_type,
      load_start_time: inputData.shipment_start_date,
      load_end_time: inputData.shipment_end_date,
      destination: inputData.destination,
      credits_qualified: part.credits_qualified,
      renewable_shipment_flag: part.renewable_shipment_flag,
      source_tank: inputData.source_tank,
      notes: part.notes,
      batch_allocation_id: part.batch_allocation_id,
      parcel_grade: inputData.parcel_grade,
      total_allocated_volume: part.total_allocated_volume,
    };
  });
};

export default shipmentDataFormatter;
