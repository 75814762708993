import React, {Fragment, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {useQuery} from "@apollo/client";
import {InputGroup, Input, FormGroup} from "reactstrap";
import {useFormContext} from "react-hook-form";
import {MATERIAL_MASTER} from "graphql/config-center/MaterialMaster";
import SpinnerLoading from "modules/common/SpinnerLoading";
import "./index.css";
import {INITIAL, CURRENCY} from "./DefaultHeaders";

const CostPerFeedstock = ({
  perFeedStockData,
  updateSaveButton,
  paramsEditable,
}) => {
  const {loading: materialMasterLoading} = useQuery(MATERIAL_MASTER);
  const {
    register,
    formState: {errors},
    getValues,
    setValue,
  } = useFormContext();

  const numberInputPattern = {
    pattern: {
      value: /^(\d{1,10}(\.\d{1,5})?)$/,
      message:
        "Value must be number with (max 10 digits before and 5 digits after decimal point)",
    },
  };
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const dynamicColumnHeaders = [];
  const dynamicRowHeaders = [];

  perFeedStockData.forEach((item) => {
    if (!dynamicColumnHeaders.includes(item.parameterLabel)) {
      dynamicColumnHeaders.push(item.parameterLabel);
    }
    if (!dynamicRowHeaders.includes(item.materialShortName)) {
      dynamicRowHeaders.push(item.materialShortName);
    }
  });

  const getStoredData = (key) => {
    const storedValues = getValues();
    return storedValues[key];
  };

  const formatKey = (key) => {
    return key.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
  };

  const getItemKey = (rowHeader, columnHeader, initial) => {
    const keySuffix = initial ? `-${INITIAL}` : "";
    return `${formatKey(rowHeader)}-${columnHeader.toLowerCase()}${keySuffix}`;
  };

  const handleInputChange = (key, val) => {
    setValue(key, val);
    updateSaveButton();
  };

  const [originalDataMap, setOriginalDataMap] = useState({});

  useEffect(() => {
    const dataMap = perFeedStockData.reduce((acc, item) => {
      const key = `${formatKey(
        item.materialShortName,
      )}-${item.parameterLabel.toLowerCase()}`;
      const value = getValues(key);
      acc[key] = value;
      return acc;
    }, {});
    setOriginalDataMap(dataMap);
  }, [perFeedStockData, getValues]);

  const handleBlur = (key, val) => {
    originalDataMap[key] = val;
    setValue(key, val);
    return val;
  };

  const handleFocus = (key) => {
    return originalDataMap[key];
  };

  return (
    <div className=" flex flex-col w-full">
      {materialMasterLoading ? (
        <SpinnerLoading />
      ) : (
        <div className="flex flex-col w-full">
          <div className="flex flex-col w-full heading-background">
            <span className="flex w-full text-background">Per feedstock</span>
          </div>
          <hr className="border-1 margin-for-border-line header-bottom-line" />
          <div className="grid grid-cols-5 w-full">
            <div className="text-background">
              <span>Feedstock</span>
            </div>
            {dynamicColumnHeaders.map((key, index) => (
              <Fragment key={key}>
                <div className="text-background">
                  <span>{key}</span>
                </div>
              </Fragment>
            ))}
          </div>
          <hr className="border-1 margin-for-border-line" />
          {dynamicRowHeaders.map((rowHeader, rowIndex) => (
            <div
              className=" grid grid-cols-5 background-for-input-box"
              key={rowHeader}
            >
              <div className="materialid-textheading">{rowHeader}</div>
              {dynamicColumnHeaders.map((columnHeader, columnIndex) => (
                <FormGroup key={getItemKey(rowHeader, columnHeader)}>
                  <InputGroup className="text-box-background">
                    <Input
                      type="number"
                      className="text-box-input-value"
                      readOnly={!paramsEditable}
                      id={getItemKey(rowHeader, columnHeader)}
                      data-test={getItemKey(rowHeader, columnHeader)}
                      {...computeProps(
                        getItemKey(rowHeader, columnHeader, true),
                        numberInputPattern,
                      )}
                      onBlur={(e) => {
                        e.target.value = handleBlur(
                          getItemKey(rowHeader, columnHeader, false),
                          e.target.value,
                        );
                      }}
                      onFocus={(e) => {
                        e.target.value = handleFocus(
                          getItemKey(rowHeader, columnHeader, false),
                        );
                      }}
                      onChange={(e) => {
                        handleInputChange(
                          getItemKey(rowHeader, columnHeader, false),
                          e.target.value,
                        );
                      }}
                    />
                    <div className="border addon-textbox-background">
                      <div className="addon-text-value">
                        {getStoredData(
                          `${getItemKey(
                            rowHeader,
                            columnHeader,
                            false,
                          )}-${CURRENCY}`,
                        )}
                      </div>
                    </div>
                  </InputGroup>
                </FormGroup>
              ))}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

CostPerFeedstock.propTypes = {
  perFeedStockData: PropTypes.array.isRequired,
  updateSaveButton: PropTypes.func,
  paramsEditable: PropTypes.bool.isRequired,
};

export default CostPerFeedstock;
