import Logo from "assets/images/logo-horizontal.svg";
import "./index.scss";
import {Link, useNavigate} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";
import {useMsal, useIsAuthenticated} from "@azure/msal-react";
import PropTypes from "prop-types";
import {Hamburger24} from "@bphxd/ds-core-react/lib/icons";
import MenuSvg from "assets/images/svg/drawer-icon.svg";
import {useCallback, useMemo, useEffect, useState} from "react";
import {DropdownItem} from "reactstrap";
import graphAPICommonMethods from "utils/helpers/graphAPICommonMethods";

import SettingsModal from "../StatusModals/SettingsModalNew";
import {Profile} from "./Profile";

const Header = ({onMenuClick, onLinkClick, className, onSideMenuItemClick}) => {
  const [showSettings, setShowSettings] = useState(false);

  const {appSetting, setAppSetting} = useAppSetting();

  const {instance, accounts} = useMsal();
  const navigate = useNavigate();
  let isAuthenticated = false;
  isAuthenticated = useIsAuthenticated();

  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    async function fetchGraphAccessToken() {
      const accessTokenGraph = await graphAPICommonMethods.getMSGraphAPIToken();
      const profilePhotoURL =
        await graphAPICommonMethods.getProfilePhoto(accessTokenGraph);
      setImageUrl(profilePhotoURL);
    }
    fetchGraphAccessToken();
  }, []);

  const handleLogout = useCallback(() => {
    const logoutRequest = {
      account: instance.getAccountByHomeId(accounts[0].homeAccountId),
    };
    instance.logoutRedirect(logoutRequest);
    navigate("/");
  }, [accounts, instance, navigate]);

  const userInitials = useMemo(
    () =>
      isAuthenticated
        ? accounts[0]?.name
            .toUpperCase()
            .split(",")
            .reverse()
            .map((str) => str.trim()[0])
            .join("")
        : "U",
    [accounts, isAuthenticated],
  );

  const userDetails = useMemo(() => accounts[0], [accounts]);

  return (
    <div className={["bp-header", className].join(" ")}>
      <NavBarIcon onMenuClick={onMenuClick} />
      {showSettings && (
        <SettingsModal
          title="Settings"
          visible={showSettings}
          onCancel={() => {
            setShowSettings(false);
          }}
        />
      )}
      <div className="bp-header-container">
        <Link
          to="/"
          data-test="home-button"
          onClick={() => {
            onLinkClick();
            onSideMenuItemClick("");
            const countryFeatureData = appSetting?.featureMappingData?.find(
              (item) => item.countryName === "GLOBAL",
            );

            setAppSetting({
              ...appSetting,
              currentCountry: "GLOBAL",
              currentCountryMappingData: countryFeatureData,
              currentModule: "",
              currentModuleTitle: "",
            });
          }}
        >
          <img src={Logo} className="bp-logo" alt="BP Logo" />
        </Link>
        <Profile
          isAuthenticated
          userInitials={userInitials}
          photoSrc={imageUrl}
          userDetails={userDetails}
          setShowSettings={setShowSettings}
          handleLogout={handleLogout}
        />
      </div>
    </div>
  );
};

const NavBarIcon = ({onMenuClick}) => {
  return (
    <button
      type="button"
      className="bp-navbar-icon hover:bg-[#fafafa] hover:text-black"
      onClick={onMenuClick}
      data-test="menu-button"
    >
      <Hamburger24 />
    </button>
  );
};

const DropdownOption = ({icon: Icon, label, onClick}) => (
  <DropdownItem onClick={onClick}>
    <Icon />
    <span className="pl-3.5">{label}</span>
  </DropdownItem>
);

DropdownOption.propTypes = {
  icon: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func,
};

Header.propTypes = {
  onMenuClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  onSideMenuItemClick: PropTypes.func,
  className: PropTypes.string,
};

NavBarIcon.propTypes = {
  onMenuClick: PropTypes.func,
};

export default Header;
