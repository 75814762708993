export const HEADER_COPRO = "CoProcessing";
export const COPRO_URL = "/co-processing";
export const KEYS_FOR_REVIEW = "Key points for review";
export const SUCCESS_SAVE = "Successful! Your changes were saved!";

export const BREADCRUMBS = {
  BIOVERSE: "Bioverse",
  COPRO: "Co-processing",
  UNITED_STATES: "United States",
};

export const NAVIGATION = {
  CO_PROCESSING: "CoProcessing",
  RECEIPTS: "Receipts",
};

export const PAGE_TITLE = {
  RECEIPTS: "Receipts",
  DOCUMENT_VIEWER: "Document Viewer",
  BATCHES: "Batches",
  BATCH_DETAILS: "Batch details",
  DETAILS: "Details",
  MISSING_INFO: "Missing expected information",
  SHIPMENTS: "Shipments",
  SHIPMENT_DETAILS: "Shipment Details",
  CREDIT_GENERATION: "Credit Generation",
  HISTORICAL: "Historical",
  BULK_ADJUSTMENT: "Bulk adjustment",
};

export const ERRORS = {
  REVIEW_ERRORS: "Review",
  IMPORT_ERRORS: "import errors",
  FAILED_LOAD: "Error: Failed to load details",
  FAILED_UPDATE: "Error: Failed to update values. Please try to submit again",
  FAILED_PDF:
    "PDF cannot be displayed - please check the email from the supplier for the original document",
  FAILED_EDIT: "Error: Failed to edit shipment",
  FAILED_REMOVE: "Error: Failed to remove split",
};

export const PAGE_SUBTITLE = {
  MISSING_INFO:
    "Based on this document, we have been unable to gather some expected information.",
};

export const BUTTONS = {
  ALL_SUPPLIERS: "All Suppliers",
  FILTER: "Filter",
  EXPORT: "Export",
  SCHEDULE: "Schedule",
  SAVE: "Accept",
  CANCEL: "Cancel",
  CONTINUE: "Continue",
  VIEW_HISTORICAL: "View historical",
  SAVE_AND_REFRESH: "Save & refresh",
  SUBMIT_ADJUSTMENT: "Submit adjustment",
};

export const DATE = {
  TIMEZONE: "America/Los_Angeles",
  ISO_FORMAT: "yyyy-MM-dd'T'HH:mm:ssXXX",
  DATE_FORMAT: "MM/dd/yyyy hh:mm a",
  DATE_FORMAT_SHORT: "MM/dd/yyyy",
  TIME_FORMAT: "hh:mm a",
};

export const PI_Vision_URL = process.env.REACT_APP_PI_VISION_URL;
export const ORION_URL = process.env.REACT_APP_ORION_URL;
