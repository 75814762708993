import {Form, Button, Spinner} from "reactstrap";
import React, {useState, useMemo, useEffect, useCallback} from "react";
import {isEmpty, orderBy} from "lodash";
import moment from "moment";
import {useForm, FormProvider} from "react-hook-form";
import {useQuery} from "@apollo/client";
import {useUserSettings} from "providers/userSettings";
import {DIV_CODE_COPRO} from "constants/divisionDetails";
import {useLocation, useParams} from "react-router-dom";
import {
  COPRO_EU_FIFO_FILTER,
  COPRO_EU_FIFO_ALL_RECORDS,
  COPRO_EU_FIFO_ALL_RECORDS_AGGREGATED_VIEW,
} from "graphql/es-co-processing/fifo/feedstock";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {useAppSetting} from "providers/appSetting/context.js";
import {downloadExportApi} from "graphql/es-co-processing/fifo/downloadtoexcelhelper";
import {Download24} from "@bphxd/ds-core-react/lib/icons";
import Layout from "../components/Layout";
import FeedstockFilterContent from "../components/FeedstockFilterContent";
import ReportSettingsDetail from "../components/ReportSettingsDetail";
import {
  META_COLUMNS,
  OPENING_COLUMNS,
  INCOMING_COLUMNS,
  OUTGOING_COLUMNS,
  CLOSING_COLUMNS,
} from "../components/Table/Column.js";
import {
  AGGREGATED_COLUMNS,
  OPENING_COLUMNS_AGGREGATED,
  INCOMING_COLUMNS_AGGREGATED,
  OUTGOING_COLUMNS_AGGREGATED,
  CLOSING_COLUMNS_AGGREGATED,
} from "../components/Table/AggregatedColumns.js";
import SplitTable from "../components/Table/index.js";
import SplitTableAggregated from "../components/Table/SplitTableAggregated";
import "./index.css";

const seperationText = "#-#";

const FeedstockLanding = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;

  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_COPRO);

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();

  const defaultPageSize = 10;

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: defaultPageSize,
  });

  const handlePaginationChange = ({pageIndex, pageSize}) => {
    setPagination({pageIndex, pageSize});
  };

  const methods = useForm({
    mode: "onBlur",
  });

  const {getValues, setValue, setError, trigger} = methods;

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "Spain", link: "/"},
    {text: "Co-processing", link: "/copro-spain/spain"},
    {text: "Feedstock FIFO", link: "/copro-spain/spain"},
  ];

  const {data: filterData} = useQuery(COPRO_EU_FIFO_FILTER, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
  });

  const location = useLocation();
  const {costCalcObject} = location.state || {};

  const getFilterName = (...inputStrings) => {
    const filterName = inputStrings
      .map((str) => (str ? str.replace(/[ .]/g, "") : "null"))
      .join(seperationText);
    return filterName.trim();
  };

  const [defaultValues, setFilterDefaultValues] = useState({
    location: null,
    periodYear: null,
    periodMonth: null,
    materialView: "sap_code",
    uom: "t",
    feedstock: null,
    viewType: "aggregated",
  });

  const [currentFilterValues, setCurrentFilterValues] = useState(defaultValues);

  const feedstockDetails =
    currentFilterValues?.feedstock?.split(seperationText);
  const locationDetails = currentFilterValues?.location?.split(seperationText);

  const firstSelectionItem = useMemo(() => {
    const sortedItems = orderBy(
      filterData?.bioLcCoproFIFODynamicFilter?.data ?? [],
      ["fifoYear", "fifoMonth", "locationName"],
      ["desc", "desc", "asc"],
    );
    return sortedItems && sortedItems[0];
  }, [filterData]);

  useEffect(() => {
    if (!isEmpty(firstSelectionItem)) {
      setFilterDefaultValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          location: `${firstSelectionItem?.locationId}${seperationText}${firstSelectionItem?.locationName}`,
          periodYear: firstSelectionItem?.fifoYear,
          periodMonth: firstSelectionItem?.fifoMonth,
        };
      });

      setCurrentFilterValues((currentFilterValuesItem) => {
        return {
          ...currentFilterValuesItem,
          location: `${firstSelectionItem?.locationId}${seperationText}${firstSelectionItem?.locationName}`,
          periodYear: firstSelectionItem?.fifoYear,
          periodMonth: firstSelectionItem?.fifoMonth,
        };
      });
    }
  }, [firstSelectionItem]);

  useEffect(() => {
    if (costCalcObject) {
      setCurrentFilterValues((currentFilterValuesItem) => {
        return {
          ...currentFilterValuesItem,
          location: getFilterName(
            costCalcObject?.data?.locationId,
            costCalcObject?.data?.locationName,
          ),
          periodYear: costCalcObject?.costCalcYear || "",
          periodMonth: costCalcObject?.costCalcMonth || "",
          viewType: "detailed",
          feedstock: getFilterName(
            costCalcObject?.costCalcFeedstockMaterialId,
            costCalcObject?.data?.sapMaterialCode,
            costCalcObject?.data?.sapNCCode,
            costCalcObject?.costCalcFeedstockMaterialShortName,
          ),
        };
      });
    }
  }, [firstSelectionItem, costCalcObject]);

  const {
    data: allRecords,
    loading: allRecordsLoading,
    refetch: refetchAllRecords,
  } = useQuery(COPRO_EU_FIFO_ALL_RECORDS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",

    variables: {
      locationId: locationDetails && locationDetails[0],
      materialId: feedstockDetails && feedstockDetails[0],
      feedstockType: currentFilterValues?.materialView,
      fifoMonth: currentFilterValues?.periodMonth,
      fifoYear: currentFilterValues?.periodYear,
      uom: currentFilterValues?.uom,
      viewType: currentFilterValues?.viewType,
      sapMaterialCode:
        currentFilterValues?.materialView === "sap_code"
          ? feedstockDetails && feedstockDetails[1]
          : feedstockDetails && feedstockDetails[2], // remove this once we have aggregated view
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      currentFilterValues?.viewType === "aggregated" ||
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const {
    data: allRecordsAggregated,
    loading: allRecordsAggregatedLoading,
    refetch: refetchAllRecordsAggregated,
  } = useQuery(COPRO_EU_FIFO_ALL_RECORDS_AGGREGATED_VIEW, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      locationId: firstSelectionItem?.locationId,
      fifoMonth: firstSelectionItem?.fifoMonth,
      fifoYear: firstSelectionItem?.fifoYear,
      uom: currentFilterValues?.uom,
      viewType: currentFilterValues?.viewType,
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    },
    skip:
      currentFilterValues?.viewType === "detailed" ||
      isEmpty(firstSelectionItem) ||
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const validate = useCallback(async () => {
    await trigger();
    const {location, periodYear, periodMonth} = getValues();
    let isOthersValid = true;

    if (isEmpty(periodYear)) {
      setError("periodYear", {
        type: "value",
        message: "Please select year",
      });
      isOthersValid = false;
    }
    if (isEmpty(periodMonth)) {
      setError("periodMonth", {
        type: "value",
        message: "Please select month",
      });
      isOthersValid = false;
    }
    if (isEmpty(location)) {
      setError("location", {
        type: "value",
        message: "Please select location",
      });
      isOthersValid = false;
    }
    return isOthersValid;
  }, [getValues, setError, trigger]);

  const handleFilterSubmit = async () => {
    const {location, feedstock, periodYear, periodMonth} = getValues();
    const isValid = await validate();
    if (isValid) {
      setCurrentFilterValues({
        ...defaultValues,
        viewType: feedstock ? "detailed" : "aggregated",
        location,
        feedstock,
        periodYear,
        periodMonth,
      });

      const feedstockDetailsData = feedstock?.split(seperationText);
      const locationDetailsData = location?.split(seperationText);
      setPagination({
        pageIndex: 0,
        pageSize: defaultPageSize,
      });

      if (feedstock) {
        await refetchAllRecords({
          locationId: locationDetailsData && locationDetailsData[0],
          materialId: feedstockDetailsData && feedstockDetailsData[0],
          feedstockType: defaultValues?.materialView,
          fifoMonth: periodMonth,
          fifoYear: periodYear,
          uom: defaultValues?.uom,
          viewType: feedstock ? "detailed" : "aggregated",
          sapMaterialCode:
            defaultValues?.materialView === "sap_code"
              ? feedstockDetailsData && feedstockDetailsData[1]
              : feedstockDetailsData && feedstockDetailsData[2],
          siteReferenceId: siteReferenceData?.siteReferenceId,
          divisionId: divisionData?.divisionId,
        });
      } else {
        await refetchAllRecordsAggregated({
          locationId: locationDetailsData && locationDetailsData[0],
          fifoMonth: periodMonth,
          fifoYear: periodYear,
          uom: defaultValues?.uom,
          viewType: feedstock ? "detailed" : "aggregated",
          siteReferenceId: siteReferenceData?.siteReferenceId,
          divisionId: divisionData?.divisionId,
        });
      }
    }
  };

  const handleFilterClear = async () => {
    setValue("location", defaultValues?.location);
    setValue("periodMonth", defaultValues?.periodMonth);
    setValue("periodYear", defaultValues?.periodYear);
    setValue("feedstock", defaultValues?.feedstock);

    setCurrentFilterValues({
      ...defaultValues,
    });

    const locationDetailsItem = defaultValues?.location?.split(seperationText);
    setPagination({
      pageIndex: 0,
      pageSize: defaultPageSize,
    });

    await refetchAllRecordsAggregated({
      locationId: locationDetailsItem && locationDetailsItem[0],
      fifoMonth: defaultValues?.periodMonth,
      fifoYear: defaultValues?.periodYear,
      uom: defaultValues?.uom,
      viewType: "aggregated",
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
    });
  };

  const onReportSettingSave = async (settings) => {
    const {location, feedstock, periodYear, periodMonth} = getValues();

    setCurrentFilterValues({
      ...currentFilterValues,
      materialView: settings?.materialView,
      uom: settings?.uom,
    });

    const feedstockDetailsData = feedstock?.split(seperationText);
    const locationDetailsData = location?.split(seperationText);
    if (feedstock) {
      await refetchAllRecords({
        locationId: locationDetailsData && locationDetailsData[0],
        materialId: feedstockDetailsData && feedstockDetailsData[0],
        feedstockType: settings?.materialView,
        fifoMonth: periodMonth,
        fifoYear: periodYear,
        uom: settings?.uom,
        viewType: "detailed",
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        sapMaterialCode:
          defaultValues?.materialView === "sap_code"
            ? feedstockDetailsData && feedstockDetailsData[1]
            : feedstockDetailsData && feedstockDetailsData[2],
      });
    } else {
      await refetchAllRecordsAggregated({
        locationId: locationDetailsData && locationDetailsData[0],
        fifoMonth: periodMonth,
        fifoYear: periodYear,
        uom: settings?.uom,
        viewType: "aggregated",
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      });
    }
  };

  const columns = useMemo(
    () =>
      META_COLUMNS(dateFormat, decimalFormat, defaultValues?.uom).concat(
        OPENING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        INCOMING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        OUTGOING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        CLOSING_COLUMNS(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
      ),
    [
      dateFormat,
      decimalFormat,
      defaultValues?.uom,
      allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage,
    ],
  );
  const [isProcessing, setProcessing] = useState(false);

  const handleDownloadClick = async () => {
    const variables = {
      download: true,
      locationId: locationDetails && locationDetails[0],
      materialId: "",
      feedstockType: defaultValues?.materialView,
      fifoMonth: currentFilterValues?.periodMonth,
      fifoYear: currentFilterValues?.periodYear,
      uom: defaultValues?.uom,
      viewType: defaultValues?.viewType,
      sapMaterialCode:
        defaultValues?.materialView === "sap_code"
          ? feedstockDetails && feedstockDetails[1]
          : feedstockDetails && feedstockDetails[2],
    };
    setProcessing(true);
    const {data} = await downloadExportApi(variables);

    const downloadUrl = data?.bioLcCoproFIFODetailedView?.url;

    if (downloadUrl) {
      const fileNameMatch = downloadUrl.match(/\/([^/?]+)\?/);
      const fileName = fileNameMatch ? fileNameMatch[1] : "report.xlsx";
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.error("Download URL is undefined.");
    }
    setProcessing(false);
  };

  const columnsAggregated = useMemo(
    () =>
      AGGREGATED_COLUMNS(dateFormat, decimalFormat, defaultValues?.uom).concat(
        OPENING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        INCOMING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        OUTGOING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
        CLOSING_COLUMNS_AGGREGATED(
          decimalFormat,
          decimalFormat,
          defaultValues?.uom,
          allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage[0],
        ),
      ),
    [
      dateFormat,
      decimalFormat,
      defaultValues?.uom,
      allRecords?.bioLcCoproFIFODetailedView?.data?.weightedAverage,
    ],
  );

  const appliedFilters = useMemo(() => {
    const feedstockDetailsData =
      currentFilterValues.feedstock?.split(seperationText);
    const locationDetailsData =
      currentFilterValues.location?.split(seperationText);

    return {
      ...currentFilterValues,
      locationName: locationDetailsData && locationDetailsData[1],
      feedtstockName: feedstockDetailsData && feedstockDetailsData[3],
    };
  }, [currentFilterValues]);

  return (
    <Layout
      title="Feedstock FIFO"
      breadcrumbs={breadcrumbItems}
      settingsContent={
        <div className="flex px-7 pt-7">
          <ReportSettingsDetail
            onSave={onReportSettingSave}
            onCancel={{}}
            defaultValues={defaultValues}
            setFilterDefaultValues={setFilterDefaultValues}
          />
          <div>
            {currentFilterValues?.viewType === "aggregated" && (
              <Button
                data-test="copro-fifo-download-to-xlsx-button"
                id="downloadToxlsx"
                name="downloadToxlsx"
                color="standard-outline rounded-0"
                className="h-9 btn--download-report"
                onClick={handleDownloadClick}
              >
                {isProcessing ? (
                  <Spinner size="sm" className="btn-icon-prefix" />
                ) : (
                  <Download24 className="btn-icon-prefix" />
                )}
                Download report
              </Button>
            )}
          </div>
        </div>
      }
    >
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <FormProvider {...methods}>
          <Form id="fifo-feedstock-filter-form">
            <FeedstockFilterContent
              defaultValues={currentFilterValues}
              filterDynamicData={
                filterData?.bioLcCoproFIFODynamicFilter?.data ?? []
              }
              handleFilterSubmit={handleFilterSubmit}
              handleFilterClear={handleFilterClear}
            ></FeedstockFilterContent>
          </Form>
        </FormProvider>
      </div>
      <div className="flex flex-col  justify-stretch w-full items-start px-7 pt-7">
        <div className="heading-section pb-4">
          {`Currently showing ${
            appliedFilters.feedtstockName ?? "aggregated view"
          } at ${appliedFilters.locationName} for ${moment(
            appliedFilters.periodMonth,
            "MM",
          ).format("MMMM")} ${appliedFilters.periodYear}`}
        </div>
      </div>
      <div className="fifo-feedstock-table flex flex-col  justify-stretch w-full items-start px-7 py-2">
        {currentFilterValues?.viewType === "detailed" && (
          <SplitTable
            columns={columns}
            data={
              allRecords?.bioLcCoproFIFODetailedView?.data?.transactionData ??
              []
            }
            costCalcObjectData={costCalcObject?.data ?? []}
            loading={allRecordsLoading}
            onPaginationChange={handlePaginationChange}
            pagination={pagination}
            uom={defaultValues?.uom}
            // keyName={`fifo-feedstock-table-${defaultValues?.uom}`}
          />
        )}

        {currentFilterValues?.viewType === "aggregated" && (
          <SplitTableAggregated
            columns={columnsAggregated}
            data={
              allRecordsAggregated?.bioLcCoproFIFOAggregatedView?.data ?? []
            }
            loading={allRecordsAggregatedLoading}
            onPaginationChange={handlePaginationChange}
            pagination={pagination}
            uom={defaultValues?.uom}
            // keyName={`fifo-feedstock-table-${defaultValues?.uom}`}
          />
        )}
      </div>
    </Layout>
  );
};

export default FeedstockLanding;
