import {Breadcrumb, BreadcrumbItem} from "reactstrap";
import {Link, useLocation} from "react-router-dom";
import {useAppSetting} from "providers/appSetting";
import {
  PAGE_TITLE,
  BREADCRUMBS,
} from "modules/co-processing/constants/coProcessing";
import COUNTRY_CODES from "constants/appSettings";

const PAGE_TITLES = {
  shipments: PAGE_TITLE.SHIPMENTS,
  "document-viewer": PAGE_TITLE.DOCUMENT_VIEWER,
  batches: PAGE_TITLE.BATCHES,
  details: PAGE_TITLE.DETAILS,
  "credit-generation": PAGE_TITLE.CREDIT_GENERATION,
  "bulk-adjustment": PAGE_TITLE.BULK_ADJUSTMENT,
};

function buildBreadcrumbsForHistorical(pathname = "") {
  const [base, historical, view] = pathname.split("/").filter(Boolean);

  return [
    {
      title: BREADCRUMBS.BIOVERSE,
      to: "",
      isLast: false,
    },
    {
      title: BREADCRUMBS.UNITED_STATES,
      to: "",
      isLast: false,
    },
    {
      title: BREADCRUMBS.COPRO,
      to: `/${base}`,
      isLast: false,
    },
    {
      title: PAGE_TITLE[view?.toUpperCase()],
      to: `/${base}/${view}`,
      isLast: false,
    },
    {
      title: PAGE_TITLE.HISTORICAL,
      to: `/${base}/${historical}/${view}`,
      isLast: true,
    },
  ];
}

const generateBreadcrumbs = (pathname, id) => {
  const paths = pathname.split("/").filter((item) => !!item);
  const breadcrumbs = [
    {title: BREADCRUMBS.BIOVERSE, to: "", isLast: false},
    {title: BREADCRUMBS.UNITED_STATES, to: "/", isLast: false},
    {title: BREADCRUMBS.COPRO, to: "/co-processing", isLast: false},
  ];

  paths.forEach((path, index) => {
    const to = `/${paths.slice(0, index + 1).join("/")}`;
    const isLast = index === paths.length - 1;

    if (path === "co-processing") {
      if (paths.length === 1 || paths[1] === "document-viewer") {
        breadcrumbs.push({title: PAGE_TITLE.RECEIPTS, to, isLast: false});
      }
    } else {
      const title = PAGE_TITLES[path] || path;
      breadcrumbs.push({title, to, isLast});
    }
  });

  if (id) {
    breadcrumbs.push({title: id, to: "", isLast: true});
  }

  return breadcrumbs;
};

const CoproBreadcrumbs = () => {
  const {state, pathname} = useLocation();
  const id = state?.id || state?.batch_id || null;
  const {appSetting, setAppSetting} = useAppSetting();

  const getBreadcrumbBehavior = (title, to, isLast) => {
    if (isLast) {
      return title;
    }
    if (title === BREADCRUMBS.BIOVERSE) {
      return (
        <Link
          to="/"
          data-test="home-button"
          onClick={() => {
            const countryFeatureData = appSetting?.featureMappingData?.find(
              (item) => item.countryName === COUNTRY_CODES.GLOBAL,
            );

            setAppSetting({
              ...appSetting,
              currentCountry: COUNTRY_CODES.GLOBAL,
              currentCountryMappingData: countryFeatureData,
            });
          }}
        >
          {title}
        </Link>
      );
    }
    return <Link to={to}>{title}</Link>;
  };

  const breadcrumbs = pathname.includes("historical")
    ? buildBreadcrumbsForHistorical(pathname)
    : generateBreadcrumbs(pathname, id);

  return (
    <Breadcrumb>
      {breadcrumbs.map(({title, to, isLast}, index) => (
        <BreadcrumbItem
          key={title}
          data-test="copro-us-breadcrumb"
          active={index !== 0}
        >
          {getBreadcrumbBehavior(title, to, isLast)}
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default CoproBreadcrumbs;
