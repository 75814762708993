import {gql} from "@apollo/client";

export const SAF_GET_INCOMING_DOCUMENTS_ACTUALIZATION = gql`
  query MyQuery($site_reference_id: String, $division_id: String) {
    bioLcGetActualizationIncomingDocs(
      event: {site_reference_id: $site_reference_id, division_id: $division_id}
    ) {
      error
      statusCode
      documents {
        sd_number
        contract_number
        certificate_number
        material_dispatch_datez
        supplier_dispatch_address
        product_qty
        energy_mj
        product_type
        country_of_origin
        ghg_gco2eq
        raw_material
        certificate_inbound_eu_id
        balance_group_name
        location_group_name
        product_qty_uom
        supplier_name
        recipient_address
      }
    }
  }
`;

export const SAF_GET_PURCHASE_DOCUMENTS_ACTUALIZATION = gql`
  query MyQuery($site_reference_id: String, $division_id: String) {
    bioLcGetActualizationPurchaseDocs(
      event: {site_reference_id: $site_reference_id, division_id: $division_id}
    ) {
      error
      statusCode
      documents {
        deal_number
        po_number
        deal_done_date
        energy_mj
        material_code
        plant_name
        ghg_kgco2eq_mj
        purchase_layer_id
        quantity_actual
        quantity_l15_actualized
        quantity_m3_sbc
        quantity_l15_sbc
        quantity_m3_hdr
        quantity_l15_hdr
        quantity_m3_in
        quantity_l15_in
        vendor_name
        purchase_layer_id
        balance_group_name
        location_group_name
        plant_code
        material_code
        purchase_date
        discharge_date
        material_description
        source_location_name
        destination_location_name
        discharge_plant_code
        purchase_plant_code
        invoice_number
        received_qty_mt
        purchase_qty_mt
        purchase_qty_m3
        received_qty_m3
      }
    }
  }
`;

export const SAF_GET_ACTUALIZATION_DEAL_NUMBERS = gql`
  query MyQuery($site_reference_id: String) {
    bioLcGetActualizationDealNumbers(
      event: {
        site_reference_id: $site_reference_id
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
      }
    ) {
      error
      statusCode
      deal_numbers {
        deal_number
      }
    }
  }
`;

export const SAF_GET_ACTUALIZATION_PLANT_NAMES = gql`
  query MyQuery($site_reference_id: String) {
    bioLcGetActualizationPlantNames(
      event: {
        site_reference_id: $site_reference_id
        division_id: "7c5fc8889b6e3c8756abbd76353d68e7"
      }
    ) {
      error
      statusCode
      plant_names {
        plant_name
      }
    }
  }
`;

export const ACTUALIZATION_MUTATION = gql`
  mutation bioLcActualization($event: GetActualizationRequest!) {
    bioLcActualization(event: $event) {
      error
      statusCode
    }
  }
`;

export default SAF_GET_INCOMING_DOCUMENTS_ACTUALIZATION;
