import PropTypes from "prop-types";
import React, {useEffect, useMemo, useState} from "react";
import {
  Modal,
  ModalBody,
  Spinner,
  Button,
  InputGroup,
  InputGroupText,
  Input,
} from "reactstrap";
import {toast} from "react-toastify";
import moment from "moment";
import {useUserSettings} from "providers/userSettings";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import MARK_AS_COMPLETE from "graphql/es-co-processing/MarkAsComplete";
import {
  Add24,
  Filter24,
  Link24,
  Remove24,
} from "@bphxd/ds-core-react/lib/icons";
import SvgSearch24 from "@bphxd/ds-core-react/lib/icons/interface/Search24";
import {DOC_STATUS_TRACKER, uploadFile} from "graphql/saf/UploadDocument";
import SAF_GET_INCOMING_DOCUMENTS from "graphql/saf/IncomingDocuments";
import {
  INCOMING_DOC_STATUS,
  POLLING_END_IN_SECONDS,
  POLLING_FIRST_TRIGGER_IN_SECONDS,
  POLLING_INTERVAL_IN_SECONDS,
} from "modules/SAF-dashboard/constants/documents";
import {saf_urls, siteRefId} from "modules/SAF-dashboard/constants/common";
import {useParams, useNavigate, useSearchParams} from "react-router-dom";
import {parseDateString} from "modules/SAF-dashboard/utils";
import getColumns from "./Columns";
import CustomizeTable from "../CustomizeTable/CustomizeTable";
import SafFilter from "../SafFilter";
import UploadDocuments from "../UploadDocuments";
import PreviewDocumentModal from "../UploadDocuments/PreviewDocumentModal";
import ResponseModal from "../UploadDocuments/ResponseModal";
import DocumentTable from "../OutgoingDocuments/DocumentTable";

import "../../pages/styles.scss";

const IncomingDocumentComponent = ({refetchCount}) => {
  const [showUploadModal, setUploadModal] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [showResponse, setShowResponse] = useState(false);
  const [isErrorModalVisible, setErrorModalVisible] = useState(false);
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false);
  const [uploadFileData, setUploadFileData] = useState(null);
  const [isMarkAsCompleteOpen, setIsMarkAsCompleteOpen] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [supplierList, setSupplierList] = useState([]);
  const [isDuplicate, setIsDuplicate] = useState(false);
  const [isStillUploading, setIsStillUploading] = useState(false);

  const [highlightedRows, setHighlightedRows] = useState([]);
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);

  const [supplierName, setSupplierName] = useState(undefined);
  const [dateFrom, setDateFrom] = useState(undefined);
  const [dateTo, setDateTo] = useState(undefined);

  const [sortedData, setSortedData] = useState(undefined);

  const {country: navCountry} = useParams();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const sortBy = searchParams.get("sortBy");

  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const {data, loading, refetch} = useQuery(SAF_GET_INCOMING_DOCUMENTS, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {site_reference_id: siteRefId[navCountry]},
  });

  useEffect(() => {
    if (data && data?.bioLcGetIncomingDocsApi) {
      setSupplierList(data?.bioLcGetIncomingSupplierApi?.supplierNames);
      // This will be used in future for now no task is created for below function.
      setHighlightedRows([]);
    }
  }, [data, setSupplierList]);
  useEffect(() => {
    if (data?.bioLcGetIncomingDocsApi?.documents) {
      const unsortedData = data?.bioLcGetIncomingDocsApi?.documents;
      if (sortBy) {
        const topData = unsortedData.filter((item) => item.state === sortBy);
        const bottomData = unsortedData.filter((item) => item.state !== sortBy);
        setSortedData([...topData, ...bottomData]);
      } else {
        const requires_attention = unsortedData.filter(
          (item) => item.state === INCOMING_DOC_STATUS.REQUIRES_ATTENTION,
        );
        const ready_to_accept = unsortedData.filter(
          (item) => item.state === INCOMING_DOC_STATUS.READY_TO_ACCEPT,
        );
        const ready_to_assign = unsortedData.filter(
          (item) => item.state === INCOMING_DOC_STATUS.READY_TO_ASSIGN,
        );
        const assigned = unsortedData.filter(
          (item) => item.state === INCOMING_DOC_STATUS.ASSIGNED,
        );
        setSortedData([
          ...requires_attention,
          ...ready_to_accept,
          ...ready_to_assign,
          ...assigned,
        ]);
      }
    }
  }, [data, sortBy]);

  const [markAsComplete] = useMutation(MARK_AS_COMPLETE, {
    onCompleted: ({bioLcCoproEuMarkAsComplete}) => {
      setIsMarkAsCompleteOpen(false);
      if (bioLcCoproEuMarkAsComplete?.statusCode === 200) {
        toast.success(
          bioLcCoproEuMarkAsComplete?.message ??
            "Document successfully marked as completed",
        );
        refetch();
        refetchCount();
      } else {
        toast.error(
          bioLcCoproEuMarkAsComplete?.message ?? "Failed to mark as completed",
        );
      }
    },
  });

  const tableData = useMemo(() => {
    /* Fields to display
  Status (Toggle should be disabled and fixed to on)
  Certificate ID (Toggle should be disabled and fixed to on) : certificate_number / certificate_inbound_eu_id
  Product : product_type
  Raw material : raw_material_type
  Quantity : total
  Supplier / Customer : supplier_name
  Receiving point : recipient_receipt_address
  Date of issuance : date_of_issuance
  Contract number : contract_number
  GHG total : ghg_total
  Date of physical receipt : user_installation_date
  Mass balance
  */
    return (sortedData ?? []).map((document) => ({
      sdNumber: document.sdNumber,
      certificateInboundEuId: document.certificateInboundEuId,
      previousVersions: document.versionHistoryData,
      previousVersionsCount: document.versionCount,
      status: document.state,
      certificateNumber: document.sdNumber,
      product: document.productType,
      rawMaterialType: document.rawMaterialType,
      dateOfIssuance: parseDateString(document.dateOfIssuance),
      supplierName: document.supplierName,
      recipientReceiptAddress: document.recipientReceiptAddress,
      materialCode: document.rawMaterialType,
      quantity: document.productQtyUom === "MT" && document.productQty,
      quantinInm3: document.productQtyUom === "M3" && document.productQty,
      ghgTotal: document.ghgTotal,
      contractNumber: document.contractNumber,
    }));
  }, [sortedData]);

  const cols = useMemo(() => getColumns(dateFormat), [dateFormat]);

  const [columns, setColumns] = useState(cols);

  useEffect(() => {
    if (cols) {
      setColumns(cols);
    }
  }, [cols, dateFormat]);

  const filteredColumns = useMemo(
    () => columns.filter(({visible}) => visible),
    [columns],
  );

  const handleFilterSubmit = (filterData) => {
    if (filterData) {
      setSupplierName(filterData.supplierName);
      setDateFrom(
        filterData.dateOfIssuance ? filterData.dateOfIssuance[0] : undefined,
      );
      setDateTo(
        filterData.dateOfIssuance ? filterData.dateOfIssuance[1] : undefined,
      );
      const convertedObjects = Object.entries(filterData)
        .filter(([key, value]) => value !== undefined)
        .map(([key, value]) => ({id: key, value}));

      setColumnFilter(convertedObjects);
    } else {
      setSupplierName(undefined);
      setDateFrom(undefined);
      setDateTo(undefined);
    }
  };

  const [pollingTracker, {data: trackerData, stopPolling, called}] =
    useLazyQuery(DOC_STATUS_TRACKER, {
      pollInterval: POLLING_INTERVAL_IN_SECONDS,
      fetchPolicy: "network-only",
    });

  useEffect(() => {
    const handleTracking = async () => {
      if (trackerData && trackerData.bioLcUploadIncomingDocStatusTrackerApi) {
        const {statusCode} = trackerData.bioLcUploadIncomingDocStatusTrackerApi;
        switch (statusCode) {
          case 400: // Duplicate document
            setIsUploading(false);
            setIsDuplicate(true);
            setErrorModalVisible(true);
            stopPolling();
            break;
          case 500: // Textract failed
            setIsUploading(false);
            setErrorModalVisible(true);
            stopPolling();
            break;
          case 201: // Successfully parsed
            setIsUploading(false);
            setIsUploadSuccessful(true);
            stopPolling();
            refetch();
            refetchCount();
            break;
          default:
            break;
        }
      }
    };

    handleTracking();
  }, [trackerData, stopPolling, refetch, refetchCount]);

  useEffect(() => {
    let pollingTimeout;
    if (called) {
      pollingTimeout = setTimeout(() => {
        // After polling 5 times, if the result is still in progress, then terminate the polling manually.
        if (
          trackerData?.bioLcUploadIncomingDocStatusTrackerApi?.statusCode ===
          202
        ) {
          setIsUploading(false);
          setIsStillUploading(true);
          stopPolling();
        }
      }, POLLING_END_IN_SECONDS);
    }
    return () => clearTimeout(pollingTimeout);
  }, [
    called,
    stopPolling,
    trackerData?.bioLcUploadIncomingDocStatusTrackerApi?.statusCode,
  ]);

  const handleUpload = async () => {
    if (uploadFileData !== null && uploadFileData !== undefined) {
      setIsUploading(true);
      setShowResponse(true);
      try {
        const uploadStatusCode = await uploadFile(
          uploadFileData,
          siteRefId[navCountry],
        );
        if (uploadStatusCode === 200) {
          // First polling will start after 30 seconds of uploading the PDF.
          setTimeout(async () => {
            pollingTracker({
              variables: {
                doc_name: uploadFileData.name,
                site_reference_id: siteRefId[navCountry],
              },
            });
          }, POLLING_FIRST_TRIGGER_IN_SECONDS);
        } else {
          setIsUploading(false);
          setErrorModalVisible(true);
        }
      } catch (err) {
        console.error(err);
        // Unexpected error
      }
    }
    return false;
  };

  return (
    <>
      <Modal
        size="sm"
        isOpen={isMarkAsCompleteOpen}
        className="modal-dialog-centered"
      >
        <ModalBody>
          <div className="flex flex-row pl-4 justify-start items-center gap-4">
            <Spinner />
            <span>Processing...</span>
          </div>
        </ModalBody>
      </Modal>
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-end w-full mb-5">
          <Button
            color="tertiary"
            outline
            type="button"
            size="md"
            className="show link-btn"
            onClick={() => {
              navigate({
                pathname: saf_urls[`${navCountry}LinkToPurchase`],
              });
            }}
          >
            Link to purchase
            <Link24 className="btn-icon-suffix" />
          </Button>
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            id="addDocumentButton"
            size="md"
            onClick={() => {
              setUploadModal(!showUploadModal);
            }}
          >
            Add document
            <Add24 className="btn-icon-suffix" />
          </Button>
        </div>
      </div>

      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={columns}
              onColumnChange={(currColumn) => setColumns(currColumn)}
              showApplyButton={false}
            />
          </div>
          <div className="flex flex-none flex-row items-center mx-5">
            <div className="mr-2">
              <Button
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
            <div>
              <InputGroup className="input-group-merge" size="">
                <Input
                  className="form-control-appended"
                  placeholder="Search"
                  value={filtering}
                  onChange={(e) => setFiltering(e.target.value)}
                />
                <div className="input-group-append">
                  <InputGroupText>
                    {filtering ? (
                      <Remove24
                        className="cursor-pointer"
                        onClick={() => setFiltering("")}
                      />
                    ) : (
                      <SvgSearch24 />
                    )}
                  </InputGroupText>
                </div>
              </InputGroup>
            </div>
          </div>
        </div>

        {showFilter && (
          <div className="flex flex-col justify-stretch w-full items-start px-7 border-secondary border-top">
            <div className=" flex flex-row w-full my-6">
              <SafFilter
                supplierName={supplierName}
                dateFrom={dateFrom}
                dateTo={dateTo}
                onSubmit={handleFilterSubmit}
                supplierList={supplierList}
                setColumnFilter={setColumnFilter}
                setShowFilter={setShowFilter}
              />
            </div>
          </div>
        )}

        <div className="border-secondary border-top w-full">
          <DocumentTable
            columns={filteredColumns}
            data={tableData}
            className="incoming-docs-table"
            highlightedRows={highlightedRows}
            loading={loading}
            filtering={filtering}
            setFiltering={setFiltering}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
            type="Incoming"
          />
          {showUploadModal && (
            <UploadDocuments
              showUploadModal={showUploadModal}
              setUploadModal={setUploadModal}
              setUploadFile={setUploadFileData}
              setShowPreview={setShowPreview}
              handleUpload={handleUpload}
            />
          )}

          {showPreview && (
            <PreviewDocumentModal
              showPreview={showPreview}
              setShowPreview={setShowPreview}
              uploadFile={uploadFileData}
              setIsUploading={setIsUploading}
              handleUpload={handleUpload}
              setShowResponse={setShowResponse}
            />
          )}

          {showResponse && (
            <ResponseModal
              showResponse={showResponse}
              setShowResponse={setShowResponse}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              isErrorModalVisible={isErrorModalVisible}
              setErrorModalVisible={setErrorModalVisible}
              setUploadModal={setUploadModal}
              bodyClassNames={
                isErrorModalVisible || isUploadSuccessful || isStillUploading
                  ? "h-40 pt-0"
                  : "h-48"
              }
              isUploadSuccessful={isUploadSuccessful}
              setIsUploadSuccessful={setIsUploadSuccessful}
              isDuplicate={isDuplicate}
              setIsDuplicate={setIsDuplicate}
              isStillUploading={isStillUploading}
              setIsStillUploading={setIsStillUploading}
            />
          )}
        </div>
      </div>
    </>
  );
};

IncomingDocumentComponent.propTypes = {
  refetchCount: PropTypes.func,
};

export default IncomingDocumentComponent;
