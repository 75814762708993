import React, {Fragment, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {InputGroup, Input, FormGroup} from "reactstrap";
import {useFormContext} from "react-hook-form";
import "./index.css";
import {INITIAL} from "./DefaultHeaders";

const CostAllFeedstock = ({
  allFeedStockData,
  updateSaveButton,
  paramsEditable,
}) => {
  const {
    register,
    setValue,
    getValues,
    formState: {errors},
  } = useFormContext();

  const numberInputPattern = {
    pattern: {
      value: /^(\d{1,10}(\.\d{1,5})?)$/,
      message:
        "Value must be number with (max 10 digits before and 5 digits after decimal point)",
    },
  };

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const [originalDataMap, setOriginalDataMap] = useState({});

  useEffect(() => {
    const dataMap = allFeedStockData.reduce((acc, item) => {
      const key = item.parameterName.toLowerCase();
      const value = getValues(key);
      acc[key] = value;
      return acc;
    }, {});
    setOriginalDataMap(dataMap);
  }, [allFeedStockData, getValues]);

  const handleBlur = (key, val) => {
    originalDataMap[key] = val;
    setValue(key, val);
    return val;
  };

  const handleFocus = (key) => {
    return originalDataMap[key];
  };

  const handleInputChange = (key, val) => {
    setValue(key, val);
    updateSaveButton();
  };

  return (
    <div className=" flex flex-col w-full">
      <div className="flex w-full heading-background">
        <span className="flex w-full text-background">All feedstock</span>
      </div>
      <hr className="border-1 margin-for-border-line header-bottom-line" />
      <div className="grid grid-cols-5 w-full">
        {allFeedStockData.map((item) => (
          <Fragment key={item.parameterName}>
            <div className="flex flex-col">
              <div className="text-background">
                <span>{item.parameterLabel}</span>
              </div>
            </div>
          </Fragment>
        ))}
      </div>
      <div className="flex flex-row background-for-input-box">
        {allFeedStockData.map((item) => (
          <Fragment key={item.parameterName}>
            <FormGroup>
              <InputGroup className="text-box-background">
                <Input
                  type="number"
                  className="text-box-input-value"
                  readOnly={!paramsEditable}
                  id={item.parameterName.toLowerCase()}
                  data-test={item.parameterName.toLowerCase()}
                  {...computeProps(
                    `${item.parameterName.toLowerCase()}-${INITIAL}`,
                    numberInputPattern,
                  )}
                  onBlur={(e) => {
                    e.target.value = handleBlur(
                      item.parameterName.toLowerCase(),
                      e.target.value,
                    );
                  }}
                  onFocus={(e) => {
                    e.target.value = handleFocus(
                      item.parameterName.toLowerCase(),
                    );
                  }}
                  onChange={(e) => {
                    handleInputChange(
                      item.parameterName.toLowerCase(),
                      e.target.value,
                    );
                  }}
                />
                <div className="border addon-textbox-background ">
                  <div className="addon-text-value">{item.uomSymbol}</div>
                </div>
              </InputGroup>
            </FormGroup>
          </Fragment>
        ))}
      </div>
      <hr className="border-1 mb-8 margin-for-border-line border-[#ccc]" />
    </div>
  );
};

CostAllFeedstock.propTypes = {
  allFeedStockData: PropTypes.array.isRequired,
  updateSaveButton: PropTypes.func,
  paramsEditable: PropTypes.bool.isRequired,
};

export default CostAllFeedstock;
