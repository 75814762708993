// once backend is ready put the gql here

export const siteRefId = {
  sweden: "c8f4261f9f46e6465709e17ebea7a92b",
  norway: "d5b9290a0b67727d4ba1ca6059dc31a6",
};

export const saf_urls = {
  swedenLandingPage: "/saf/sweden/dashboard",
  norwayLandingPage: "/saf/norway/dashboard",
  swedenDocumentPage: "/saf/sweden/document-manager",
  norwayDocumentPage: "/saf/norway/document-manager",
  swedenMassBalance: "/saf/sweden/mass-balance",
  norwayMassBalance: "/saf/norway/mass-balance",
  swedenReports: "saf/sweden/reports",
  norwayReports: "saf/norway/reports",
  swedenLinkToPurchase: "/saf/sweden/link-to-purchase",
  norwayLinkToPurchase: "/saf/norway/link-to-purchase",
};

export const DATE_RANGE_SIZE = 2;

export const CONFIG_CENTER_URL = "/config-center/coprocessing-spain";
