import React from "react";
import PropTypes from "prop-types";
import {Datepicker} from "@bphxd/ds-core-react";
import {Down24} from "@bphxd/ds-core-react/lib/icons";
import {
  Input,
  Label,
  FormGroup,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import {
  SHIPMENT_TYPES_DROPDOWN,
  STATUS_DROPDOWN,
} from "modules/co-processing/constants/shipments";
import {INITIAL_RANGE} from "modules/co-processing/context/DateRangeContext";
import "./index.scss";

const StatusFilter = ({columnFilters, setColumnFilters}) => {
  const filterStatuses =
    columnFilters?.find((f) => f?.id === "status")?.value || [];

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="border-2 border-primary rounded-0 col-sm-12"
        color="standard"
        caret
      >
        Statuses
        <Down24 />
      </DropdownToggle>
      <DropdownMenu>
        {STATUS_DROPDOWN.map((status) => {
          const isActive = filterStatuses?.includes(status.value);

          return (
            <DropdownItem key={status.value}>
              <FormGroup check className="d-inline-block x5-mx-md-2 mb-md-0">
                <Input
                  type="checkbox"
                  value={status.value}
                  defaultChecked={isActive}
                  onClick={() =>
                    setColumnFilters((prev) => {
                      const statusFilter = prev?.find(
                        (f) => f?.id === "status",
                      );
                      const statuses = statusFilter?.value || [];

                      if (isActive) {
                        // If the status is active, remove it from the filter
                        const updatedStatuses = statuses.filter(
                          (s) => s !== status.value,
                        );
                        return prev?.map((f) =>
                          f?.id === "status"
                            ? {...f, value: updatedStatuses}
                            : f,
                        );
                      }
                      // If the status is not active, add it to the filter
                      const updatedStatuses = statuses.concat(status.value);
                      return statusFilter
                        ? prev?.map((f) =>
                            f?.id === "status"
                              ? {...f, value: updatedStatuses}
                              : f,
                          )
                        : prev.concat({id: "status", value: [status.value]});
                    })
                  }
                />
                <Label check for={status.value}>
                  {status.label}
                </Label>
              </FormGroup>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const ShipmentTypeFilter = ({
  activeShipmentType,
  onShipmentTypeChange,
}) => {
  return (
    <UncontrolledDropdown>
      <DropdownToggle color="standard" caret>
        {SHIPMENT_TYPES_DROPDOWN.find((t) => t?.value === activeShipmentType)
          ?.label || "Shipment type"}
        <Down24 />
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem onClick={(e) => onShipmentTypeChange("")}>
          All shipment types
        </DropdownItem>
        {SHIPMENT_TYPES_DROPDOWN.map(({value, label}) => {
          return (
            <DropdownItem
              onClick={(e) => onShipmentTypeChange(value)}
              key={value}
            >
              {label}
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export const Filters = ({columnFilters, setColumnFilters, onDateChange}) => {
  return (
    <div className="historical-shipment-filters" data-test="shipments-filters">
      {/* <StatusFilter
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      /> */}
      <ShipmentTypeFilter
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
      />
      <Datepicker
        data-test="historical-shipments-date-range-filter"
        placeholder="Custom date range"
        options={{
          allowInput: false,
          defaultDate: Object.values(INITIAL_RANGE).map((dateStr) => {
            const date = new Date(dateStr);
            return date.toISOString();
          }),
          onChange: ([startDate, endDate]) => {
            if (endDate) {
              // Add one day to ensure that end date specified by the user is included in the date range.
              const inclusiveEndDate = new Date(endDate);
              inclusiveEndDate.setDate(endDate.getDate() + 1);

              const dates = [startDate, inclusiveEndDate];
              onDateChange(dates?.map((date) => date.toISOString()));
            }
          },
          mode: "range",
          static: true,
        }}
      />
    </div>
  );
};

const filterPropTypes = {
  columnFilters: PropTypes.array,
  setColumnFilters: PropTypes.func,
};

Filters.propTypes = {...filterPropTypes, onDateChange: PropTypes.func};
StatusFilter.propTypes = filterPropTypes;
ShipmentTypeFilter.propTypes = {
  activeShipmentType: PropTypes.string,
  onShipmentTypeChange: PropTypes.func,
};
