import PropTypes from "prop-types";
import React, {useEffect, useMemo} from "react";
import {uniqBy, orderBy, isEmpty} from "lodash";
import {FormGroup, Label, Input, Button, FormFeedback} from "reactstrap";
import {useFormContext} from "react-hook-form";

const MassBalanceFilterContent = ({
  defaultValues,
  filterDynamicData = [],
  handleFilterSubmit,
}) => {
  const {
    register,
    watch,
    setValue,
    getValues,
    formState: {errors},
  } = useFormContext({
    defaultValues,
  });
  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const watchType = watch("mbBalanceTypeId");
  const watchLocation = watch("mbLocationGroupId");
  const watchPeriod = watch("mbPeriodId");
  const watchBalance = watch("mbBalanceGroupId");

  const filterDynamicDataResult = useMemo(() => {
    return filterDynamicData;
  }, [filterDynamicData]);

  const typeList = useMemo(() => {
    if (filterDynamicDataResult.length > 0) {
      const typeItems = filterDynamicDataResult?.map((item) => ({
        mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
        mbBalanceTypeId: item?.mbBalanceType?.mbBalanceTypeId,
      }));
      return orderBy(
        uniqBy(typeItems, "mbBalanceTypeCode"),
        "mbBalanceTypeCode",
        "desc",
      );
    }
    return [];
  }, [filterDynamicDataResult]);

  const locationList = useMemo(() => {
    if (filterDynamicDataResult.length > 0) {
      const locationItems = filterDynamicDataResult
        ?.filter(
          (item) =>
            item?.mbBalanceType?.mbBalanceTypeId ===
            (isEmpty(watchType)
              ? item?.mbBalanceType?.mbBalanceTypeId
              : watchType),
        )
        ?.map((item) => ({
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbLocationGroupId: item?.mbLocationGroup?.mbLocationGroupId,
        }));
      return orderBy(uniqBy(locationItems, "location"), "location", "desc");
    }
    return [];
  }, [filterDynamicDataResult, watchType]);

  const periodList = useMemo(() => {
    if (filterDynamicDataResult.length > 0) {
      const periodItems = filterDynamicDataResult
        ?.filter(
          (item) =>
            item?.mbBalanceType?.mbBalanceTypeId ===
              (isEmpty(watchType)
                ? item?.mbBalanceType?.mbBalanceTypeId
                : watchType) &&
            item?.mbLocationGroup?.mbLocationGroupId ===
              (isEmpty(watchLocation)
                ? item?.mbLocationGroup?.mbLocationGroupId
                : watchLocation),
        )
        ?.map((item) => ({
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbPeriodName: item?.mbPeriod?.mbPeriodName,
          mbPeriodId: item?.mbPeriod?.mbPeriodId,
        }));
      return orderBy(
        uniqBy(periodItems, "mbPeriodName"),
        "mbPeriodName",
        "desc",
      );
    }
    return [];
  }, [filterDynamicDataResult, watchType, watchLocation]);

  const balanceList = useMemo(() => {
    if (filterDynamicDataResult.length > 0) {
      const balanceItems = filterDynamicDataResult
        ?.filter(
          (item) =>
            item?.mbBalanceType?.mbBalanceTypeId ===
              (isEmpty(watchType)
                ? item?.mbBalanceType?.mbBalanceTypeId
                : watchType) &&
            item?.mbLocationGroup?.mbLocationGroupId ===
              (isEmpty(watchLocation)
                ? item?.mbLocationGroup?.mbLocationGroupId
                : watchLocation) &&
            item?.mbPeriod?.mbPeriodId ===
              (isEmpty(watchPeriod) ? item?.mbPeriod?.mbPeriodId : watchPeriod),
        )
        ?.map((item) => ({
          mbBalanceTypeCode: item?.mbBalanceType?.mbBalanceTypeCode,
          mbLocationGroupName: item?.mbLocationGroup?.mbLocationGroupName,
          mbPeriodName: item?.mbPeriod?.mbPeriodName,
          mbBalanceGroupName: item?.mbBalanceGroup?.mbBalanceGroupName,
          mbBalanceGroupId: item?.mbBalanceGroup?.mbBalanceGroupId,
        }));
      return orderBy(
        uniqBy(balanceItems, "mbBalanceGroupName"),
        "mbBalanceGroupName",
        "desc",
      );
    }
    return [];
  }, [filterDynamicDataResult, watchType, watchLocation, watchPeriod]);

  useEffect(() => {
    if (filterDynamicDataResult?.length > 0 && !isEmpty(defaultValues)) {
      setValue("mbBalanceTypeId", defaultValues?.mbBalanceTypeId);
      setValue("mbLocationGroupId", defaultValues?.mbLocationGroupId);
      setValue("mbPeriodId", defaultValues?.mbPeriodId);
      setValue("mbBalanceGroupId", defaultValues?.mbBalanceGroupId);
    }
  }, [setValue, filterDynamicDataResult, defaultValues]);

  useEffect(() => {
    if (
      !isEmpty(watchType) &&
      !typeList.find((item) => item?.mbBalanceTypeId === watchType)
    ) {
      if (typeList?.length > 0) {
        setValue("mbBalanceTypeId", typeList[0]?.mbBalanceTypeId);
      } else {
        setValue("mbBalanceTypeId", "");
      }
    }
  }, [setValue, typeList, watchType]);

  useEffect(() => {
    if (
      !isEmpty(watchLocation) &&
      !locationList.find((item) => item?.mbLocationGroupId === watchLocation)
    ) {
      if (locationList?.length > 0) {
        setValue("mbLocationGroupId", locationList[0]?.mbLocationGroupId);
      } else {
        setValue("mbLocationGroupId", null);
      }
    }
  }, [setValue, locationList, watchLocation]);

  useEffect(() => {
    if (
      !isEmpty(watchPeriod) &&
      !periodList.find((item) => item?.mbPeriodId === watchPeriod)
    ) {
      if (periodList?.length > 0) {
        setValue("mbPeriodId", periodList[0]?.mbPeriodId);
      } else {
        setValue("mbPeriodId", "");
      }
    }
  }, [setValue, periodList, watchPeriod]);

  useEffect(() => {
    if (
      !isEmpty(watchBalance) &&
      !balanceList.find((item) => item?.mbBalanceGroupId === watchBalance)
    ) {
      if (balanceList?.length > 0) {
        setValue("mbBalanceGroupId", balanceList[0]?.mbBalanceGroupId);
      } else {
        setValue("mbBalanceGroupId", "");
      }
    }
  }, [setValue, balanceList, watchBalance]);

  return (
    <div className="flex flex-col fifo-filter-content-section">
      <div className="flex flex-row gap-5">
        <FormGroup>
          <Label for="mbBalanceTypeId">Type</Label>
          <Input
            type="select"
            id="type"
            data-test="type"
            {...computeProps("mbBalanceTypeId", {
              required: "Please select type",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            {typeList?.length > 0 &&
              typeList?.map((type, index) => (
                <option key={index} value={type?.mbBalanceTypeId}>
                  {type?.mbBalanceTypeCode}
                </option>
              ))}
          </Input>
          {errors.mbBalanceTypeId && (
            <FormFeedback>{errors?.mbBalanceTypeId?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="location">Location</Label>
          <Input
            type="select"
            id="location"
            data-test="location"
            {...computeProps("mbLocationGroupId", {
              required: "Please select location",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            {locationList?.length > 0 &&
              locationList?.map((location) => (
                <option
                  key={location?.mbLocationGroupId}
                  value={location?.mbLocationGroupId}
                >
                  {location?.mbLocationGroupName}
                </option>
              ))}
          </Input>
          {errors.location && (
            <FormFeedback>{errors?.location?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="mbPeriodId">Period</Label>
          <Input
            type="select"
            id="mbPeriodId"
            data-test="mbPeriodId"
            {...computeProps("mbPeriodId", {
              required: "Please select period",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-60 bg-white"
          >
            {periodList?.length > 0 &&
              periodList?.map((period) => (
                <option key={period?.mbPeriodId} value={period?.mbPeriodId}>
                  {period?.mbPeriodName}
                </option>
              ))}
          </Input>
          {errors?.mbPeriodId && (
            <FormFeedback>{errors?.mbPeriodId?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup>
          <Label for="mbBalanceGroupId">Balance</Label>
          <Input
            type="select"
            id="balance"
            data-test="balance"
            {...computeProps("mbBalanceGroupId", {
              required: "Please select balance",
            })}
            maxLength={100}
            placeholder="Please choose from list"
            className="!w-32 bg-white"
          >
            {balanceList?.length > 0 &&
              balanceList?.map((balance) => (
                <option
                  key={balance?.mbBalanceGroupId}
                  value={balance?.mbBalanceGroupId}
                >
                  {balance?.mbBalanceGroupName}
                </option>
              ))}
          </Input>
          {errors.balance && (
            <FormFeedback>{errors?.balance?.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup className="flex flex-col-reverse">
          <Button
            data-test="filter-submit-button"
            color="standard-tertiary rounded-0"
            type="button"
            form="mb-filter-form"
            onClick={handleFilterSubmit}
          >
            Apply
          </Button>
        </FormGroup>
      </div>
    </div>
  );
};

MassBalanceFilterContent.propTypes = {
  defaultValues: PropTypes.object.isRequired,
  filterDynamicData: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleFilterSubmit: PropTypes.func,
};

export default MassBalanceFilterContent;
