import React, {useEffect, useMemo, useState} from "react";
import {Link, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {Button, Spinner} from "reactstrap";
import {Filter24} from "@bphxd/ds-core-react/lib/icons";
import {
  SAF_GET_INCOMING_DOCUMENTS_ACTUALIZATION,
  SAF_GET_PURCHASE_DOCUMENTS_ACTUALIZATION,
  ACTUALIZATION_MUTATION,
} from "graphql/saf/LinkToPurchase";
import {useMutation, useQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {useUserSettings} from "providers/userSettings";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {useAppSetting} from "providers/appSetting";
import {DIV_CODE_SAF} from "constants/divisionDetails";
import Layout from "../components/Layout";
import {saf_urls, siteRefId} from "../constants/common";
import generateBreadcrumbItems, {
  formatNumber,
  getUniqueList,
  parseDateString,
} from "../utils";
import INCOMING_COLUMNS, {
  PURCHASE_COLUMNS,
} from "../components/LinkToPurchaseTable/Column";
import CustomizeTable from "../components/LinkToPurchaseTable/CustomizeTable";
import DocumentTable from "../components/LinkToPurchaseTable/DocumentTable";
import ActualisationModal from "../components/LinkToPurchaseTable/ActualisationModal";
import {DIVISION_ID} from "../constants/documents";

const SAFLinkToPurchasePage = () => {
  const {country} = useParams();
  const [showFilter, setShowFilter] = useState(true);
  const [filtering, setFiltering] = useState("");
  const [columnFilter, setColumnFilter] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [purchaseRowSelection, setPurchaseRowSelection] = useState({});
  const [incomingTotal, setIncomingTotal] = useState(0);
  const [purchaseTotal, setPurchaseTotal] = useState(0);
  const [filteredIncomingRows, setFilteredIncomingRows] = useState([]);
  const [filteredPurchaseRows, setFilteredPurchaseRows] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isIncoming, setIsIncoming] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);

  const {
    userSettings: {dateFormat},
  } = useUserSettings();

  const {appSetting} = useAppSetting();

  setCountryDetails(country);

  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(DIV_CODE_SAF);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const contract_number = searchParams.get("contract_no");

  useEffect(() => {
    if (contract_number) {
      const convertedObjects = [
        {id: "deal_number", value: contract_number},
        {id: "contract_number", value: contract_number},
      ];
      setColumnFilter(convertedObjects);
    }
  }, [contract_number]);

  useEffect(() => {
    const calculateSum = (data, rowSelected, field) => {
      if (data.length > 0) {
        const isSelected = Object.keys(rowSelected).length > 0;
        const selectedRecords = isSelected
          ? Object.keys(rowSelected).map((item) =>
              data.find((ele) => ele.id === item),
            )
          : data;

        return selectedRecords.reduce(
          (sum, item) => sum + item.original[field],
          0,
        );
      }
      return 0;
    };

    const sumIncoming = calculateSum(
      filteredIncomingRows,
      rowSelection,
      "product_qty_m3",
    );
    const sumPurchase = calculateSum(
      filteredPurchaseRows,
      purchaseRowSelection,
      "quantity_actual",
    );

    setIncomingTotal(sumIncoming);
    setPurchaseTotal(sumPurchase);
  }, [
    rowSelection,
    purchaseRowSelection,
    filteredIncomingRows,
    filteredPurchaseRows,
  ]);

  const optionalItems = [
    {text: "Link to purchase", link: saf_urls[`${country}LinkToPurchase`]},
  ];

  const breadcrumbItems = generateBreadcrumbItems(
    country,
    saf_urls,
    optionalItems,
  );

  const incomingCols = useMemo(() => {
    const IncomingColsList = INCOMING_COLUMNS(dateFormat);
    return IncomingColsList;
  }, [dateFormat]);

  const purchaseCols = useMemo(
    () => PURCHASE_COLUMNS(dateFormat),
    [dateFormat],
  );

  const [incomingColumns, setIncomingColumns] = useState(incomingCols);
  const [purchaseColumns, setPurchaseColumns] = useState(purchaseCols);

  useEffect(() => {
    if (incomingCols) {
      setIncomingColumns(incomingCols);
    }
    if (purchaseCols) {
      setPurchaseColumns(purchaseCols);
    }
  }, [incomingCols, purchaseCols, dateFormat]);

  const filteredIncomingColumns = useMemo(() => {
    return [
      {
        header: incomingColumns?.[0]?.header,
        columns: incomingColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [incomingColumns]);

  const filteredPurchaseColumns = useMemo(() => {
    return [
      {
        header: purchaseColumns?.[0]?.header,
        columns: purchaseColumns?.[0]?.columns.filter(({visible}) => visible),
      },
    ];
  }, [purchaseColumns]);

  const {
    data: incomingData,
    loading: incomingLoading,
    refetch: incomingRefetch,
  } = useQuery(SAF_GET_INCOMING_DOCUMENTS_ACTUALIZATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      site_reference_id: siteReferenceData?.siteReferenceId,
      division_id: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const {
    data: purchaseData,
    loading: purchaseLoading,
    refetch: purchaseRefetch,
  } = useQuery(SAF_GET_PURCHASE_DOCUMENTS_ACTUALIZATION, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      site_reference_id: siteReferenceData?.siteReferenceId,
      division_id: divisionData?.divisionId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined,
  });

  const incomingDocData =
    incomingData?.bioLcGetActualizationIncomingDocs?.documents;
  const purchaseDocData =
    purchaseData?.bioLcGetActualizationPurchaseDocs?.documents;

  const incomingTableData = useMemo(() => {
    return (incomingDocData ?? []).map((document) => ({
      contract_number: document.contract_number,
      certificate_number: document.certificate_number || "",
      material_dispatch_datez: parseDateString(
        document.material_dispatch_datez,
      ),
      energy_mj_incoming: document.energy_mj && parseFloat(document.energy_mj),
      ghg_gco2eq_incoming:
        document.ghg_gco2eq && parseFloat(document.ghg_gco2eq),
      supplier_dispatch_address: document.supplier_dispatch_address,
      product_type: document.product_type,
      raw_material: document.raw_material,
      country_of_origin: document.country_of_origin,
      certificate_inbound_eu_id: document.certificate_inbound_eu_id,
      location_group_name: document.location_group_name,
      product_qty_m3:
        document.product_qty_uom === "M3" &&
        document.product_qty &&
        parseFloat(document.product_qty),
      product_qty_mt:
        document.product_qty_uom === "MT" &&
        document.product_qty &&
        parseFloat(document.product_qty),
      supplier_name: document.supplier_name,
      recipient_address: document.recipient_address,
      balance_group_name: document.balance_group_name,
    }));
  }, [incomingDocData]);

  const purchaseTableData = useMemo(() => {
    return (purchaseDocData ?? []).map((document) => ({
      deal_number: document.deal_number,
      po_number: document.po_number,
      deal_done_date: parseDateString(document.deal_done_date),
      energy_mj_purchase:
        document.material_number && parseFloat(document.energy_mj),
      ghg_gco2eq_purchase:
        document.ghg_kgco2eq_mj && parseFloat(document.ghg_kgco2eq_mj),
      quantity_actual:
        document.quantity_actual && parseFloat(document.quantity_actual),
      quantity_l15_actualized:
        document.quantity_l15_actualized &&
        parseFloat(document.quantity_l15_actualized),
      quantity_m3_sbc:
        document.quantity_m3_sbc && parseFloat(document.quantity_m3_sbc),
      quantity_l15_sbc:
        document.quantity_l15_sbc && parseFloat(document.quantity_l15_sbc),
      quantity_m3_hdr:
        document.quantity_m3_hdr && parseFloat(document.quantity_m3_hdr),
      quantity_l15_hdr:
        document.quantity_l15_hdr && parseFloat(document.quantity_l15_hdr),
      quantity_m3_in:
        document.quantity_m3_in && parseFloat(document.quantity_m3_in),
      quantity_l15_in:
        document.quantity_l15_in && parseFloat(document.quantity_l15_in),
      material_number:
        document.material_number && parseFloat(document.material_number),
      plant_name: document.plant_name,
      purchase_layer_id: document.purchase_layer_id,
      balance_group_name: document.balance_group_name,
      location_group_name: document.location_group_name,
      plant_code: document.plant_code,
      material_code: document.material_code,
      purchase_date: parseDateString(document.purchase_date),
      discharge_date: parseDateString(document.discharge_date),
      material_description: document.material_description,
      vendor_name: document.vendor_name,
      source_location_name: document.source_location_name,
      destination_location_name: document.destination_location_name,
      discharge_plant_code: document.discharge_plant_code,
      purchase_plant_code: document.purchase_plant_code,
    }));
  }, [purchaseDocData]);

  const isIncomingSelected = Object.keys(rowSelection).length > 0;
  const isPurchaseSelected = Object.keys(purchaseRowSelection).length > 0;

  const noData = incomingDocData?.length === 0 && purchaseDocData?.length === 0;

  // Setting value to show/not show shadow at table footer
  const moreData =
    filteredIncomingRows?.length > 14 || filteredPurchaseRows?.length > 14;

  const [actualizeSplitApi, {loading: mutationLoading}] = useMutation(
    ACTUALIZATION_MUTATION,
    {
      onCompleted: ({bioLcActualization}) => {
        if (bioLcActualization.statusCode >= 400) {
          toast.error("Failed to link the documents");
          setShowModal(false);
        } else {
          setShowModal(true);
          setRowSelection({});
          setPurchaseRowSelection({});
          setApiSuccess(true);
          incomingRefetch();
          purchaseRefetch();
        }
      },
    },
  );

  const handleLinkage = (type) => {
    const pos_ids = Object.keys(rowSelection)
      .map((item) => filteredIncomingRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original.certificate_inbound_eu_id);

    const purchase_ids = Object.keys(purchaseRowSelection)
      .map((item) => filteredPurchaseRows.find((ele) => ele.id === item))
      .map((selectedItem) => selectedItem.original.purchase_layer_id);

    actualizeSplitApi({
      variables: {
        event: {
          division_id: DIVISION_ID,
          site_reference_id: siteRefId[country],
          pos_ids,
          purchase_ids,
          split_type: type,
        },
      },
    });
  };

  return (
    <Layout title="Link to purchase" breadcrumbs={breadcrumbItems}>
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-[32px]">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={incomingColumns}
              onColumnChange={(cols) => setIncomingColumns(cols)}
              purchaseColumns={purchaseColumns}
              onPurchaseChange={(cols) => setPurchaseColumns(cols)}
              showApplyButton={false}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear all
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div
          className={`flex border-t-[1px] border-[#ededed] w-full overflow-y-auto overflow-x-hidden ${
            noData ? "" : "min-h-[400px] max-h-[850px]"
          }`}
        >
          <div className="min-w-[50%] bg-white">
            <div className="overflow-x-auto">
              <DocumentTable
                columns={filteredIncomingColumns}
                data={incomingTableData || []}
                className="incoming-docs-table"
                loading={incomingLoading}
                filtering={filtering}
                setFiltering={setFiltering}
                columnFilter={columnFilter}
                setColumnFilter={setColumnFilter}
                type="Incoming"
                enableRowSelection
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                otherRows={purchaseRowSelection}
                otherData={purchaseTableData || []}
                setFilteredIncomingRows={setFilteredIncomingRows}
                showFilter={showFilter}
                contractNumbers={getUniqueList(
                  incomingTableData,
                  "contract_number",
                )}
              />
            </div>
          </div>
          <div
            style={{
              width: "2px",
              margin: "0 0.3px 0 0.7px",
              border: "solid 3px #fafafa",
            }}
          ></div>
          <div className="min-w-[50%] bg-white">
            <div className="overflow-x-auto">
              <DocumentTable
                columns={filteredPurchaseColumns}
                data={purchaseTableData || []}
                className="incoming-docs-table"
                loading={purchaseLoading}
                filtering={filtering}
                setFiltering={setFiltering}
                columnFilter={columnFilter}
                setColumnFilter={setColumnFilter}
                type="Purchase"
                enableRowSelection
                rowSelection={purchaseRowSelection}
                setRowSelection={setPurchaseRowSelection}
                otherRows={rowSelection}
                otherData={incomingTableData || []}
                setFilteredPurchaseRows={setFilteredPurchaseRows}
                showFilter={showFilter}
                dealNumbers={getUniqueList(purchaseTableData, "deal_number")}
              />
            </div>
          </div>
        </div>
        {noData && (
          <div className="w-full text-center h-96 mt-[-15px] bg-white">
            <p className="m-10">
              It appears that you have actualized all available incoming
              documents with purchases.
            </p>
            <p>
              Back to{" "}
              <Link
                to={saf_urls[`${country}DocumentPage`]}
                className="!underline text-black"
              >
                Incoming documents
              </Link>{" "}
              or{" "}
              <Link
                to={saf_urls[`${country}MassBalance`]}
                className="!underline text-black"
              >
                Mass balance
              </Link>
            </p>
          </div>
        )}
        <div
          className={`w-full flex items-center h-16 bg-white z-10 ${
            moreData
              ? "[box-shadow:0_-6px_10px_0_rgba(17,_17,_17,_0.08)]"
              : "mt-[3px]"
          }`}
        >
          <div className="w-1/2 text-end mr-14">
            {!incomingLoading && filteredIncomingRows.length > 0 && (
              <>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(rowSelection).length > 0
                    ? "Selected Quantity"
                    : "Total Quantity"}
                </span>
                <span>{formatNumber(incomingTotal, "", 3)} m³</span>
              </>
            )}
          </div>
          <div className="w-1/2 text-end mr-14">
            {!purchaseLoading && filteredPurchaseRows.length > 0 && (
              <>
                <span className="text-gray-700 mr-[10px]">
                  {Object.keys(purchaseRowSelection).length > 0
                    ? "Selected sustainable m³"
                    : "Total sustainable m³"}
                </span>
                <span>{formatNumber(purchaseTotal, "", 3)}</span>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-6">
        <div className=" flex flex-row justify-end w-full mb-5">
          <Button
            color="tertiary"
            outline
            type="button"
            size="md"
            className="show link-btn rounded-0"
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            size="md"
            disabled={
              !isIncomingSelected || !isPurchaseSelected || mutationLoading
            }
            onClick={() => {
              if (incomingTotal === purchaseTotal) {
                handleLinkage();
              } else if (incomingTotal < purchaseTotal) {
                setIsIncoming(false);
                setShowModal(true);
              } else {
                setIsIncoming(true);
                setShowModal(true);
              }
            }}
          >
            {mutationLoading && (
              <Spinner size="sm" className="btn-icon-prefix" />
            )}
            Link
          </Button>
        </div>
      </div>
      {showModal && (
        <ActualisationModal
          showModal={showModal}
          setShowModal={setShowModal}
          isIncoming={isIncoming}
          setRowSelection={setRowSelection}
          setPurchaseRowSelection={setPurchaseRowSelection}
          apiSuccess={apiSuccess}
        />
      )}
    </Layout>
  );
};

export default SAFLinkToPurchasePage;
