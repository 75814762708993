import React from "react";
import PropTypes from "prop-types";
import TestModal from "./Modals/TestModal";
import {actionItems} from "../constants";

const ModalToShow = ({modal, rowData}) => {
  return (
    <TestModal
      isVisibleModal={modal === actionItems.LINK_DOCUMENT}
      rowData={rowData}
    />
  );
};

ModalToShow.propTypes = {
  modal: PropTypes.string,
  rowData: PropTypes.object,
};

export default ModalToShow;
