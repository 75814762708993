import React from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap";

const FilterSelectField = ({
  column,
  columnFilterValue,
  data,
  setRowSelection,
}) => {
  return (
    <Input
      className="bg-white"
      type="select"
      onChange={(e) => {
        setRowSelection({});
        column.setFilterValue(e.target.value);
      }}
      value={columnFilterValue || ""}
    >
      <option value="">All</option>
      {data.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </Input>
  );
};

FilterSelectField.propTypes = {
  column: PropTypes.object,
  columnFilterValue: PropTypes.any,
  data: PropTypes.array,
  setRowSelection: PropTypes.func,
};

export default FilterSelectField;
