import tw from "twin.macro";
import {Edit24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import valueFormatter from "modules/co-processing/helpers/valueFormatter";
import {
  SHIPMENTS_WITH_SUBTYPES,
  SHIPMENTS_WITH_ACTUAL_VOLUME,
  SHIPMENTS_WITH_PART_EDITABLE,
  SHIPMENTS_WITH_NOTES,
  SHIPMENT_WITH_SOURCE_TANK,
  SHIPMENT_FIELD_KEYS,
  NON_RENEWABLE,
} from "modules/co-processing/constants/shipments";
import isNonRenewableShipment from "modules/co-processing/helpers/getRenewableStatus";

const isEditableAndIncluded = (item, data) => {
  const shipmentType = data?.shipment_type;
  const conditions = {
    sub_type: SHIPMENTS_WITH_SUBTYPES.includes(shipmentType),
    total_actual_volume: SHIPMENTS_WITH_ACTUAL_VOLUME.includes(shipmentType),
    credits_qualified: SHIPMENTS_WITH_PART_EDITABLE.includes(shipmentType),
    renewable_shipment_flag:
      SHIPMENTS_WITH_PART_EDITABLE.includes(shipmentType),
    notes: SHIPMENTS_WITH_NOTES.includes(shipmentType),
    source_tank: SHIPMENT_WITH_SOURCE_TANK.includes(shipmentType),
  };

  return item.isEditable && conditions[item.key];
};

const ShipmentLabel = tw.div`flex flex-row text-gray-600 text-[13px]`;
const ShipmentValue = tw.div`text-xl pt-2`;
const ShipmentMetricLabel = tw.div`text-gray-600 text-xs pt-2`;
const DetailField = ({indexKey, item, part, openModal}) => {
  const shipmentValue =
    item.key === SHIPMENT_FIELD_KEYS.BATCH_ID && isNonRenewableShipment(part)
      ? NON_RENEWABLE
      : valueFormatter(part[item.key], item.dataType);
  return (
    <div key={indexKey} className="pb-9">
      <ShipmentLabel>
        {item.label}
        {isEditableAndIncluded(item, part) && (
          <span data-test="shipments-edit-icon" className="cursor-pointer">
            <Edit24
              onClick={() =>
                openModal(
                  item,
                  part.allocated_shipment_id,
                  part[item.key],
                  part.total_estimated_volume,
                )
              }
            />
          </span>
        )}
      </ShipmentLabel>
      <ShipmentValue>{shipmentValue}</ShipmentValue>
      {!!item.metric && !!part[item.key] && (
        <ShipmentMetricLabel>{item.metric}</ShipmentMetricLabel>
      )}
    </div>
  );
};

DetailField.propTypes = {
  indexKey: PropTypes.number,
  item: PropTypes.object,
  part: PropTypes.object,
  openModal: PropTypes.func,
};

export default DetailField;
