import React, {useEffect, useMemo, useState} from "react";
import {useParams} from "react-router-dom";
import {Form, Button} from "reactstrap";
import {useMutation, useQuery} from "@apollo/client";
import {Filter24, Info24} from "@bphxd/ds-core-react/lib/icons";

import {useUserSettings} from "providers/userSettings";
import MASS_BALANCE_TOP_FILTERS from "graphql/MassBalance/TopFilters";
import {FormProvider, useForm} from "react-hook-form";
import {useAppSetting} from "providers/appSetting";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting";
import {isEmpty, findIndex, toLower} from "lodash";
import MASS_BALANCE_COUNTS from "graphql/MassBalance/MassBalanceCounts";
import CLOSING_MASS_BALANCE from "graphql/MassBalance/ClosingMassBalance";
import ADD_MB_STOCK_INVENTORY from "graphql/MassBalance/AddMbStockInventory";
import Layout from "../components/Layout";
import DocumentTable from "../components/Table";
import CustomizeTable from "../components/CustomizeTable/CustomizeTable";
import MassBalanceFilterContent from "../MassBalanceFilterContent";
import getColumns from "../utils";
import {
  MASS_BALANCE_CLOSE_ERROR_HEADER,
  MASS_BALANCE_CLOSE_SUCCESS_BODY,
  MASS_BALANCE_CLOSE_SUCCESS_HEADER,
  MASS_BALANCE_CLOSING_API_MODAL,
  MASS_BALANCE_CLOSING_CONFIRMATION_MODAL,
  status,
} from "../constants";
import MassBalanceCloseConfirmationModal from "../components/Modals/MassBalanceCloseConfirmationModal";
import ClosingPopover from "../components/ClosingPopover";
import MassBalanceCloseResponseModal from "../components/Modals/MassBalanceCloseResponseModal";
import ClosingStockTotalAdd from "../components/Modals/ClosingStockTotalAdd";

const tableData = [
  {
    inboundRecord: {
      physicalReceiptDate: "10/03/2024",
      incomingDocument:
        "EU-ISCC-Cert-DE100-01201121-20392570EU-ISCC-Cert-DE100-01201121-20392570EU-ISCC-Cert-DE100-01201121-",
      rawMaterial: "Steel what you want here .....................",
      countryOfOrigin: "India",
      GhgTotalMj: 96,
      actualizedQuantityM3: 1000,
      issuanceDate: "10/03/2024",
      receivedQuantityMt: 100,
      productQtyM3: 1000,
      productQtyMt: 100,
      supplier: "Company A",
      transportSystem: "Truck",
      vesselName: "Vessel A",
      invoiceNumber: "INV-001",
      transportLg: "Truck",
      certified: "Yes",
      actualizedQuantityMt: 100,
      GhgSavings: 100,
      coProcessedQuantityMt: 1000,
    },
    outboundRecords: [
      {
        quantityOutM3: 1000,
        outboundType: "Production",
        recipient: "Company B",
        document: "Production Order",
        quantityOutMt: 100,
        GhgTotalMjOutgoing: 100,
        GhgSavingsOutgoing: 100,
        issuanceDateOutgoing: "10/03/2024",
        product: "Steel",
        transportSystemOutgoing: "Truck",
        vesselNameOutgoing: "Vessel A",
        status: status.PENDING,
      },
    ],
  },
  {
    inboundRecord: {
      physicalReceiptDate: "10/03/2024",
      incomingDocument: "Invoice of the mass balance",
      rawMaterial: "Steel",
      countryOfOrigin: "India",
      GhgTotalMj: 96,
      actualizedQuantityM3: 1000,
      issuanceDate: "10/03/2024",
      receivedQuantityMt: 100,
      productQtyM3: 1000,
      productQtyMt: 100,
      supplier: "Company A",
      transportSystem: "Truck",
      vesselName: "Vessel A",
      invoiceNumber: "INV-001",
      transportLg: "Truck",
      certified: "Yes",
      actualizedQuantityMt: 100,
      GhgSavings: 100,
      coProcessedQuantityMt: 1000,
    },
    outboundRecords: [
      {
        quantityOutM3: 1000,
        outboundType: "Production",
        recipient: "Company B",
        document: "Production Order",
        quantityOutMt: 100,
        GhgTotalMjOutgoing: 100,
        GhgSavingsOutgoing: 100,
        issuanceDateOutgoing: "10/03/2024",
        product: "Steel",
        transportSystemOutgoing: "Truck",
        vesselNameOutgoing: "Vessel A",
        status: status.AVAILABLE,
      },
    ],
  },
  {
    inboundRecord: {
      physicalReceiptDate: "10/03/2024",
      incomingDocument: "Invoice of the mass balance",
      rawMaterial: "Steel",
      countryOfOrigin: "India",
      GhgTotalMj: 96,
      actualizedQuantityM3: 1000,
      issuanceDate: "10/03/2024",
      receivedQuantityMt: 100,
      productQtyM3: 1000,
      productQtyMt: 100,
      supplier: "Company A",
      transportSystem: "Truck",
      vesselName: "Vessel A",
      invoiceNumber: "INV-001",
      transportLg: "Truck",
      certified: "Yes",
      actualizedQuantityMt: 100,
      GhgSavings: 100,
      coProcessedQuantityMt: 1000,
    },
    outboundRecords: [
      {
        quantityOutM3: 1000,
        outboundType: "Production",
        recipient: "Company B",
        document: "Production Order",
        quantityOutMt: 100,
        GhgTotalMjOutgoing: 100,
        GhgSavingsOutgoing: 100,
        issuanceDateOutgoing: "10/03/2024",
        product: "Steel",
        transportSystemOutgoing: "Truck",
        vesselNameOutgoing: "Vessel A",
        status: status.AVAILABLE_CO,
      },
    ],
  },
  {
    inboundRecord: {
      physicalReceiptDate: "10/03/2024",
      incomingDocument: "Invoice of the mass balance",
      rawMaterial: "Steel",
      countryOfOrigin: "India",
      GhgTotalMj: 96,
      actualizedQuantityM3: 1000,
      issuanceDate: "10/03/2024",
      receivedQuantityMt: 100,
      productQtyM3: 1000,
      productQtyMt: 100,
      supplier: "Company A",
      transportSystem: "Truck",
      vesselName: "Vessel A",
      invoiceNumber: "INV-001",
      transportLg: "Truck",
      certified: "Yes",
      actualizedQuantityMt: 100,
      GhgSavings: 100,
      coProcessedQuantityMt: 1000,
    },
    outboundRecords: [
      {
        quantityOutM3: 1000,
        outboundType: "Production",
        recipient: "Company B",
        document: "Production Order",
        quantityOutMt: 100,
        GhgTotalMjOutgoing: 100,
        GhgSavingsOutgoing: 100,
        issuanceDateOutgoing: "10/03/2024",
        product: "Steel",
        transportSystemOutgoing: "Truck",
        vesselNameOutgoing: "Vessel A",
        status: status.ALLOCATED,
      },
    ],
  },
  {
    inboundRecord: {
      physicalReceiptDate: "10/03/2024",
      incomingDocument: "Invoice of the mass balance",
      rawMaterial: "Steel",
      countryOfOrigin: "India",
      GhgTotalMj: 96,
      actualizedQuantityM3: 1000,
      issuanceDate: "10/03/2024",
      receivedQuantityMt: 100,
      productQtyM3: 1000,
      productQtyMt: 100,
      supplier: "Company A",
      transportSystem: "Truck",
      vesselName: "Vessel A",
      invoiceNumber: "INV-001",
      transportLg: "Truck",
      certified: "Yes",
      actualizedQuantityMt: 100,
      GhgSavings: 100,
      coProcessedQuantityMt: 1000,
    },
    outboundRecords: [
      {
        quantityOutM3: 1000,
        outboundType: "Production",
        recipient: "Company B",
        document: "Production Order",
        quantityOutMt: 100,
        GhgTotalMjOutgoing: 100,
        GhgSavingsOutgoing: 100,
        issuanceDateOutgoing: "10/03/2024",
        product: "Steel",
        transportSystemOutgoing: "Truck",
        vesselNameOutgoing: "Vessel A",
        status: status.RETIRED,
      },
    ],
  },
];
const MassBalance = () => {
  const [columnFilter, setColumnFilter] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [open, setOpen] = useState(false);
  const [confirmCloseMassBalance, setConfirmCloseMassBalance] = useState(false);
  const [closeMassBalanceHeader, setCloseMassBalanceHeader] = useState(
    MASS_BALANCE_CLOSE_SUCCESS_HEADER,
  );
  const [closeMassBalanceBody, setCloseMassBalanceBody] = useState(
    MASS_BALANCE_CLOSE_SUCCESS_BODY,
  );

  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  const {appSetting} = useAppSetting();

  const {country, division, type, location, period, balance} = useParams();

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionData = getDivisionData(division.toUpperCase());
  const [showModalAdd, setShowModalAdd] = useState(false);
  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {text: "SAF", link: "/saf/sweden/dashboard"},
    {text: "Sweden", link: "/saf/sweden/dashboard"},
    {text: "Mass balance", link: "/mass-balance"},
  ];

  const tableCols = useMemo(
    () => getColumns(country, type, dateFormat, decimalFormat),
    [dateFormat, decimalFormat, country, type],
  );

  const [columns, setColumns] = useState(tableCols);
  const [defaultValues, setFilterDefaultValues] = useState({});

  const [currentFilterValues, setCurrentFilterValues] = useState(defaultValues);

  const {data: mb_period_instances, loading: topFiltersLoading} = useQuery(
    MASS_BALANCE_TOP_FILTERS,
    {
      fetchPolicy: "network-only",
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
      },
      skip:
        siteReferenceData?.siteReferenceId === undefined ||
        divisionData?.divisionId === undefined,
    },
  );

  const filterDynamicData = useMemo(
    () => mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances,
    [mb_period_instances?.bioLcGetMBFilterOptionsApi?.mbPeriodInstances],
  );

  const firstSelectionItem = useMemo(() => {
    const index = findIndex(filterDynamicData ?? [], (item) => {
      return (
        toLower(item?.mbBalanceType?.mbBalanceTypeCode) === toLower(type) &&
        toLower(item?.mbLocationGroup?.mbLocationGroupName) ===
          toLower(location) &&
        toLower(item?.mbPeriod?.mbPeriodName) === toLower(period) &&
        toLower(item?.mbBalanceGroup?.mbBalanceGroupName) === toLower(balance)
      );
    });
    return filterDynamicData?.[index];
  }, [balance, filterDynamicData, location, period, type]);

  useEffect(() => {
    if (!isEmpty(firstSelectionItem)) {
      setFilterDefaultValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbBalanceTypeId: firstSelectionItem?.mbBalanceType?.mbBalanceTypeId,
          mbLocationGroupId:
            firstSelectionItem?.mbLocationGroup?.mbLocationGroupId,
          mbPeriodId: firstSelectionItem?.mbPeriod?.mbPeriodId,
          mbBalanceGroupId:
            firstSelectionItem?.mbBalanceGroup?.mbBalanceGroupId,
        };
      });

      setCurrentFilterValues((defaultValuesItem) => {
        return {
          ...defaultValuesItem,
          mbBalanceTypeId: firstSelectionItem?.mbBalanceType?.mbBalanceTypeId,
          mbLocationGroupId:
            firstSelectionItem?.mbLocationGroup?.mbLocationGroupId,
          mbPeriodId: firstSelectionItem?.mbPeriod?.mbPeriodId,
          mbBalanceGroupId:
            firstSelectionItem?.mbBalanceGroup?.mbBalanceGroupId,
        };
      });
    }
  }, [firstSelectionItem]);

  useEffect(() => {
    if (tableCols) {
      setColumns(tableCols);
    }
  }, [tableCols]);

  const filteredColumns = useMemo(() => {
    return columns.map((column) => ({
      ...column,
      columns: column.columns.filter(({visible}) => visible),
    }));
  }, [columns]);

  const methods = useForm({});
  const {getValues} = methods;

  const handleFilterSubmit = async () => {
    const {mbBalanceTypeId, mbLocationGroupId, mbPeriodId, mbBalanceGroupId} =
      getValues();

    setCurrentFilterValues((defaultValuesItem) => {
      return {
        ...defaultValuesItem,
        mbBalanceTypeId,
        mbLocationGroupId,
        mbPeriodId,
        mbBalanceGroupId,
      };
    });
  };

  const {data: massBalanceCounts} = useQuery(MASS_BALANCE_COUNTS, {
    fetchPolicy: "network-only",
    variables: {
      siteReferenceId: siteReferenceData?.siteReferenceId,
      divisionId: divisionData?.divisionId,
      mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
      mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
      mbPeriodStatusId: currentFilterValues?.mbPeriodId,
      mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
    },
    skip:
      siteReferenceData?.siteReferenceId === undefined ||
      divisionData?.divisionId === undefined ||
      !currentFilterValues?.mbBalanceTypeId ||
      !currentFilterValues?.mbBalanceTypeId ||
      !currentFilterValues?.mbLocationGroupId ||
      !currentFilterValues?.mbPeriodId,
  });

  const openingCount = useMemo(
    () => massBalanceCounts?.bioLcGetMassBalanceCounts?.openingCount,
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.openingCount],
  );

  const incomingCount = useMemo(
    () => massBalanceCounts?.bioLcGetMassBalanceCounts?.incomingCount,
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.incomingCount],
  );

  const outgoingCount = useMemo(
    () => massBalanceCounts?.bioLcGetMassBalanceCounts?.outgoingCount,
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.outgoingCount],
  );

  const currentCount = useMemo(
    () => massBalanceCounts?.bioLcGetMassBalanceCounts?.currentCount,
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.currentCount],
  );

  const physicalStock = useMemo(
    () => massBalanceCounts?.bioLcGetMassBalanceCounts?.physicalStock,
    [massBalanceCounts?.bioLcGetMassBalanceCounts?.physicalStock],
  );

  const [closeBalance, {loading: physicalStockLoading}] = useMutation(
    ADD_MB_STOCK_INVENTORY,
    {
      onCompleted: ({bioLcUpdateMBPhysicalStockApi}) => {
        if (bioLcUpdateMBPhysicalStockApi?.statusCode === 200) {
          setShowModalAdd(false);
        }
        if (bioLcUpdateMBPhysicalStockApi?.statusCode === 500) {
          setShowModalAdd(false);
        }
      },
    },
  );

  const [closingMassBalanceApi, {loading: closeApiLoading}] = useMutation(
    CLOSING_MASS_BALANCE,
    {
      onCompleted: ({bioLcMassBalanceClosing}) => {
        if (bioLcMassBalanceClosing.statusCode === 500) {
          setCloseMassBalanceHeader(MASS_BALANCE_CLOSE_ERROR_HEADER);
          setCloseMassBalanceBody(bioLcMassBalanceClosing.error);
          setConfirmCloseMassBalance(MASS_BALANCE_CLOSING_API_MODAL);
        }
        if (bioLcMassBalanceClosing.statusCode === 200) {
          setCloseMassBalanceHeader(MASS_BALANCE_CLOSE_SUCCESS_HEADER);
          setCloseMassBalanceBody(MASS_BALANCE_CLOSE_SUCCESS_BODY);
          setConfirmCloseMassBalance(MASS_BALANCE_CLOSING_API_MODAL);
        }
      },
    },
  );

  const handleClosingMassBalance = () => {
    closingMassBalanceApi({
      variables: {
        siteReferenceId: siteReferenceData?.siteReferenceId,
        divisionId: divisionData?.divisionId,
        mbBalanceTypeId: currentFilterValues?.mbBalanceTypeId,
        mbLocationGroupId: currentFilterValues?.mbLocationGroupId,
        mbPeriodStatusId: currentFilterValues?.mbPeriodId,
        mbBalanceGroupId: currentFilterValues?.mbBalanceGroupId,
      },
    });
  };

  return (
    <Layout title="Mass balance" breadcrumbs={breadcrumbItems}>
      {/* Top Filters */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 pt-[32px]">
        <div className=" flex flex-row justify-between w-full mb-[24px]">
          <div className="flex flex-col justify-stretch w-full items-start">
            <FormProvider {...methods}>
              <Form id="mb-filter-form">
                <MassBalanceFilterContent
                  defaultValues={defaultValues}
                  filterDynamicData={filterDynamicData}
                  handleFilterSubmit={handleFilterSubmit}
                ></MassBalanceFilterContent>
              </Form>
            </FormProvider>
          </div>
          <div className="flex flex-none flex-row ml-5 mb-[8px] items-end">
            <Button
              data-test="allocate-outgoing-button"
              color="standard-tertiary rounded-0"
              type="button"
            >
              Allocate outgoing
            </Button>
          </div>
        </div>
      </div>
      {/* Customise columns, table, aggregate view */}
      <div className="flex flex-col justify-stretch w-full items-start px-7">
        <div className=" flex flex-row justify-between w-full p-4 bg-white">
          <div>
            <CustomizeTable
              columns={columns}
              onColumnChange={(cols) => setColumns(cols)}
            />
          </div>
          <div className="flex flex-none flex-row items-center">
            {columnFilter.length > 0 && (
              <div className="mr-2">
                <Button
                  data-test="copro-filter-btn"
                  color="standard-tertiary rounded-0"
                  onClick={() => setColumnFilter([])}
                >
                  Clear
                </Button>
              </div>
            )}
            <div>
              <Button
                className={showFilter ? "border-black" : ""}
                data-test="copro-filter-btn"
                color="standard-tertiary rounded-0"
                onClick={() => setShowFilter(!showFilter)}
              >
                Filters
                <Filter24 className="btn-icon-suffix" />
              </Button>
            </div>
          </div>
        </div>
        <div className="w-full h-[calc(100vh-595px)] bg-white overflow-y-auto overflow-x-auto">
          <DocumentTable
            data={tableData}
            columns={filteredColumns}
            loading={topFiltersLoading}
            type={type}
            showFilter={showFilter}
            columnFilter={columnFilter}
            setColumnFilter={setColumnFilter}
          />
        </div>
        <div className="w-full flex items-center h-20 pb-5 border-t-2 border-t-[#eee] bg-white">
          <div className="w-1/2 text-end mr-4 h-7">
            <span className="text-gray-700 mr-[10px]">Opening</span>
            <span className="mr-[50px]">{openingCount} MT</span>
            <span className="text-gray-700 mr-[10px]">Incoming</span>
            <span>{incomingCount} MT</span>
          </div>

          <div className="w-1/2 text-end mr-4 mt-4">
            <div className="flex justify-end">
              <div className="flex flex-row mr-4">
                <span className="text-gray-700 mr-[10px]">Outgoing</span>
                <span className="mr-[50px]">{outgoingCount} MT</span>

                <div className="flex flex-col">
                  <div className="flex flex-row mr-4 h-7">
                    <span className="text-gray-700 mr-[10px]">Current</span>
                    <span>{currentCount} MT</span>
                  </div>

                  <span className="text-sm h-5 pb-4">
                    Closing stock total:{" "}
                    <button
                      type="button"
                      className="!bg-white !text-black !border-none !underline"
                      onClick={() =>
                        setShowModalAdd((showModalAdd) => !showModalAdd)
                      }
                    >
                      {physicalStock || "Add"}
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Bottom button */}
      <div className="flex flex-col justify-stretch w-full items-start px-7 mt-6">
        <div className=" flex flex-row justify-end w-full mb-5">
          <span className="flex items-center">How to close mass balance</span>
          <div className="flex items-center mr-[17px]">
            <Info24
              id="mass-balance-close-btn"
              onClick={() => setOpen(!open)}
            />
          </div>
          <ClosingPopover open={open} type={type} />
          <Button
            color="primary"
            type="secondary"
            className="float-right rounded-0 text-gray-400"
            size="md"
            onClick={() =>
              setConfirmCloseMassBalance(
                MASS_BALANCE_CLOSING_CONFIRMATION_MODAL,
              )
            }
          >
            Close balance
          </Button>
        </div>
      </div>
      <ClosingStockTotalAdd
        setShowModalAdd={setShowModalAdd}
        title={
          physicalStock ? "Confirm stock inventory" : "Add closing stock total"
        }
        closeBalance={closeBalance}
        mbPeriodId={currentFilterValues?.mbPeriodId}
        showModalAdd={showModalAdd}
        physicalStock={physicalStock}
        loading={physicalStockLoading}
      />
      <MassBalanceCloseConfirmationModal
        showResponse={
          confirmCloseMassBalance === MASS_BALANCE_CLOSING_CONFIRMATION_MODAL
        }
        setShowResponse={setConfirmCloseMassBalance}
        period={period}
        onSubmit={handleClosingMassBalance}
        loading={closeApiLoading}
      />
      <MassBalanceCloseResponseModal
        showResponse={
          confirmCloseMassBalance === MASS_BALANCE_CLOSING_API_MODAL
        }
        setShowResponse={setConfirmCloseMassBalance}
        headerData={closeMassBalanceHeader}
        bodyData={closeMassBalanceBody}
      />
    </Layout>
  );
};

export default MassBalance;
