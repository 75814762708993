import React from "react";
import {Route, Routes, Outlet} from "react-router-dom";
import {useParams} from "react-router";
import NotFoundPage from "modules/common/ErrorPages/NotFoundPage";
import ComplianceEngineRootLayout from "./components/ComplianceEngineRootLayout";
import PrimaryPage from "./pages/primaryPage";
import RulesPage from "./pages/rulesPage";

export default () => {
  const {country} = useParams();
  return (
    <div>
      <Routes>
        <Route element={<ComplianceEngineRootLayout />}>
          <Route index element={<PrimaryPage />} />{" "}
          <Route path="/rules" element={<RulesPage />} />
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>

      <Outlet />
    </div>
  );
};
