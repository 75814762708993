import {useAppSetting} from "providers/appSetting";

export const getSiteDetails = (countryId) => {
  const {appSetting} = useAppSetting();
  const siteData = appSetting?.siteData;
  const country_id = String(countryId);
  const siteReferenceData = siteData.find(
    (site) => site.countryId === country_id,
  );
  return siteReferenceData;
};

export const getDivisionData = (divisionCode) => {
  const {appSetting} = useAppSetting();
  const divisionData = appSetting?.divisionData;
  const filtereddivision = divisionData.find(
    (data) => data.divisionCode === divisionCode,
  );
  return filtereddivision;
};

export const setCountryDetails = async (countryName) => {
  const {appSetting, setAppSetting} = useAppSetting();

  if (
    appSetting?.currentCountry?.toLowerCase() === "global" &&
    appSetting?.featureMappingData &&
    countryName?.length > 0
  ) {
    const countryFeatureData = appSetting?.featureMappingData?.find(
      (item) => item.countryName.toLowerCase() === countryName.toLowerCase(),
    );
    setAppSetting({
      ...appSetting,
      currentCountry: countryName?.toUpperCase(),
      currentCountryMappingData: countryFeatureData,
    });
  }
};
export default {getDivisionData, getSiteDetails};
