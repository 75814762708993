import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Input} from "reactstrap";
import {getKeyNames, getUniqueLists} from "modules/GlobalMassBalance/utils";

const FilterSelectField = ({column, columnFilterValue, data}) => {
  const [dropDownData, setDropDownData] = useState([]);

  useEffect(() => {
    if (column) {
      const {parentKeyName, keyName} = getKeyNames(column);
      const dropData = getUniqueLists(data, `${parentKeyName}.${keyName}`);
      setDropDownData(dropData?.[keyName]);
    }
  }, [column, data]);

  return (
    <Input
      className="bg-white"
      type="select"
      onChange={(e) => {
        column.setFilterValue(e.target.value);
      }}
      value={columnFilterValue || ""}
    >
      <option value="">All</option>
      {dropDownData?.map((item) => (
        <option key={item} value={item}>
          {item}
        </option>
      ))}
    </Input>
  );
};

FilterSelectField.propTypes = {
  column: PropTypes.object,
  columnFilterValue: PropTypes.any,
  data: PropTypes.array,
};

export default FilterSelectField;
