import _ from "lodash";
import {
  DIV_CODE_COPRO,
  DIV_CODE_GF,
  DIV_CODE_SAF,
} from "constants/divisionDetails";
import {
  COPROCESSED_RECORD,
  DATE_RANGE_SIZE,
  actionItems,
  countryNames,
  massBalanceType,
  parentMapping,
  status,
} from "./constants";

const {
  getColumnsTR,
  getColumnsCPP,
  getColumnsTRSWithUK,
} = require("./components/Table/columns");

const getColumns = (country, type, dateFormat, decimalFormat) => {
  switch (type) {
    case massBalanceType.TR:
      return getColumnsTR(dateFormat, decimalFormat, country);
    case massBalanceType.TRS:
      if (country === countryNames.UK) {
        return getColumnsTRSWithUK(dateFormat, decimalFormat, country);
      }
      return getColumnsTR(dateFormat, decimalFormat, country);
    case massBalanceType.CPP:
      return getColumnsCPP(dateFormat, decimalFormat, country);
    default:
      return getColumnsTR(dateFormat, decimalFormat, country);
  }
};

export const getFilterValue = (cell, filterId) => {
  const context = cell.getContext();
  const tableState = context ? context.table.getState() : null;
  const columnFilters = tableState ? tableState.columnFilters : [];

  const filterItem = columnFilters.find((item) => item.id === filterId);

  return filterItem ? filterItem.value : null;
};

export const getStatusColor = (statusValue) => {
  switch (statusValue) {
    case status.PENDING:
      return "bg-[#fffff0]";
    case status.AVAILABLE:
      return "bg-[#fffff0]";
    case status.AVAILABLE_CO:
      return "bg-[#fffff0]";
    case status.ALLOCATED:
      return "bg-[#ecfafe]";
    case status.ALLOCATED_AND_SENT:
      return "bg-[#ecfafe]";
    case status.RESERVED:
      return "bg-[#ecfafe]";
    case status.TRANSFERRED:
      return "bg-[#ecfafe]";
    case status.RETIRED:
      return "bg-[#ecfafe]";
    case status.WRITE_OFF:
      return "bg-[#ededed]";
    case status.CARRIED_OVER:
      return "bg-[#ededed]";
    case status.INVALID_PURCHASE:
      return "bg-[#ffedf0]";
    case status.INVALID_OUTGOING:
      return "bg-[#ffedf0]";
    default:
      return "bg-[#fffff0]";
  }
};

export const getStatusValue = (statusValue) => {
  switch (statusValue) {
    case status.PENDING:
      return "Pending";
    case status.AVAILABLE:
      return "Available";
    case status.AVAILABLE_CO:
      return "Available from carry over";
    case status.ALLOCATED:
      return "Allocated";
    case status.ALLOCATED_AND_SENT:
      return "Allocated & sent";
    case status.RESERVED:
      return "Reserved";
    case status.TRANSFERRED:
      return "Transferred";
    case status.RETIRED:
      return "retired";
    case status.WRITE_OFF:
      return "Written off";
    case status.CARRIED_OVER:
      return "Carried over";
    case status.INVALID_PURCHASE:
      return "Invalid purchase";
    case status.INVALID_OUTGOING:
      return "Invalid Outgoing";
    default:
      return "Pending";
  }
};

export const getUniqueLists = (data, keyPaths) => {
  const result = {};
  let newKeyPaths = keyPaths;

  // Ensure keyPaths is an array, even if a single string is passed
  if (typeof newKeyPaths === "string") {
    newKeyPaths = [newKeyPaths];
  }

  newKeyPaths.forEach((keyPath) => {
    const keys = keyPath.split(".");

    // Extract all values for the given keyPath using reduce and flatMap
    const values = _.flatMap(data, (item) =>
      keys.reduce((value, key) => {
        if (Array.isArray(value)) {
          return _.flatMap(value, (v) => v[key]);
        }
        return value ? value[key] : undefined;
      }, item),
    );

    // Handle cases where values can be objects or arrays
    const uniqueValues = _.uniqWith(values.filter(Boolean), _.isEqual);

    // Use the last part of the keyPath as the key for the result object
    const key = keys[keys.length - 1];
    result[key] = uniqueValues;
  });

  return result;
};

export const getKeyNames = (column) => {
  const parentKeyName = parentMapping[column?.parent?.id] || COPROCESSED_RECORD;
  const keyName = column.id;
  return {parentKeyName, keyName};
};

export function createDateFromDDMMYYYY(dateString) {
  const parts = dateString.split("/");
  // Ensure parts array has 3 elements
  if (parts.length !== 3) {
    throw new Error(
      "Invalid date format. Please provide date in DD/MM/YYYY format.",
    );
  }

  // Parse day, month, and year from parts array
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10) - 1; // Month is 0-indexed in JavaScript
  const year = parseInt(parts[2], 10);

  // Create a new Date object
  return new Date(year, month, day);
}

export const customDateFilterFn = (row, columnId, value) => {
  if (value && value.length === DATE_RANGE_SIZE && row.getValue(columnId)) {
    const startDate = createDateFromDDMMYYYY(value[0]);
    const endDate = createDateFromDDMMYYYY(value[1]);
    // Below will be used in future task 390502
    // const date = createDateFromDDMMYYYY(
    //   moment(row.getValue(columnId)).format("DD/MM/YYYY"),
    // );
    const date = createDateFromDDMMYYYY(row.getValue(columnId));
    return date >= startDate && date <= endDate;
  }
  return true;
};

export const customDateFilterFnOutgoing = (rowData, columnId, value) => {
  if (value && value.length === DATE_RANGE_SIZE && rowData) {
    const startDate = createDateFromDDMMYYYY(value[0]);
    const endDate = createDateFromDDMMYYYY(value[1]);
    // Below will be used in future task 390502
    // const date = createDateFromDDMMYYYY(
    //   moment(row.getValue(columnId)).format("DD/MM/YYYY"),
    // );
    const date = createDateFromDDMMYYYY(rowData);
    return date >= startDate && date <= endDate;
  }
  return true;
};

const {
  LINK_DOCUMENT,
  VIEW_DETAILS,
  UNLINK_DOCUMENT,
  ALLOCATE,
  EDIT_RESERVATION,
  GENERATE_DOCUMENT,
  REMOVE_ALLOCATION,
  REMOVE_DOCUMENT,
  REMOVE_FROM_BALANCE,
  REMOVE_OUTGOING_TRANSACTION,
  REMOVE_PURCHASE,
  REMOVE_RESERVATION,
  REMOVE_WRITE_OFF,
  RESERVE,
  RETIRE_FOR_MANDATE,
  TRANSFER,
  UNRETIRE_FOR_MANDATE,
  WRITE_OFF,
} = actionItems;

export const actionConfig = {
  [status.PENDING + DIV_CODE_SAF]: {
    visible: [LINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, WRITE_OFF],
    enabled: [LINK_DOCUMENT],
  },
  [status.AVAILABLE + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, WRITE_OFF],
    enabled: [UNLINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, WRITE_OFF],
  },
  [status.AVAILABLE + DIV_CODE_COPRO]: {
    visible: [VIEW_DETAILS, ALLOCATE, RESERVE, TRANSFER, WRITE_OFF],
    enabled: [VIEW_DETAILS, ALLOCATE, RESERVE, TRANSFER, WRITE_OFF],
  },
  [status.AVAILABLE + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
  },
  [status.RESERVED + DIV_CODE_COPRO]: {
    visible: [
      VIEW_DETAILS,
      ALLOCATE,
      EDIT_RESERVATION,
      REMOVE_RESERVATION,
      TRANSFER,
      WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, ALLOCATE, EDIT_RESERVATION, REMOVE_RESERVATION],
  },
  [status.ALLOCATED + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      GENERATE_DOCUMENT,
    ],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION, GENERATE_DOCUMENT],
  },
  [status.ALLOCATED + DIV_CODE_COPRO]: {
    visible: [VIEW_DETAILS, REMOVE_ALLOCATION, RESERVE, TRANSFER, WRITE_OFF],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION],
  },
  [status.ALLOCATED + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, REMOVE_ALLOCATION, RETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION],
  },
  [status.RETIRED + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, UNRETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS, UNRETIRE_FOR_MANDATE],
  },
  [status.RETIRED + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, REMOVE_ALLOCATION, UNRETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS, UNRETIRE_FOR_MANDATE],
  },
  [status.TRANSFERRED + DIV_CODE_COPRO]: {
    visible: [VIEW_DETAILS, ALLOCATE, RESERVE, TRANSFER, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.GENERATED + DIV_CODE_SAF]: {
    visible: [
      UNLINK_DOCUMENT,
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      REMOVE_DOCUMENT,
    ],
    enabled: [VIEW_DETAILS, REMOVE_ALLOCATION, REMOVE_DOCUMENT],
  },
  [status.WRITE_OFF + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, REMOVE_WRITE_OFF],
    enabled: [VIEW_DETAILS, ALLOCATE, REMOVE_WRITE_OFF],
  },
  [status.WRITE_OFF + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, REMOVE_WRITE_OFF],
    enabled: [VIEW_DETAILS, ALLOCATE, REMOVE_WRITE_OFF],
  },
  [status.WRITE_OFF + DIV_CODE_COPRO]: {
    visible: [VIEW_DETAILS, ALLOCATE, RESERVE, TRANSFER, REMOVE_WRITE_OFF],
    enabled: [VIEW_DETAILS, REMOVE_WRITE_OFF],
  },
  [status.WRITE_OFF + DIV_CODE_GF]: {
    visible: [
      VIEW_DETAILS,
      REMOVE_ALLOCATION,
      RETIRE_FOR_MANDATE,
      REMOVE_WRITE_OFF,
    ],
    enabled: [VIEW_DETAILS, REMOVE_WRITE_OFF],
  },
  [status.AVAILABLE_CO + DIV_CODE_SAF]: {
    visible: [VIEW_DETAILS, ALLOCATE, WRITE_OFF, REMOVE_FROM_BALANCE],
    enabled: [VIEW_DETAILS, ALLOCATE, WRITE_OFF, REMOVE_FROM_BALANCE],
  },
  [status.AVAILABLE_CO + DIV_CODE_COPRO]: {
    visible: [
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
    ],
    enabled: [
      VIEW_DETAILS,
      ALLOCATE,
      RESERVE,
      TRANSFER,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
    ],
  },
  [status.AVAILABLE_CO + DIV_CODE_GF]: {
    visible: [
      VIEW_DETAILS,
      ALLOCATE,
      RETIRE_FOR_MANDATE,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
    ],
    enabled: [
      VIEW_DETAILS,
      ALLOCATE,
      RETIRE_FOR_MANDATE,
      WRITE_OFF,
      REMOVE_FROM_BALANCE,
    ],
  },
  [status.CARRIED_OVER + DIV_CODE_SAF]: {
    visible: [UNLINK_DOCUMENT, VIEW_DETAILS, ALLOCATE, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.CARRIED_OVER + DIV_CODE_COPRO]: {
    visible: [VIEW_DETAILS, ALLOCATE, RESERVE, TRANSFER, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.CARRIED_OVER + DIV_CODE_GF]: {
    visible: [VIEW_DETAILS, ALLOCATE, RETIRE_FOR_MANDATE, WRITE_OFF],
    enabled: [VIEW_DETAILS],
  },
  [status.INVALID_PURCHASE + DIV_CODE_SAF]: {
    visible: [REMOVE_PURCHASE],
    enabled: [REMOVE_PURCHASE],
  },
  [status.INVALID_OUTGOING + DIV_CODE_SAF]: {
    visible: [REMOVE_OUTGOING_TRANSACTION],
    enabled: [REMOVE_OUTGOING_TRANSACTION],
  },
};

export const linkAction = (division, setModal, actionName) => {
  // Handle for common cases
  if (actionName === LINK_DOCUMENT) {
    setModal(actionName);
    return;
  }
  // Handle for division specific cases
  switch (division) {
    case DIV_CODE_SAF:
      setModal(actionName);
      break;
    default:
      break;
  }
};

export const checkFilter = (
  data,
  filterData,
  isOutgoingFilter,
  OUTGOING_COLUMN_FILTER_IDS,
  OUTGOING_COLUMN_DATE_FILTER_IDS,
) => {
  if (isOutgoingFilter) {
    return data.some((record) =>
      record.outboundRecords.some((outboundRecord) =>
        filterData.every((filter) => {
          const key = filter?.id;
          const value = filter?.value;
          // Check for string match filters
          if (OUTGOING_COLUMN_FILTER_IDS.includes(key)) {
            return outboundRecord[key]
              ?.toString()
              .toLowerCase()
              .includes(value?.toLowerCase());
          }
          // Check for date filters
          if (OUTGOING_COLUMN_DATE_FILTER_IDS.includes(key)) {
            return customDateFilterFnOutgoing(outboundRecord[key], key, value);
          }
          // If the filter doesn't match any known conditions, consider it passed (true)
          return true;
        }),
      ),
    );
  }
  return true;
};

export default getColumns;
