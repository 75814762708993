import React from "react";
import PropTypes from "prop-types";
import {Popover, PopoverHeader, PopoverBody} from "reactstrap";
import {massBalanceType} from "../constants";

const ClosingPopover = ({open, type}) => {
  return (
    <Popover fade placement="top" target="mass-balance-close-btn" isOpen={open}>
      <PopoverHeader>
        {type === massBalanceType.TR && "Close a trader only mass balance"}
        {type === massBalanceType.TRS &&
          "Close a trader with storage mass balance"}
      </PopoverHeader>
      <PopoverBody>
        {type === massBalanceType.TR && (
          <>
            <div>1. The mass balance period needs to have ended</div>
            <div>2. All purchases must be actualized and allocated</div>
            <div>
              3. Outgoing transactions must be allocated to all purchases and
              all outgoing documents must be generated
            </div>
            <div>4. The closing balance must be zero</div>
          </>
        )}
        {type === massBalanceType.TRS && (
          <>
            <div>1. The mass balance period needs to have ended</div>
            <div>
              2. The previous mass balance period for the same location needs to
              have been closed
            </div>
            <div>3. All purchases must be actualized</div>
            <div>
              4. Outgoing transactions must be allocated to purchases and all
              outgoing documents must be generated
            </div>
          </>
        )}
      </PopoverBody>
    </Popover>
  );
};

ClosingPopover.propTypes = {
  open: PropTypes.bool,
  type: PropTypes.string,
};

export default ClosingPopover;
