const siteRefId = {
  pl: "94880bda83bda77c5692876700711f15",
  uk: "89f9c9f489be2a83cf57e53b9197d288",
};

export const divisionId = "c07b0b4d7660314f711a68fc47c4ab38";
export const siteReferenceId = "89f9c9f489be2a83cf57e53b9197d288";
export const certificateInboundEuId = "4e4067d1dfc06b12e24af0b4c7282c1c";

export default siteRefId;
