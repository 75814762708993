import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap";
import {useState, useEffect} from "react";
import {Close} from "@bphxd/ds-core-react";
import PropTypes from "prop-types";

const TestModal = ({isVisibleModal, rowData}) => {
  const [isModalVisible, setModalVisible] = useState(isVisibleModal);

  useEffect(() => {
    setModalVisible(isVisibleModal);
  }, [isVisibleModal]);

  return (
    <div>
      <Modal
        size="sm"
        isOpen={isModalVisible}
        className="modal-dialog-centered"
      >
        <ModalHeader
          className="border-b-2 border-gray-200 mb-5 text-xl"
          close={<Close onClick={() => setModalVisible(false)} />}
        >
          Test
        </ModalHeader>
        <ModalBody className="text-center pt-0">Test Modal Body</ModalBody>
        <ModalFooter>Test Footer</ModalFooter>
      </Modal>
    </div>
  );
};

TestModal.propTypes = {
  isVisibleModal: PropTypes.bool,
  rowData: PropTypes.object,
};

export default TestModal;
