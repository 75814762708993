import {useMemo, useState} from "react";
import {Kebab24} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import {useParams} from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";

import {useMsal, useAccount} from "@azure/msal-react";
import {useAppSetting} from "providers/appSetting/context.js";
import {
  getSiteDetails,
  getDivisionData,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";
import {actionConfig, linkAction} from "../utils";
import ModalToShow from "./ModalToShow";
import {actionItems} from "../constants";

const ActionDropdown = ({row, table}) => {
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const [actionModalVisible, setActionModalVisible] = useState("");

  const {appSetting} = useAppSetting();
  const {country: countryName, division} = useParams();
  setCountryDetails(countryName);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);
  const divisionCode = division.toUpperCase();
  const divisionData = getDivisionData(divisionCode);

  const rowStatus = row?.original?.outboundRecords?.status;

  const ACTIONS = useMemo(
    () =>
      Object.values(actionItems).map((action) => ({
        name: action,
        isVisible:
          actionConfig[rowStatus + divisionCode]?.visible.includes(action),
        isDisabled:
          !actionConfig[rowStatus + divisionCode]?.enabled.includes(action),
        action: () => linkAction(divisionCode, setActionModalVisible, action),
      })),
    [divisionCode, rowStatus],
  );

  return (
    <>
      <UncontrolledDropdown direction="start">
        <DropdownToggle
          color="standard-quartenary"
          className="!px-0"
          caret
          onClick={() => setActionModalVisible("")}
        >
          <Kebab24 />
        </DropdownToggle>
        <DropdownMenu container="body">
          {ACTIONS.filter((action) => action.isVisible).map((action) => (
            <DropdownItem
              key={action.name}
              onClick={action.action}
              disabled={action.isDisabled}
              data-test={`data-test-mb-table-action-${action.name}`}
            >
              {action.name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
      {actionModalVisible && (
        <ModalToShow modal={actionModalVisible} rowData={row.original} />
      )}
    </>
  );
};

ActionDropdown.propTypes = {
  row: PropTypes.object,
  table: PropTypes.object,
};

export default ActionDropdown;
