/* eslint-disable jsx-a11y/click-events-have-key-events */
import PropTypes from "prop-types";
import {useLazyQuery} from "@apollo/client";
import {useState, useMemo, useCallback, useEffect} from "react";
import {toast} from "react-toastify";
import {
  Card,
  CardHeader,
  CardBody,
  Collapse,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {useNavigate} from "react-router-dom";
import {Down32, Up32, Kebab24} from "@bphxd/ds-core-react/lib/icons";
import {
  COPRO_US_SHIPMENTS_API_ALLOCATE_BATCHES_AND_SHIPMENTS,
  COPRO_US_SHIPMENTS_API_ALLOCATE_BATCH_TO_SHIPMENT,
} from "graphql/coprocessing/shipments";
import {
  TITLES,
  BUTTONS,
  ACTION_OPTIONS,
  SHIPMENT_TYPE,
  BANNER_TYPE,
  ERRORS,
  SHIPMENT_SUCCESS,
} from "modules/co-processing/constants/shipments";
import {COPRO_URL} from "modules/co-processing/constants/coProcessing";
import ActiveBatchCard from "modules/co-processing/components/Shipments/ActiveBatchCard";
import ShipmentCard from "modules/co-processing/components/Shipments/ShipmentCard";
import Banner from "modules/co-processing/components/Shipments/Banner";
import CopyToClipboard from "modules/co-processing/components/Shipments/CopyToClipboard";
import buildAllocationDetails from "modules/co-processing/helpers/buildAllocationDetails";
import combineShipments from "modules/co-processing/helpers/combineShipments";
import BatchesStatusChip from "../Shared/BatchesStatusChip";
import BOLModal from "./BOLModals/BOLModal";
import SplitShipmentsModal from "./SplitModal/SplitShipment";
import ShipmentsDeletePopover from "./ShipmentsDeletePopover";
import BatchChecklistProgress from "../Shared/BatchChecklist/BatchChecklistProgress";
import "./index.scss";

const extractStatusDetails = (batchDetails) => {
  return {
    batch_created_status: batchDetails?.batch_created_status,
    shipment_linked_status: batchDetails?.shipment_linked_status,
    c14_linked_status: batchDetails?.c14_linked_status,
    feedstock_allocated_status: batchDetails?.feedstock_allocated_status,
    emts_submitted_status: batchDetails?.emts_submitted_status,
    state_submitted_status: batchDetails?.state_submitted_status,
    state_final_uploaded_status: batchDetails?.state_final_uploaded_status,
    completion_percentage: batchDetails?.completion_percentage,
  };
};

const getCardDetails = (type, data) => {
  const isActiveBatches = type === TITLES.ACTIVE_BATCHES;
  return {
    status: data?.[isActiveBatches ? "batch_status" : "shipment_status"],
    cardTitle: data?.[isActiveBatches ? "batch_id" : "shipment_id"],
    isActiveBatches,
  };
};

const getActionsForShipmentType = (
  shipmentType,
  setIsOpenBOLModal,
  setIsOpenSplitModal,
  shipmentStatus,
  setShowPopover,
  navigate,
) => {
  switch (shipmentType) {
    case SHIPMENT_TYPE.PIPELINE:
    case SHIPMENT_TYPE.VESSEL:
      return (
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              navigate();
            }}
          >
            {ACTION_OPTIONS.VIEW_DETAILS}
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenSplitModal(true);
            }}
          >
            {ACTION_OPTIONS.SPLIT_SHIPMENT}
          </DropdownItem>
        </DropdownMenu>
      );
    case SHIPMENT_TYPE.TRUCK_RACK:
      return (
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              navigate();
            }}
          >
            {ACTION_OPTIONS.VIEW_DETAILS}
          </DropdownItem>
          <DropdownItem
            onClick={(e) => {
              e.stopPropagation();
              setIsOpenBOLModal(true);
            }}
          >
            {ACTION_OPTIONS.ALLOCATE_BOLS}
          </DropdownItem>
          <DropdownItem
            disabled={shipmentStatus !== "Active"}
            onClick={(e) => {
              e.stopPropagation();
              setShowPopover(true);
            }}
          >
            {ACTION_OPTIONS.DELETE_SHIPMENT}
          </DropdownItem>
        </DropdownMenu>
      );
    case SHIPMENT_TYPE.BACK_TO_UNIT:
      return (
        <DropdownMenu>
          <DropdownItem
            onClick={() => {
              navigate();
            }}
          >
            {ACTION_OPTIONS.VIEW_DETAILS}
          </DropdownItem>
          <DropdownItem
            disabled={shipmentStatus !== "Active"}
            onClick={(e) => {
              e.stopPropagation();
              setShowPopover(true);
            }}
          >
            {ACTION_OPTIONS.DELETE_SHIPMENT}
          </DropdownItem>
        </DropdownMenu>
      );
    default:
      return <DropdownMenu></DropdownMenu>;
  }
};

const CollapsibleCard = ({
  cardType,
  cardData,
  refreshData,
  refetchShipments,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [formData, setFormData] = useState([]);
  const [cardId, setCardId] = useState({});
  const [errors, setErrors] = useState({});
  const [warnings, setWarnings] = useState({});
  const [isOpenBOLModal, setIsOpenBOLModal] = useState(false);
  const [isOpenSplitModal, setIsOpenSplitModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const toggleSplitModal = () => setIsOpenSplitModal(!isOpenSplitModal);
  const navigate = useNavigate();

  const {status, cardTitle, isActiveBatches} = useMemo(
    () => getCardDetails(cardType, cardData),
    [cardType, cardData],
  );

  const updateValues = useCallback((event, formId) => {
    const {name, value} = event.target;
    const idKey = formId.shipment_id ? "shipment_id" : "batch_id";
    const idValue = formId[idKey];

    setFormData((prevData) => {
      return buildAllocationDetails(prevData, name, value, idKey, idValue);
    });
  }, []);

  const updateErrors = useCallback((dataKey, error) => {
    setErrors((prevErrors) => ({...prevErrors, [dataKey]: error}));
  }, []);

  const updateWarnings = useCallback((dataKey, error, id) => {
    setWarnings((prevErrors) => ({...prevErrors, [dataKey]: error}));
    setCardId(id);
  }, []);

  const navigateToDetailsScreen = () => {
    navigate(`${COPRO_URL}/shipments/details`, {
      state: {
        id: cardData.shipment_id,
        details: cardData,
      },
    });
  };
  const [allocateBatchtoShipment, {data: res}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_ALLOCATE_BATCH_TO_SHIPMENT,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (!res) return {};
        const {error, statusCode} = res.bioLcCoproUsShipmentsApi;
        if (error) {
          const errorMessage =
            statusCode === 400
              ? `${ERRORS.FAILED_ALLOCATION}. ${error}`
              : ERRORS.FAILED_ALLOCATION;
          return toast.error(errorMessage);
        }
        toast.success(SHIPMENT_SUCCESS);
        return refreshData();
      },
    },
  );

  const disableSaveButton = useMemo(() => {
    const hasErrors = Object.keys(errors).some((key) => errors?.[key]);
    const isFormEmpty = !formData.length;
    return isLoading || hasErrors || isFormEmpty;
  }, [isLoading, errors, formData]);

  const saveValues = () => {
    allocateBatchtoShipment({
      variables: {
        batch_shipment_info: isActiveBatches
          ? formData
          : combineShipments(formData),
      },
    });
  };

  const [allocateBatchesAndShipments, {data: allocateDataRes}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_ALLOCATE_BATCHES_AND_SHIPMENTS,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (allocateDataRes?.error) {
          return toast.error(ERRORS.FAILED_ALLOCATION);
        }
        return refreshData();
      },
    },
  );

  const batchStatuses = useMemo(() => {
    return isActiveBatches ? extractStatusDetails(cardData?.details) : {};
  }, [isActiveBatches, cardData]);

  return (
    <>
      <Card
        id="collapsible-card"
        className="mb-4 bg-white"
        data-test="copro-collapsible-card"
      >
        <CardHeader
          className="bg-white border !border-b-0 flex justify-between items-center"
          data-test="copro-collapsible-card-header"
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className="flex flex-row items-center">
            {isActiveBatches ? (
              <BatchChecklistProgress
                popoverId={cardTitle.replace(".", "-")}
                statusDetails={batchStatuses}
              />
            ) : (
              <BatchesStatusChip value={status} small />
            )}

            <span
              data-test="copro-collapsible-card-title"
              id="copro-collapsible-card-title"
              className="fw-normal px-3"
            >
              {cardTitle}
            </span>
            <CopyToClipboard text={cardTitle} />
          </div>
          <div className="flex flex-row items-center">
            {!isActiveBatches && (
              <>
                <UncontrolledDropdown data-test="shipments-actions-dropdown">
                  <DropdownToggle
                    onClick={(e) => e.stopPropagation()}
                    color="tertiary"
                    className="mr-3 rounded-0 border"
                    disabled={!cardData.shipment_type}
                    data-test="shipments-actions-button"
                    id="shipments-actions-button"
                  >
                    <span className="mt-1">{BUTTONS.ACTIONS}</span>
                    <Kebab24 />
                  </DropdownToggle>
                  {getActionsForShipmentType(
                    cardData.shipment_type,
                    setIsOpenBOLModal,
                    setIsOpenSplitModal,
                    cardData.shipment_status,
                    setShowPopover,
                    navigateToDetailsScreen,
                  )}
                </UncontrolledDropdown>
                <ShipmentsDeletePopover
                  showPopover={showPopover}
                  setShowPopover={setShowPopover}
                  refetchShipments={refetchShipments}
                  shipmentID={cardData.shipment_id}
                />
              </>
            )}
            <div>{isOpen ? <Up32 /> : <Down32 />}</div>
          </div>
        </CardHeader>
        <Collapse isOpen={isOpen}>
          <CardBody className="border" data-test="copro-collapsible-content">
            {Object.values(errors).map((error, index) => (
              <Banner
                key={index}
                errorState={!!error}
                text={error}
                type={BANNER_TYPE.ERROR}
              />
            ))}
            {Object.values(warnings).map((warning, index) => (
              <Banner
                key={index}
                errorState={!!warning}
                text={warning}
                type={BANNER_TYPE.WARNING}
                id={cardId}
              />
            ))}
            {isActiveBatches ? (
              <ActiveBatchCard
                data={cardData}
                handleChange={updateValues}
                handleErrors={updateErrors}
                handleWarnings={updateWarnings}
                handleLoading={setLoading}
                refreshData={refreshData}
              />
            ) : (
              <ShipmentCard
                data={cardData}
                handleChange={updateValues}
                handleErrors={updateErrors}
                handleLoading={setLoading}
                refreshData={refreshData}
              />
            )}
          </CardBody>
          <CardBody className="border flex justify-end">
            <div>
              <Button
                data-test="copro-collapsible-save-button"
                disabled={disableSaveButton}
                className="py-2 rounded-0 enabled:text-gray-800 enabled:bg-white"
                onClick={() => saveValues()}
              >
                {BUTTONS.SAVE}
              </Button>
            </div>
          </CardBody>
        </Collapse>
      </Card>
      {isOpenBOLModal && (
        <BOLModal
          onClose={() => setIsOpenBOLModal(false)}
          shipmentId={cardTitle}
          shipmentData={cardData}
          onSave={() => {
            setIsOpenBOLModal(false);
            refetchShipments();
          }}
        />
      )}

      <SplitShipmentsModal
        isModalOpen={isOpenSplitModal}
        toggleModal={toggleSplitModal}
        cardData={cardData}
        allocateBatchesAndShipments={allocateBatchesAndShipments}
      />
    </>
  );
};

CollapsibleCard.propTypes = {
  cardType: PropTypes.string,
  cardData: PropTypes.any,
  refreshData: PropTypes.func,
  refetchShipments: PropTypes.func,
};

export default CollapsibleCard;
