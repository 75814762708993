import {useMemo, useState} from "react";
import PropTypes from "prop-types";
import {useQuery, useLazyQuery} from "@apollo/client";
import {toast} from "react-toastify";
import {Button} from "reactstrap";
import {
  SHIPMENT_TYPE,
  DETAIL_HEADERS,
  SHIPMENT_SUMMARY_FIELDS_HEADER,
  SHIPMENT_SUMMARY_FIELDS_BODY,
  BUTTONS,
} from "modules/co-processing/constants/shipments";
import {ERRORS} from "modules/co-processing/constants/coProcessing";
import {
  COPRO_US_SHIPMENTS_API_SHIPMENT_ID,
  COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID,
} from "graphql/coprocessing/shipments";
import shipmentDataFormatter from "modules/co-processing/helpers/shipmentDataFormatter";
import DetailField from "modules/co-processing/components/ShipmentDetails/DetailField";
import LoadingSpinner from "modules/co-processing/components/Shared/LoadingSpinner";
import RemoveSplitPopover from "./RemoveSplitPopover";
import EditShipmentModal from "./EditModal";

const ShipmentSummary = ({id}) => {
  const [modal, setModal] = useState(false);
  const [updateDetails, setUpdateDetails] = useState({});
  const [showPopover, setShowPopover] = useState(false);

  const {
    data: res,
    loading,
    refetch,
  } = useQuery(COPRO_US_SHIPMENTS_API_SHIPMENT_ID, {
    variables: {shipmentId: id},
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [editShipment, {data: shipmentData}] = useLazyQuery(
    COPRO_US_SHIPMENTS_API_EDIT_SHIPMENT_ID,
    {
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true,
      onCompleted: () => {
        if (shipmentData?.bioLcCoproUsShipmentsApi?.error) {
          return toast.error(ERRORS.FAILED_EDIT);
        }
        return refetch();
      },
    },
  );

  const data = useMemo(
    () => res?.bioLcCoproUsShipmentsApi?.body?.shipment_details,
    [res],
  );

  const hasRemoveSplitButton = useMemo(() => {
    const hasParts = data?.parts?.length > 1;

    return (
      hasParts &&
      [SHIPMENT_TYPE.PIPELINE, SHIPMENT_TYPE.VESSEL].includes(
        data?.shipment_type,
      )
    );
  }, [data]);

  const formattedDetails = useMemo(() => shipmentDataFormatter(data), [data]);

  const labelIndex = useMemo(
    () => (data?.shipment_type === SHIPMENT_TYPE.TRUCK_RACK ? [0, 6] : [2, 5]),
    [data],
  );

  const saveDetail = (allocated_id, label, value) => {
    const variables = {
      shipmentId: id,
      update_field: {
        allocated_shipment_id: allocated_id,
        field: label,
        value,
      },
    };
    editShipment({variables});
  };

  const openModal = (itemDetails, allocated_id, value, maxValue) => {
    setUpdateDetails(() => ({
      ...itemDetails,
      shipmentType: data?.shipment_type,
      allocated_id,
      value,
      maxValue,
    }));
    setModal(true);
  };

  const renderDetailFields = (fields, part) =>
    fields.map((item, index) => (
      <DetailField
        key={index}
        indexKey={index}
        item={item}
        part={part}
        openModal={openModal}
      />
    ));

  const onRemove = () => {
    setShowPopover(true);
  };

  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div>
          {formattedDetails?.map((part, index) => {
            return (
              <div
                data-test="shipment-summary-card"
                className="bg-white p-5 mb-8 border rounded-lg w-2/3"
                key={index}
              >
                <div
                  data-test="shipment-summary-header"
                  className="flex flex-row justify-between mb-4"
                >
                  <div>
                    <div className="font-medium text-xl pb-3">
                      {part.shipment_id}
                    </div>
                    <div className="text-gray-600 font-light text-lg">
                      {DETAIL_HEADERS.SHIPMENT_SUMMARY}
                    </div>
                  </div>
                  <div>
                    {hasRemoveSplitButton && index === 0 && (
                      <Button
                        className="pb-2 rounded-1 bg-white text-black"
                        id="shipments-remove-button"
                        data-test="copro-remove-splits-btn"
                        onClick={() => onRemove()}
                      >
                        {BUTTONS.REMOVE_SPLIT}
                      </Button>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-4 p-5">
                  {renderDetailFields(SHIPMENT_SUMMARY_FIELDS_HEADER, part)}
                </div>
                <hr />
                <div data-test="shipment-summary-part-details">
                  <div className="grid grid-cols-3 gap-4">
                    {renderDetailFields(
                      SHIPMENT_SUMMARY_FIELDS_BODY.slice(
                        labelIndex[0],
                        labelIndex[1],
                      ),
                      part,
                    )}
                  </div>
                  <div className="flex justify-between">
                    {renderDetailFields(
                      SHIPMENT_SUMMARY_FIELDS_BODY.slice(6, 7),
                      part,
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
      <EditShipmentModal
        details={updateDetails}
        setModal={setModal}
        modal={modal}
        saveDetail={saveDetail}
      />
      {hasRemoveSplitButton && (
        <RemoveSplitPopover
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          refetchShipments={refetch}
          shipmentId={id}
        />
      )}
    </div>
  );
};

ShipmentSummary.propTypes = {
  id: PropTypes.string,
};

export default ShipmentSummary;
