import React from "react";
import {Route, Routes, Outlet} from "react-router-dom";
import CoProcessingRootLayout from "modules/co-processing/components/CoProcessingRootLayout";
import NotFoundPage from "modules/common/ErrorPages/NotFoundPage";
import PrimaryPage from "./pages/receipts/primaryPage";
import BatchesPage from "./pages/batches/primaryPage";
import DocumentPage from "./pages/receipts/documentPage";
import BatchDetails from "./pages/batches/detailsPage";
import ShipmentsPage from "./pages/shipments/primaryPage";
import ShipmentDetails from "./pages/shipments/detailsPage";
import HistoricalData from "./pages/historical/primaryPage";
import CreditGenPage from "./pages/creditGen/primaryPage";
import BulkAdjustment from "./pages/shipments/bulkAdjustmentPage";

export default () => (
  <div>
    <Routes>
      <Route element={<CoProcessingRootLayout />}>
        <Route index element={<PrimaryPage />} />{" "}
        <Route path="/batches" element={<BatchesPage />} />
        <Route path="/batches/details" element={<BatchDetails />} />
        <Route path="/batches/document-viewer" element={<DocumentPage />} />
        <Route path="/shipments" element={<ShipmentsPage />} />
        <Route path="/credit-generation" element={<CreditGenPage />} />
        <Route path="/shipments/details/" element={<ShipmentDetails />} />
        <Route
          path="/shipments/bulk-adjustment/"
          element={<BulkAdjustment />}
        />
        <Route path="/document-viewer" element={<DocumentPage />} />
        <Route path="historical/:historicalType" element={<HistoricalData />} />
      </Route>
      <Route path="*" element={<NotFoundPage />} />
    </Routes>

    <Outlet />
  </div>
);
