import PropTypes from "prop-types";
import {Card, CardTitle, CardBody} from "reactstrap";
import {
  BATCH_LABELS,
  DHDS_SUMMARY_LABELS,
  DHDS_FEED_COLUMN,
  DHDS_PRODUCTION_COLUMN,
  OP_CONDITIONS,
} from "modules/co-processing/constants/batches";
import DataGrid from "modules/co-processing/components/DetailsScreen/DataGrid";

const DHDSSummary = ({data}) => {
  const isByPass = data.operating_condition === OP_CONDITIONS.BY_PASSED;

  return (
    <div>
      <Card className="card-border rounded-6">
        <CardBody>
          <CardTitle className="text-[26px] pb-10">
            {BATCH_LABELS.DHDS_SUMMARY}
          </CardTitle>
          <div className="grid grid-cols-2 gap-16 pb-12">
            <DataGrid
              data={isByPass ? {} : data?.details}
              title={DHDS_SUMMARY_LABELS.DHDS_FEED}
              dataColumn={DHDS_FEED_COLUMN}
            />
            <DataGrid
              data={isByPass ? {} : data?.details}
              title={DHDS_SUMMARY_LABELS.DHDS_PRODUCTION}
              dataColumn={DHDS_PRODUCTION_COLUMN}
            />
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

DHDSSummary.propTypes = {
  data: PropTypes.object,
};

export default DHDSSummary;
