import PropTypes from "prop-types";
import React, {useMemo} from "react";
import {Progress} from "reactstrap";
import {roundDecimal} from "utils/helpers/uihelper";
import ChecklistPopover from "./ChecklistPopover";
import {batchStatusPropTypes} from "./checkListStatusTypes";
import "./index.scss";

const BatchChecklistProgress = ({popoverId, statusDetails}) => {
  const {completion_percentage, ...statuses} = statusDetails;

  const completionPercent = useMemo(
    () => roundDecimal(completion_percentage),
    [completion_percentage],
  );

  return (
    <ChecklistPopover popoverId={popoverId} statuses={statuses}>
      <div className="progress-indicator" data-test="batch-checklist-progress">
        <span className="text-[10px]">{completionPercent}% COMPLETE</span>
        <Progress
          className="rounded-pill"
          color="success"
          style={{height: "5px"}}
          value={completionPercent}
        />
      </div>
    </ChecklistPopover>
  );
};

BatchChecklistProgress.propTypes = {
  popoverId: PropTypes.string.isRequired,
  statusDetails: PropTypes.shape(batchStatusPropTypes).isRequired,
};

export default BatchChecklistProgress;
