import tw from "twin.macro";
import {useMemo} from "react";
import {useLocation} from "react-router-dom";
import {useQuery} from "@apollo/client";
import {
  COPRO_US_BATCH_DAYS_REMAINING,
  GET_BATCH_BY_ID,
} from "graphql/coprocessing/batches";
import {COPRO_US_BATCH_LINKED_SHIPMENTS} from "graphql/coprocessing/shipments";
import {PAGE_TITLE} from "modules/co-processing/constants/coProcessing";
import {
  BATCH_LABELS,
  BATCH_RECORD_KEYS,
  BATCH_STATUS_CARD_KEYS,
} from "modules/co-processing/constants/batches";
import BatchesStatusChip from "modules/co-processing/components/Shared/BatchesStatusChip";
import RecordCard from "modules/co-processing/components/DetailsScreen/RecordCard";
import BatchStatusCard from "modules/co-processing/components/DetailsScreen/BatchStatusCard";
import BatchSummarySection from "modules/co-processing/components/BatchSummary/BatchSummarySection";
import C14Card from "modules/co-processing/components/DetailsScreen/C14Card";
import ShippingDetailsCard from "modules/co-processing/components/DetailsScreen/ShippingDetailsCard";
import LoadingSpinner from "modules/co-processing/components/Shared/LoadingSpinner";
import DHDSSummary from "modules/co-processing/components/BatchSummary/DHDSSummary";

const SectionHeader = tw.div`text-[26px] pr-3`;
const SectionWrapper = tw.div`pb-8 w-3/4`;
const BatchDetails = () => {
  const {state} = useLocation();
  const batchId = state?.batch_id;

  const {loading, data, refetch} = useQuery(GET_BATCH_BY_ID, {
    fetchPolicy: "network-only",
    variables: {
      batch_id: batchId,
    },
  });

  const batchDetails = useMemo(
    () => data?.bioLcCoproUsBatchesApi?.body?.batch_details,
    [data],
  );

  const {data: daysRemainingData} = useQuery(COPRO_US_BATCH_DAYS_REMAINING, {
    fetchPolicy: "network-only",
    variables: {
      c14_id: batchDetails?.c14_detail_id,
    },
    skip: !batchDetails?.c14_detail_id,
  });

  const {data: shipmentData} = useQuery(COPRO_US_BATCH_LINKED_SHIPMENTS, {
    fetchPolicy: "network-only",
    variables: {
      batchId,
    },
  });

  const getCardDetails = (label, index) => {
    switch (label.title) {
      case BATCH_LABELS.C14_DETAILS:
        return (
          <C14Card
            key={index}
            title={label.title}
            testId={label.testId}
            data={batchDetails}
            batchId={batchId}
            refetch={refetch}
          />
        );
      case BATCH_LABELS.SHIPPING_DETAILS:
        if (
          shipmentData?.bioLcCoproUsShipmentsApi?.body?.batch_shipments.length >
          0
        ) {
          return (
            <ShippingDetailsCard
              key={index}
              title={label.title}
              testId={label.testId}
              shipments={
                shipmentData?.bioLcCoproUsShipmentsApi?.body?.batch_shipments
              }
            />
          );
        }
        return (
          <RecordCard key={index} title={label.title} testId={label.testId} />
        );
      default:
        return (
          <RecordCard key={index} title={label.title} testId={label.testId} />
        );
    }
  };

  return (
    <div data-test="batches-details-screen" className="mx-[40px]">
      <h1 className="pb-6 fs-2 border-b-[1.5px]">{PAGE_TITLE.BATCH_DETAILS}</h1>
      <div>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div className="flex flex-row mb-12 mt-16">
              <SectionHeader>{BATCH_LABELS.STATUS}</SectionHeader>
              <BatchesStatusChip value={batchDetails?.batch_status} small />
            </div>
            <div
              data-test="batch-status-wrapper"
              className="flex flex-row w-3/4 justify-between pb-16"
            >
              {BATCH_STATUS_CARD_KEYS.map((data, index) => (
                <BatchStatusCard
                  key={index}
                  title={data.title}
                  status={data.status}
                />
              ))}
            </div>
            <BatchSummarySection
              data={batchDetails}
              refetch={refetch}
              daysRemaining={
                daysRemainingData?.bioLcCoproUsBatchesApi?.body?.days_remaining
              }
            />
            <SectionWrapper data-test="DHDS-summary-wrapper">
              <DHDSSummary data={batchDetails} />
            </SectionWrapper>
            <SectionWrapper data-test="batch-record-cards-wrapper">
              {BATCH_RECORD_KEYS.map((label, index) =>
                getCardDetails(label, index),
              )}
            </SectionWrapper>
          </div>
        )}
      </div>
    </div>
  );
};

export default BatchDetails;
