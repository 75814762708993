import React from "react";
import PropTypes from "prop-types";
import {Datepicker} from "@bphxd/ds-core-react";
import {CircleClose24} from "@bphxd/ds-core-react/lib/icons";

import "./filter.css";

const FilterDateRangeField = ({column, columnFilterValue}) => {
  const isRight = column?.parent?.id === "Outgoing";

  return (
    <div className="filter-datepicker-container">
      <Datepicker
        className={`bp-datepicker-bp-core !placeholder-[#111111a3] ${
          isRight
            ? "actualisation-date-range-right"
            : "actualisation-date-range-left"
        } bg-white !border-none`}
        placeholder="Select"
        id="dateRange"
        name="dateRange"
        allowEmpty={[true]}
        resetOptions
        defaultValue=""
        options={{
          allowInput: false,
          dateFormat: "d/m/Y",
          defaultDate: columnFilterValue,
          enableTime: false,
          mode: "range",
          onClose: (selectedDates, date) => {
            const dateArr = date.split(" thru ");
            if (selectedDates.length === 2 && dateArr.length === 1) {
              column.setFilterValue([date, date]);
            } else if (dateArr.length === 2) {
              column.setFilterValue(date.split(" thru "));
            }
          },
        }}
      />
      {columnFilterValue && (
        <div className="!w-[24px] mr-[10px]">
          <CircleClose24
            color="gray"
            className="cursor-pointer"
            onClick={() => {
              column.setFilterValue("");
            }}
          />
        </div>
      )}
    </div>
  );
};

FilterDateRangeField.propTypes = {
  column: PropTypes.object,
  columnFilterValue: PropTypes.any,
};

export default FilterDateRangeField;
