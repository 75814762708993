import React from "react";
import PropTypes from "prop-types";
import {Input, InputGroup, InputGroupText} from "reactstrap";
import {CircleClose24} from "@bphxd/ds-core-react/lib/icons";

const FilterSearchField = ({column, columnFilterValue, setRowSelection}) => {
  return (
    <InputGroup className="input-group-merge bg-white" size="">
      <Input
        className="form-control-appended"
        type="text"
        placeholder="Search..."
        onChange={(e) => {
          setRowSelection({});
          column.setFilterValue(e.target.value);
        }}
        value={columnFilterValue || ""}
      />

      <div className="input-group-append">
        <InputGroupText>
          {columnFilterValue && (
            <CircleClose24
              color="gray"
              className="cursor-pointer"
              onClick={() => {
                setRowSelection({});
                column.setFilterValue("");
              }}
            />
          )}
        </InputGroupText>
      </div>
    </InputGroup>
  );
};

FilterSearchField.propTypes = {
  column: PropTypes.object,
  columnFilterValue: PropTypes.any,
  setRowSelection: PropTypes.func,
};

export default FilterSearchField;
