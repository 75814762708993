import {Outlet} from "react-router-dom";
import {Helmet} from "react-helmet";

export default () => {
  return (
    <div
      data-test="compliance-engine-root-layout"
      id="compliance-engine-container"
    >
      <Helmet>
        <title>Compliance Rules Engine</title>
      </Helmet>
      <div className="compliance-engine-header-section">
        <div className="flex mx-[40px] pt-5 text-xs"></div>
      </div>
      <Outlet />
    </div>
  );
};
