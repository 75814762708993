import tw from "twin.macro";

const RulesPage = () => {
  return (
    <div className="overflow-x-auto">
      <HeaderSection>
        <PageTitle>Rules Page</PageTitle>
        <hr className="border-b-[1.5px]" />
      </HeaderSection>
    </div>
  );
};

export default RulesPage;

const HeaderSection = tw.div`relative flex flex-col pb-5 mx-[40px]`;
const PageTitle = tw.h1`text-[1.8125rem] -mb-5`;
